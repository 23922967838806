import { ajax_post, ajax_get } from './util';

export const getClientToken = () => ajax_get('/api/payment/client_token');
export const checkout = (payment_method_nonce, plan, analytics) =>
  ajax_post('/api/payment/checkout', { payment_method_nonce, plan, analytics });
export const applyCode = (code) => ajax_post('/api/payment/apply_code', { code });
export const activateFreeTrial = async () => await ajax_post('/api/payment/free_trial', {});
export const preactivateSubscription = async (checkoutId, analytics) =>
  await ajax_post('/api/payment/preactivate_subscription', { checkoutId, analytics });
export const cancelSubscription = async (subscriptionId) =>
  await ajax_post('/api/payment/cancel_subscription', { subscriptionId });
