import React from 'react';
import moment from 'moment';

export default class EventTimer extends React.Component {
  constructor() {
    super();
    this.state = {
      timeNow: null,
    };
  }

  updateTimeNow() {
    let localTime = moment();
    let utcTime = moment.utc(localTime);
    this.setState({ timeNow: utcTime });
  }

  componentWillMount() {
    this.updateTimeNow();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateTimeNow(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let timer = 'NA';
    let timerColor = 'rgb(165,165,165)';
    let subtext = '';

    if (this.props.event) {
      let timeNow = this.state.timeNow;

      let eventStart = moment.utc(this.props.event.beginDate);
      let eventEnd = moment.utc(this.props.event.endDate);

      if (timeNow.isBetween(eventStart, eventEnd)) {
        let difference = moment(eventEnd).diff(timeNow);
        // Check if difference bigger than 24h
        if (difference >= 8.64e7) {
          let dayTimer = moment.duration(difference).humanize();
          timer = dayTimer;
        } else {
          timer = moment.utc(difference).format('HH:mm');
        }
        timerColor = '#FB8C00';
        subtext = 'Remaining';
      } else if (timeNow.isAfter(eventEnd)) {
        let difference = moment(eventEnd).diff(eventStart);
        timer = moment.utc(difference).format('HH:mm');
        subtext = 'Elapsed';
      } else if (timeNow.isBefore(eventStart)) {
        let difference = moment(eventStart).diff(timeNow);
        // Check if difference bigger than 24h
        if (difference >= 8.64e7) {
          let dayTimer = moment.duration(difference).humanize();
          timer = dayTimer;
        } else {
          timer = moment.utc(difference).format('HH:mm');
        }
        timerColor = '#638EAF';
        subtext = 'Until start';
      }
    }

    return (
      <div className="drawer-timer">
        <a href="#" className="timer-time" style={{ color: timerColor }}>
          {timer}
        </a>
        <p href="#" className="timer-subtext">
          {subtext}
        </p>
      </div>
    );
  }
}
