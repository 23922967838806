import { createSelector } from 'reselect';
import escapeRegExp from 'lodash.escaperegexp';
import {
  // SELECT_EVENT,
  ADD_TASK_SUCCESS,
  SELECT_LIST,
  SELECT_LIST_NONE,
  CHANGE_ACTIVE_VIEW,
  CHANGE_TASKS_TAB,
  SEARCH_MODE_ON,
  SEARCH_MODE_OFF,
  SEARCH_STRING_CHANGE,
  FILTER_COMPLETED_OFF,
  FILTER_COMPLETED_ON,
  FILTER_SCHEDULED_OFF,
  FILTER_SCHEDULED_ON,
  FILTER_OVERDUE_ON,
  FILTER_OVERDUE_OFF,
  TOGGLE_FILTER_USER_TASKS,
  CHANGE_CALENDAR_DATE_RANGE,
  CHANGE_CALENDAR_RANGE,
  CLEAR_SHOW_SUGGESTIONS_FOR_TASK_ID,
  SHOW_OVERDUE_SUGGESTION_FOR_INST_ID,
  SET_CURRENT_VIEW_TASKS_COUNTS,
  SET_SHOW_SUGGESTIONS_FOR_TASK_ID,
  SNOOZE_OVERDUE_SUGGESTIONS_UNTIL,
} from '../actions';
import { ReduxState } from 'reducers';
import { localStorageGetItem } from 'common/utils';
import { getStoredSnoozeOverdueSuggestionsFor } from 'operations/ui';

const defaultState = {
  showSuggestionForTaskId: null,
  showOverdueSuggestionForInstId: null,
  forceShowTaskId: null,
  selectedListId: null,
  activeView: 1,

  searchString: '',
  searchMode: false,

  filter: {
    showCompleted: false,
    showScheduled: false,
    showOverdueOnly: false,
    showOnlyUserTasks: false,
  },

  currentViewOverdueTasksCount: 0,
  currentVuewAutoSuggestionsSourceTasksCount: 0,

  snoozeOverdueSuggestionsUntil: getStoredSnoozeOverdueSuggestionsFor(),

  tab: 'inbox',

  calRange: localStorageGetItem('calendarRange') || 'daily', // daily/3days/week
  viewDatesRange: {
    start: null,
    end: null,
  },
};

export const ui = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        showSuggestionForTaskId: action.id,
        forceShowTaskId: action.id,
      };
    case SET_SHOW_SUGGESTIONS_FOR_TASK_ID:
      return {
        ...state,
        showSuggestionForTaskId: action.taskId,
        forceShowTaskId: action.forceShowTask ? action.taskId : state.forceShowTaskId,
      };
    case CLEAR_SHOW_SUGGESTIONS_FOR_TASK_ID:
      if (state.showSuggestionForTaskId !== action.taskId) {
        return state;
      }
      return {
        ...state,
        showSuggestionForTaskId: null,
      };
    case SELECT_LIST:
      return {
        ...state,
        selectedListId: action.listId,
        tab: 'inbox',
      };
    case SELECT_LIST_NONE:
      return {
        ...state,
        selectedListId: null,
      };
    case CHANGE_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.view,
      };
    case CHANGE_TASKS_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case SEARCH_MODE_ON:
      return {
        ...state,
        searchMode: true,
      };
    case SEARCH_MODE_OFF:
      return {
        ...state,
        searchMode: false,
        searchString: '',
      };
    case SEARCH_STRING_CHANGE:
      return {
        ...state,
        searchString: action.searchString,
      };
    case FILTER_COMPLETED_OFF:
      return {
        ...state,
        filter: {
          ...state.filter,
          showCompleted: false,
        },
      };
    case FILTER_COMPLETED_ON:
      return {
        ...state,
        filter: {
          ...state.filter,
          showCompleted: true,
        },
      };

    case FILTER_SCHEDULED_OFF:
      return {
        ...state,
        filter: {
          ...state.filter,
          showScheduled: false,
        },
      };
    case FILTER_SCHEDULED_ON:
      return {
        ...state,
        filter: {
          ...state.filter,
          showScheduled: true,
        },
      };
    case FILTER_OVERDUE_ON:
      return {
        ...state,
        filter: {
          ...state.filter,
          showOverdueOnly: true,
        },
      };
    case FILTER_OVERDUE_OFF:
      return {
        ...state,
        filter: {
          ...state.filter,
          showOverdueOnly: false,
        },
      };
    case SET_CURRENT_VIEW_TASKS_COUNTS:
      return {
        ...state,
        currentViewOverdueTasksCount: action.overdueTasksCount,
        currentVuewAutoSuggestionsSourceTasksCount: action.autoSuggestionsSourceTasksCount,
      };
    case TOGGLE_FILTER_USER_TASKS:
      return {
        ...state,
        filter: {
          ...state.filter,
          showOnlyUserTasks: !state.filter.showOnlyUserTasks,
        },
      };
    case CHANGE_CALENDAR_DATE_RANGE:
      if (!action.applyToView) return state;
      return {
        ...state,
        viewDatesRange: {
          start: action.start,
          end: action.end,
        },
      };
    case CHANGE_CALENDAR_RANGE:
      return {
        ...state,
        calRange: action.calRange,
      };
    case SHOW_OVERDUE_SUGGESTION_FOR_INST_ID:
      return {
        ...state,
        showOverdueSuggestionForInstId: action.id,
      };
    case SNOOZE_OVERDUE_SUGGESTIONS_UNTIL:
      return {
        ...state,
        snoozeOverdueSuggestionsUntil: action.snoozeOverdueSuggestionsUntil,
      };

    // case SELECT_EVENT:
    //     console.log('action', action)
    //     return {
    //         ...state,
    //         selectedEventId: action.eventId
    //     }
    default:
      return state;
  }
};
// string like: "word1 word2" word3 - split into 2 pieces: '"word1 word2"' and 'word3'
const reSplitIntoPieces = /"([^"]+)"|(\S+)/g;
const reCutQuotes = /^"(.+)"$/;

const cutQuotes = (str: string) => {
  const res = str.match(reCutQuotes);
  return res ? res[1] : str;
};

export const searchStringToRegex = createSelector<ReduxState, string, RegExp>(
  (state) => state.ui.searchString,
  (searchString) => {
    const piecesRes = searchString.match(reSplitIntoPieces) || [];
    // console.log('search quotedPiecesRes', piecesRes);

    // let str = searchString.split(/\s+/)

    const str = piecesRes.map((word) => escapeRegExp(cutQuotes(word))).join('|');
    // console.log('search str regex inner body', str);
    return new RegExp(`(${str})`, 'i');
  }
);
