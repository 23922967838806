import moment from 'moment';
import { fbOps } from 'common/firebase';
import { createNewEvent, saveEvent } from './event';
import { store, getUid } from '.';
import { createEventFromTask } from 'common/utils';
import { predictDuration } from 'api';
import { formatDate } from 'shared';

const SOME_DAY_PERIOD = 150;

export const scheduleTask = (task, dateHint) => {
  if (task.eventId) {
    // return this.scheduleEvent(this.eventsObj[task.eventId], zoneDatetime)
    let eventsObj = store().getState().calendar.eventsObj;
    if (eventsObj[task.eventId]) return scheduleEvent(eventsObj[task.eventId], dateHint, task);

    // event not loaded
    return fbOps.getEventById(getUid(), task.eventId).then((event) => {
      if (event) return scheduleEvent(event, dateHint, task);

      // dangling association - just clear it and repeat
      return fbOps.breakAssociationByTaskId(getUid(), task.id).then(() =>
        scheduleTask(
          {
            ...task,
            eventId: null,
            // eventBeginDate: null,
            // eventEndDate: null,
          },
          dateHint
        )
      );
    });
  }
  let event: any = createEventFromTask(task);
  // event.calendarId = store().getState().account.user.defaultCalendarId;
  event.taskId = task.id;

  const today = moment().startOf('day');
  let someDayPeriod = SOME_DAY_PERIOD;
  if (task.dueDate) {
    const diff = moment(task.dueDate).diff(today, 'days');
    if (diff > 0 && diff < someDayPeriod) {
      someDayPeriod = Math.floor(diff / 2);
    }
  }

  // event.floating = true
  // event.beginDate = zoneDatetime;
  // event.endDate = moment(zoneDatetime).add(60, 'minutes')

  if (typeof dateHint === 'string') {
    event.allDay = true;
    switch (dateHint.toUpperCase()) {
      case 'TODAY':
        event.beginDate = moment().startOf('day');
        break;
      case 'TOMORROW':
        event.beginDate = moment().add(1, 'days').startOf('day');
        break;
      case 'WEEKEND':
        // event.beginDate = moment().add(7, 'days').startOf('week').subtract(2, 'days');
        event.beginDate = moment().day(6);
        break;
      case 'NEXT WEEK':
        // event.beginDate = moment().add(7, 'days').startOf('week');
        event.beginDate = moment().day(8);
        break;
      case 'IN 2 WEEKS':
        event.beginDate = moment().add(14, 'days');
        break;
      case 'IN A MONTH':
        event.beginDate = moment().add(1, 'month');
        break;
      case 'IN 3 MONTHS':
        event.beginDate = moment().add(3, 'months');
        break;
      case 'SOME DAY':
        event.beginDate = moment().add(someDayPeriod, 'days');
        break;
      default:
    }
    event.endDate = event.beginDate.clone().add(1, 'day');
  } else {
    event.beginDate = dateHint.beginDate;
    event.endDate = dateHint.endDate;
    event.allDay = dateHint.allDay;
  }

  if (event.allDay) {
    event.beginDate = formatDate(event.beginDate);
    event.endDate = formatDate(event.endDate);
  }

  createNewEvent(event);
};
export const scheduleEvent = (event, dateHint, task) => {
  event = {
    // copy to avoid modification of existing event
    ...event,
  };

  if (typeof dateHint === 'string') {
    let duration = event.endDate ? moment(event.endDate).diff(moment(event.beginDate), 'minutes') : 60;

    const today = moment().startOf('day');
    const beginDate = moment(event.beginDate);
    const originalEventDay = beginDate.day();
    const daysFromToday = beginDate.clone().startOf('day').diff(today, 'days');

    let someDayPeriod = SOME_DAY_PERIOD;
    if (task?.dueDate) {
      const diff = moment(task.dueDate).diff(today, 'days');
      if (diff > 0 && diff < someDayPeriod) {
        someDayPeriod = Math.floor(diff / 2);
      }
    }

    switch (dateHint.toUpperCase()) {
      case 'TODAY':
        beginDate.subtract(daysFromToday, 'days');
        break;
      case 'TOMORROW':
        beginDate.subtract(daysFromToday - 1, 'days');
        break;
      case 'WEEKEND':
        beginDate.subtract(daysFromToday, 'days').day(6);
        break;
      case 'NEXT WEEK':
        beginDate.subtract(daysFromToday, 'days').day(8);
        break;
      case 'IN 2 WEEKS':
        beginDate.subtract(daysFromToday, 'days').add(14, 'days').day(originalEventDay);
        break;
      case 'IN A MONTH':
        beginDate.subtract(daysFromToday, 'days').add(1, 'month').day(originalEventDay);
        break;
      case 'IN 3 MONTHS':
        beginDate.subtract(daysFromToday, 'days').add(3, 'months').day(originalEventDay);
        break;
      case 'SOME DAY':
        beginDate.subtract(daysFromToday, 'days').add(someDayPeriod, 'days');
        break;
      default:
    }
    event.beginDate = beginDate;
    event.endDate = moment(event.beginDate).add(duration, 'minutes');
  } else {
    event.beginDate = dateHint.beginDate;
    event.endDate = dateHint.endDate;
    event.allDay = dateHint.allDay || false;
  }

  if (event.allDay) {
    event.beginDate = formatDate(event.beginDate);
    event.endDate = formatDate(event.endDate);
  }

  const handleResult = (err) => {
    if (err) alert(err);
  };
  saveEvent(event, handleResult);
};

const pickDurationFromResult = (durationsResult) => {
  for (const { value: duration } of durationsResult) {
    if (duration > 0 && duration < 1440) {
      return duration;
    }
  }
  return null;
};

export const getSinglePrediction = async (title) =>
  predictDuration([title], 3).then(([preds]) => {
    console.log('received predictions', preds);
    return pickDurationFromResult(preds.duration);
  });

export const predictDurationQuicklyOrFallback = async (title, fallbackDuration, inProgressPromise, timeout = 1500) => {
  console.log('Try to predict duration for', title);
  const timeStart = Date.now();
  const timeoutPromise = new Promise((resolve) => {
    setTimeout(() => {
      console.log('Timeout, fallback to', fallbackDuration);
      resolve(fallbackDuration);
    }, timeout);
  });
  const racingPromises = [inProgressPromise || getSinglePrediction(title), timeoutPromise];
  if (inProgressPromise) console.log('Use inProgress prediction promise in race');

  return Promise.race(racingPromises)
    .then((res) => {
      const tookTime = Date.now() - timeStart;
      console.log('Quick prediction result', res, 'took', tookTime, 'msec');
      return res;
    })
    .catch((err) => {
      console.log('Error predicting duration', err, 'ignore');
    });
};
