import moment from 'moment';

export interface PlannerZone {
  start: moment.Moment;
  end: moment.Moment;
}

export interface MinutesZone {
  start: number;
  end: number;
}

export interface ShallowEvent {
  start: string | moment.Moment;
  end: string | moment.Moment;
  allDay?: boolean;
  floating?: boolean;
}

export interface PlannerSummaryZoneData {
  zone: PlannerZone;
  freeZones: PlannerZone[];
  totalMinutes: number;
  smallestFreeZone: PlannerZone;
  biggestFreeZone: PlannerZone;
  freeZoneRatio: number;
}
export type PlannerSummary = { [zoneStr: string]: PlannerSummaryZoneData };

interface ZoneData {
  zone: PlannerZone;
  events: ShallowEvent[];
}
type EventsByZones = { [zoneStr: string]: ZoneData };

const PRINT_DEBUG = false;

const orderByStartTime = (l, r) => {
  if (moment(l.start).isBefore(r.start)) return -1;
  if (moment(l.start).isAfter(r.start)) return 1;
  return 0;
};

export const zoneDurationMinutes = (zone: PlannerZone) => moment(zone.end).diff(zone.start, 'minutes');

export class Planner {
  day: moment.Moment;
  zones: PlannerZone[] = [];
  constructor(
    date: string | moment.Moment,
    { zoneBoundaries, zones }: { zoneBoundaries?: number[]; zones?: MinutesZone[] }
  ) {
    // can be like [5, 12, 17, 21]
    zoneBoundaries = zoneBoundaries || [0, 5, 12, 17, 21, 24];
    // if (zoneBoundaries[0] !== 0) zoneBoundaries.unshift(0);
    // if (zoneBoundaries[zoneBoundaries.length - 1] !== 24) zoneBoundaries.push(24);

    // passed date could be in UTC mode, which means all below operations will apply to UTC hours etc
    // for the moment it's commented and it's expected the caller to take care of correct zone
    // let time = moment(date).local()

    let time = moment(date).startOf('day'); // reset it as the date may have minutes, seconds, etc set - it should not affect the zones
    this.day = time;

    if (zones) {
      this.zones = zones.map(({ start, end }) => ({
        start: moment(time).startOf('day').add(start, 'm'),
        end: moment(time).startOf('day').add(end, 'm'),
      }));
    } else {
      for (let i = 0; i < zoneBoundaries.length - 1; ++i) {
        const zstart = zoneBoundaries[i];
        const zend = zoneBoundaries[i + 1];

        const start = time.clone();
        if (zstart % 0 === 0) {
          start.hour(zstart);
        } else {
          start.hour(Math.floor(zstart));
          start.minute((zstart - Math.floor(zstart)) * 60);
        }

        const end = time.clone();
        if (zend % 0 === 0) {
          end.hour(zend);
        } else {
          end.hour(Math.floor(zend));
          end.minute((zend - Math.floor(zend)) * 60);
        }

        this.zones.push({
          start,
          end,
        });
      }
    }

    if (PRINT_DEBUG) console.log('Constructed planner with date', time, this.day, ', zones:', this.zones);
  }
  zoneDurationMinutes = zoneDurationMinutes;
  getZone(_time): PlannerZone | null {
    if (!_time) throw new Error('Cannot detect zone - passed time argument is empty');
    const time = moment(_time);
    if (!time.isValid()) throw new Error('Argument is not date-ish');
    let zone = this.zones.find((zone) => time.isBetween(zone.start, zone.end, 'hour', '[)'));
    if (!zone) {
      let lastZone = this.zones[this.zones.length - 1];
      if (time.isSame(lastZone.end)) return lastZone;
    }
    if (!zone) {
      if (PRINT_DEBUG)
        console.log('Cannot detect zone - passed time must be out of Planner day (', this.day, '), time:', time);
      // throw new Error('Cannot detect zone - passed time must be out of Planner day')
      return null;
    }
    return zone;
  }
  isEventBelongsToZone(event: ShallowEvent, zone: PlannerZone) {
    return moment(event.start).isBefore(zone.end) && moment(event.end).isAfter(zone.start) && !event.floating;
  }
  calculatePinnedZones(orderedPinnedEvents: ShallowEvent[]) {
    if (!orderedPinnedEvents.length) return [];
    orderedPinnedEvents = Array.from(orderedPinnedEvents);
    const eventToZone = (ev) => {
      return { start: moment(ev.start), end: moment(ev.end) };
    };
    let zones: PlannerZone[] = [eventToZone(orderedPinnedEvents.shift())],
      ev;
    while ((ev = orderedPinnedEvents.shift())) {
      let lastZone = zones[zones.length - 1];
      if (lastZone.end.isBefore(ev.start)) {
        // no overlapping
        zones.push(eventToZone(ev)); // new zone
      } else if (lastZone.end.isBefore(ev.end)) {
        // partial overlapping
        lastZone.end = moment(ev.end); // merge zones
      }
      // total overlapping - ignore
    }
    return zones;
  }
  calculateFreeZones(zone: PlannerZone, orderedPinnedEvents: ShallowEvent[]) {
    if (!orderedPinnedEvents.length) return [zone];
    let pinnedZones = this.calculatePinnedZones(orderedPinnedEvents)
      .map((pinnedZone) => {
        if (pinnedZone.end.isSameOrBefore(zone.start) || pinnedZone.start.isSameOrAfter(zone.end)) return null; // ignore pinned zones out of zone we're interested in

        if (pinnedZone.start.isBefore(zone.start)) {
          pinnedZone.start = zone.start.clone(); // cut the part beyound the zone we're interested in
        }
        if (pinnedZone.end.isAfter(zone.end)) {
          pinnedZone.end = zone.end.clone(); // cut the part beyound the zone we're interested in
        }
        return pinnedZone;
      })
      .filter((pinnedZone) => pinnedZone !== null); // removed zones filter in the previous step

    if (!pinnedZones.length) return [zone]; //

    let zones: PlannerZone[] = [];
    if (zone.start.isBefore(pinnedZones[0].start)) {
      zones.push({ start: moment(zone.start), end: moment(pinnedZones[0].start) });
    }
    for (let zoneIndex = 0; zoneIndex < pinnedZones.length - 1; ++zoneIndex) {
      zones.push({ start: moment(pinnedZones[zoneIndex].end), end: moment(pinnedZones[zoneIndex + 1].start) });
    }
    if (zone.end.isAfter(pinnedZones[pinnedZones.length - 1].end)) {
      zones.push({ start: moment(pinnedZones[pinnedZones.length - 1].end), end: moment(zone.end) });
    }
    return zones;
  }
  groupEventsByZones(events: ShallowEvent[]): EventsByZones {
    let eventsByZone: EventsByZones = {};
    const addEventByZone = (event, zone) => {
      let zoneStr = zone.start.toISOString();
      let zoneEvents = eventsByZone[zoneStr];
      if (!zoneEvents) {
        zoneEvents = eventsByZone[zoneStr] = { zone: zone, events: [] };
      }
      zoneEvents.events.push(event);
    };
    // const getEventsByZone = (zone) => eventsByZone[zone.start.toISOString()];
    events.forEach((event) => {
      for (const zone of this.zones) {
        if (this.isEventBelongsToZone(event, zone)) {
          addEventByZone(event, zone);
        }
      }
      // let zoneStart = this.getZone(event.start),
      //   zoneEnd = this.getZone(event.end);
      // if (zoneStart) addEventByZone(event, zoneStart);
      // if (zoneEnd && zoneStart !== zoneEnd && !event.floating) {
      //   addEventByZone(event, zoneEnd);
      // }
      // if (!zoneStart && !zoneEnd) {
      //   console.error('Event does not belong to day zones: ' + JSON.stringify(event));
      // }
    });
    return eventsByZone;
  }
  tryOrder(orderedFloatingEvents, orderedPinnedEvents, zone, durationMin) {
    let time = zone.start.clone();
    orderedFloatingEvents = Array.from(orderedFloatingEvents);
    let fits = true;
    let lastValidTimeslot;
    const setTime = (ev, start, durationMin) => {
      ev.start = moment(start);
      ev.end = moment(start).add(durationMin, 'm');
    };

    let availableFreeZones = this.calculateFreeZones(zone, orderedPinnedEvents).filter((zone) => {
      let diff = zone.end.diff(zone.start, 'minutes');
      return diff >= durationMin;
    });
    if (PRINT_DEBUG) console.log('discoveredFreeZones', JSON.stringify(availableFreeZones, null, '\t'));
    let freeZone = availableFreeZones.shift();

    if (freeZone) {
      time = freeZone.start.clone();
      let iterations = 0,
        MAX_ITER = 10000;
      do {
        let timeslot = { start: moment(time), end: time.add(durationMin, 'm') };
        if (timeslot.end.isSameOrBefore(freeZone.end)) {
          // valid timeslot
          let nextEvent = orderedFloatingEvents.shift();
          if (!nextEvent) break; // out of events
          setTime(nextEvent, timeslot.start, durationMin);
          lastValidTimeslot = timeslot;
        }
        if (timeslot.end.isSameOrAfter(freeZone.end)) {
          if ((freeZone = availableFreeZones.shift())) {
            time = moment(freeZone.start);
          } else {
            break; // out of free zones
          }
        }
      } while (++iterations <= MAX_ITER);
      if (iterations >= MAX_ITER) throw new Error('Too many iterations');
    } else {
      // throw new Error('Sorry, cannot find free spot to place task');
      console.log('No free spot to place task');
      lastValidTimeslot = { start: time.clone(), end: time.add(durationMin, 'm').clone() };
    }
    if (orderedFloatingEvents.length) {
      fits = false;
      orderedFloatingEvents.forEach((leftoverEvent) => setTime(leftoverEvent, lastValidTimeslot.start, durationMin));
    }
    return fits;
  }
  // orderEventsInZone(events, zone, eventOnTheMove) {
  //   if (PRINT_DEBUG) console.log('orderEventsInZone', zone, 'events', JSON.stringify(events, null, '\t'));
  //   if (!events.length) return [];
  //   let time = moment(zone.start);

  //   const orderByStartTime_eventOnTheMove_makeLast = (l, r) => {
  //     if (l.makeLast) return 1;
  //     if (r.makeLast) return -1;
  //     if (moment(l.start).isBefore(r.start)) return -1;
  //     if (moment(l.start).isAfter(r.start)) return 1;
  //     if (l === eventOnTheMove) return -1;
  //     if (r === eventOnTheMove) return 1;
  //     return 0;
  //   };

  //   let pinnedEvents = [];

  //   let floatingEvents = events
  //     .filter((ev) => {
  //       if (!ev.floating) {
  //         pinnedEvents.push(ev);
  //         return false;
  //       }
  //       return true;
  //     })
  //     .sort(orderByStartTime_eventOnTheMove_makeLast);
  //   if (!floatingEvents.length) return [];

  //   pinnedEvents.sort(orderByStartTime_eventOnTheMove_makeLast);

  //   let fits = this.tryOrder(floatingEvents, pinnedEvents, zone);
  //   return floatingEvents;
  // }

  // // Note that passed floating events can be modified
  // // eventOnTheMove is with priority when two floating events are with same start date
  // // to work correctly eventOnTheMove should be one of passed events, same instance, not copy!
  // orderEvents(events, eventOnTheMove) {
  //   if (PRINT_DEBUG) console.log('orderEvents begin', JSON.stringify(events, null, '\t'));
  //   let eventsByZone = this.groupEventsByZones(events);
  //   let reordered = [];
  //   // if checking eventOnTheMove, new and old start/end need to be checked - reorder all zones for the moment
  //   // if(eventOnTheMove) {
  //   // 	let optionalZone = this.getZone(eventOnTheMove.start)
  //   // 	// console.log('Zone', optionalZone, 'events', eventsByZone[optionalZone])
  //   // 	reordered = this.orderEventsInZone(getEventsByZone(optionalZone).events, optionalZone, eventOnTheMove)
  //   // 	if(!eventOnTheMove.floating) {
  //   // 		let zoneEnd = this.getZone(eventOnTheMove.end)
  //   // 		if(zoneEnd !== optionalZone) {
  //   // 			// console.log('Zone', zoneEnd, 'events', eventsByZone[zoneEnd])
  //   // 			reordered = reordered.concat(this.orderEventsInZone(getEventsByZone(zoneEnd).events, zoneEnd, eventOnTheMove))
  //   // 		}
  //   // 	}
  //   // } else {
  //   for (let zoneStr in eventsByZone) {
  //     // console.log('Zone', zone, 'events', eventsByZone[zone])
  //     reordered = reordered.concat(
  //       this.orderEventsInZone(eventsByZone[zoneStr].events, eventsByZone[zoneStr].zone, eventOnTheMove)
  //     );
  //   }
  //   // }

  //   if (PRINT_DEBUG) console.log('orderEvents end', JSON.stringify(events, null, '\t'));
  //   return reordered;
  // }

  getSummaryByZone(events): PlannerSummary {
    let dayStart = this.day.clone(),
      dayEnd = this.day.clone().endOf('day');
    events = events
      .filter((ev) => moment(ev.start).isSameOrBefore(dayEnd) && moment(ev.end).isSameOrAfter(dayStart))
      .sort(orderByStartTime);
    let eventsByZone = this.groupEventsByZones(events);

    // init
    let summary: PlannerSummary = {};
    this.zones.forEach((zone) => {
      let zoneStr = zone.start.toISOString();
      if (summary[zoneStr]) return;

      summary[zoneStr] = {
        zone,
        smallestFreeZone: zone,
        biggestFreeZone: zone,
        freeZones: [zone],
        totalMinutes: zoneDurationMinutes(zone),
        freeZoneRatio: 1,
      };
    });

    for (let zoneStr in eventsByZone) {
      const zoneData: ZoneData = eventsByZone[zoneStr];
      const freeZones = this.calculateFreeZones(zoneData.zone, zoneData.events),
        noFreeZones = freeZones.length === 0;
      const totalMinutes = freeZones.reduce((val, zz) => val + zoneDurationMinutes(zz), 0);

      summary[zoneStr] = {
        zone: zoneData.zone,
        freeZones: freeZones,
        totalMinutes,
        smallestFreeZone: noFreeZones
          ? null
          : freeZones.reduce((smallest, zz) => {
              if (!smallest) return zz;
              return zoneDurationMinutes(smallest) > zoneDurationMinutes(zz) ? zz : smallest;
            }),
        biggestFreeZone: noFreeZones
          ? null
          : freeZones.reduce((biggest, zz) => {
              if (!biggest) return zz;
              return zoneDurationMinutes(biggest) < zoneDurationMinutes(zz) ? zz : biggest;
            }),
        freeZoneRatio: totalMinutes / zoneDurationMinutes(zoneData.zone),
      };
    }
    return summary;
  }
}
