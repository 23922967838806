import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ExternalIcon from '@mui/icons-material/OpenInNew';

export const OpenInCalendar = ({ htmlLink }) => (
  <Tooltip title="Open in Calendar" placement="left">
    <IconButton
      size="small"
      sx={{
        opacity: 0.1,
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      <a
        href={htmlLink}
        target="_blank"
        rel="noopener noreferrer"
        draggable="false"
        style={{ color: '#4073ff', height: '24px' }}
      >
        <ExternalIcon />
      </a>
    </IconButton>
  </Tooltip>
);
