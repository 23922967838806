import { takeEvery } from 'redux-saga/effects';
import Analytics from '../analytics.jsx';
import * as actions from '../actions';
import { logError } from 'common/utils';

const failedRegex = /_FAILED$/;

function* handleAction(action) {
  if (action.type.match(failedRegex)) {
    logError(action.err, action);
  }

  switch (action.type) {
    case actions.EXTERNAL_CALENDAR_UPDATE:
    case actions.EXTERNAL_TASKS_UPDATE:
    case actions.INITIAL_CALENDAR_LOAD:
    case actions.INITIAL_TASKS_LOAD:
    case actions.USER_CHANGED:
    case actions.USER_DATA_CHANGED:
      return;
    case actions.ADD_LIST_SUCCESS:
      return Analytics.event({ category: 'List', action: 'List succesfully created' });
    case actions.ADD_LIST_FAILED:
      return Analytics.event({ category: 'List', action: 'Failed to Create List', label: action.err.toString() });
    default:
  }

  // Analytics.event({category: 'Redux', action: action.type})

  yield true;
}

export default function* analyticsService() {
  yield takeEvery('*', handleAction);
}
