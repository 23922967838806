import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/';
import { calendar } from './calendar';
import { tasks } from './tasks';
import { ui } from './ui';
import { account } from './account';
import { extensions } from './extensions';

const reducers = combineReducers({
  account,
  calendar,
  extensions,
  tasks,
  ui,
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  // logger,
  sagaMiddleware,
];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

export const store = createStore(reducers, applyMiddleware(...middlewares));

export type ReduxState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);
