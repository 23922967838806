export const VOTE_INTEGRATION = 'VOTE_INTEGRATION';
export const voteIntegration = (integration) => {
  return {
    type: VOTE_INTEGRATION,
    integration,
  };
};

export const VOTE_INTEGRATION_FAILED = 'VOTE_INTEGRATION_FAILED';
export const voteIntegrationFailed = (originalAction, err) => {
  return {
    ...originalAction,
    type: VOTE_INTEGRATION_FAILED,
    err,
  };
};

export const USER_CHANGED = 'USER_CHANGED';
export const userChanged = (fbUser) => ({
  type: USER_CHANGED,
  fbUser,
});

export const USER_DATA_CHANGED = 'USER_DATA_CHANGED';
export const userDataChanged = (user) => ({
  type: USER_DATA_CHANGED,
  user,
});

export const UPDATE_LOCAL_PREFS = 'UPDATE_LOCAL_PREFS';
export const updateLocalPrefs = (prefs) => ({
  type: UPDATE_LOCAL_PREFS,
  prefs,
});
