import { localStorageGetItem } from 'common/utils';

export const ajax = <ResultType>(url: string, data: any, method: string) => {
  return new Promise<ResultType>((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    if (method === 'GET') {
      const hasParams = url.indexOf('?') !== -1;
      url += (hasParams ? '&' : '?') + new Date().getTime();
    }
    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('new_access_token', localStorageGetItem('accessToken', sessionStorage.getItem('accessToken')));
    xhr.responseType = 'json';
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        console.log(xhr.response);
        resolve(xhr.response);
      } else {
        const reason =
          xhr.response?.response?.body?.error?.message || xhr.response?.err?.message || String(xhr.response?.err);
        console.log('Error, status:', xhr.status, reason);
        reject(new Error(reason || xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error('Network error'));
    };
    xhr.send(data ? JSON.stringify(data) : undefined);
  });
};

export const ajax_post = <ResultType = unknown>(url: string, data: any = {}) => ajax<ResultType>(url, data, 'POST');
export const ajax_put = <ResultType = unknown>(url: string, data: any) => ajax<ResultType>(url, data, 'PUT');
export const ajax_patch = <ResultType = unknown>(url: string, data: any) => ajax<ResultType>(url, data, 'PATCH');
export const ajax_get = <ResultType = unknown>(url: string) => ajax<ResultType>(url, {}, 'GET');
export const ajax_delete = <ResultType = unknown>(url: string) => ajax<ResultType>(url, null, 'DELETE');
