import { createSelector } from 'reselect';
import { ReduxState } from '.';
import {
  INITIAL_CALENDAR_LOAD,
  EXTERNAL_CALENDAR_UPDATE,
  PUT_EVENT_TO_UI,
  PUT_EVENT_WITH_TASK_TO_UI,
  REMOVE_EVENT_FROM_UI,
  ADD_EVENT_WITH_TASK_SUCCESS,
  PUT_TASK_TO_UI,
  REMOVE_EVENT_WITH_TASK_FROM_UI,
  CHANGE_CALENDAR_ENABLED,
  ADD_EVENT_SUCCESS,
  SET_SUGGESTION_EVENTS,
  HIDE_SUGGESTION_EVENT,
  SET_AUTO_SUGGEST_MODE,
  COMPARE_AND_CLEAR_SUGGESTION_EVENTS,
} from '../actions';
import { ObjectValues, detectOrigin, localStorageGetItem, localStorageSetItem } from 'common/utils';
import type { Calendar, CalendarEvent } from 'shared';
import moment from 'moment';
import { EventApi } from '@fullcalendar/core';
import { eventToCalendarEvent } from 'modules/calendar/utils';

const enrichEvent = (event, calendar) => ({
  ...event,
  color: event.color || calendar.color,
  enabled: calendar.enabled,
});

const defaultAutoSuggestMode = getAutoSuggestModeFromLocalStorage();

export const calendar = (
  state = {
    calendarsObj: {},
    eventsObj: {},
    disabledCalendarsObj: {},
    inProgressObj: {},
    suggestionEvents: [],
    canAcceptAllSuggestions: false,
    overdueMode: false,
    autoSuggestMode: defaultAutoSuggestMode,
  },
  action
) => {
  switch (action.type) {
    case INITIAL_CALENDAR_LOAD: {
      let { calendarsObj, eventsObj, disabledCalendarsObj } = action;

      let filteredCalendarsObj = {};
      for (let calId in calendarsObj) {
        const cal = calendarsObj[calId];
        if (cal.removed) continue;
        filteredCalendarsObj[cal.id] = {
          ...cal,
          enabled: !disabledCalendarsObj[cal.id],
        };
      }

      let filteredEventsObj = {};
      for (let evId in eventsObj) {
        const event = eventsObj[evId];
        const calendar = filteredCalendarsObj[event.calendarId];
        if (event.removed || !calendar) continue;
        filteredEventsObj[event.id] = enrichEvent(event, calendar);
      }

      return {
        ...state,
        calendarsObj: filteredCalendarsObj,
        eventsObj: filteredEventsObj,
        disabledCalendarsObj,
      };
    }
    case EXTERNAL_CALENDAR_UPDATE: {
      let { calendarsObj, eventsObj } = action;
      let filteredCalendarsObj = {
        ...state.calendarsObj,
      };
      let filteredEventsObj = {
        ...state.eventsObj,
      };

      let updated = false;

      if (calendarsObj) {
        for (let calId in calendarsObj) {
          // analyze only changed cals
          updated = true;
          const cal = calendarsObj[calId];
          if (cal.removed) {
            delete filteredCalendarsObj[cal.id];
            continue;
          }
          filteredCalendarsObj[cal.id] = {
            ...cal,
            enabled: !state.disabledCalendarsObj[cal.id],
          };
        }
        for (let id in filteredEventsObj) {
          let event = filteredEventsObj[id];
          let calendar = calendarsObj[event.calendarId];
          if (calendar) {
            updated = true;
            if (calendar.removed) {
              delete filteredEventsObj[id];
              continue;
            }
            const filteredCal = filteredCalendarsObj[event.calendarId];
            filteredEventsObj[id] = enrichEvent(event, filteredCal);
          }
        }
      }

      for (let eventId in eventsObj) {
        const event = eventsObj[eventId];
        if (state.inProgressObj[event.id] || state.inProgressObj[event.suggestId]) {
          // ignore - it's caused by our own actions
          continue;
        }
        updated = true;

        const calendar = filteredCalendarsObj[event.calendarId];
        if (event.removed || !calendar) {
          delete filteredEventsObj[event.id];
          continue;
        }
        filteredEventsObj[event.id] = enrichEvent(event, calendar);
      }

      if (!updated) return state;

      return {
        ...state,
        calendarsObj: filteredCalendarsObj,
        eventsObj: filteredEventsObj,
      };
    }

    case CHANGE_CALENDAR_ENABLED: {
      const { calendarId, enabled } = action;
      let disabledCalendarsObj = {
        ...state.disabledCalendarsObj,
        [calendarId]: !enabled,
      };

      let calendarsObj = {
        ...state.calendarsObj,
      };
      for (let key in calendarsObj) {
        let cal = calendarsObj[key];
        cal.enabled = !disabledCalendarsObj[cal.id];
      }

      let eventsObj = {
        ...state.eventsObj,
      };
      for (let key in eventsObj) {
        const event = eventsObj[key];
        const calendar = calendarsObj[event.calendarId];
        if (!calendar) continue;
        eventsObj[key] = enrichEvent(event, calendar);
      }

      return {
        ...state,
        calendarsObj,
        eventsObj,
        disabledCalendarsObj,
      };
    }

    case PUT_EVENT_WITH_TASK_TO_UI:
    case PUT_EVENT_TO_UI: {
      let { id, title, calendarId, taskId, taskCompleted, eventId, beginDate, endDate, allDay, inProgress } = action;
      id = id || eventId;
      let calendar = state.calendarsObj[calendarId];
      const existingEvent = state.eventsObj[id] || {};
      let event = {
        ...existingEvent,
        id,
      };

      if (title) event.title = title;
      if (calendarId) event.calendarId = calendarId;
      if (taskId) event.taskId = taskId;
      if (beginDate) event = { ...event, beginDate, endDate, allDay };
      if (taskCompleted !== undefined) event.taskCompleted = taskCompleted;
      if (calendar) event = enrichEvent(event, calendar);

      let inProgressObj = { ...state.inProgressObj };
      if (inProgress) {
        inProgressObj[id] = true;
      } else {
        delete inProgressObj[id];
      }
      return {
        ...state,
        inProgressObj,
        eventsObj: {
          ...state.eventsObj,
          [event.id]: event,
        },
      };
    }

    case REMOVE_EVENT_FROM_UI:
    case REMOVE_EVENT_WITH_TASK_FROM_UI: {
      let { id, eventId } = action;
      id = id || eventId;
      let eventsObj = {
        ...state.eventsObj,
      };
      delete eventsObj[id];
      let inProgressObj = {
        ...state.inProgressObj,
      };
      delete inProgressObj[id];
      return {
        ...state,
        eventsObj,
        inProgressObj,
      };
    }

    case PUT_TASK_TO_UI: {
      let { eventId, completed, inProgress } = action;
      if (!eventId || !state.eventsObj[eventId]) return state;

      let inProgressObj = {
        ...state.inProgressObj,
      };
      if (inProgress) {
        inProgressObj[eventId] = true;
      } else {
        delete inProgressObj[eventId];
      }

      let existingEvent = state.eventsObj[eventId];
      return {
        ...state,
        eventsObj: {
          ...state.eventsObj,
          [eventId]: {
            ...existingEvent,
            taskCompleted: completed,
          },
        },
        inProgressObj,
      };
    }

    case ADD_EVENT_SUCCESS:
      const { id, suggestId, clearInProgress, replaceEventId } = action;
      if (clearInProgress) {
        let inProgressObj = {
          ...state.inProgressObj,
        };
        delete inProgressObj[replaceEventId || suggestId || id];

        let eventsObj = state.eventsObj;
        if (replaceEventId) {
          const event = state.eventsObj[replaceEventId];
          if (event) {
            eventsObj = {
              ...state.eventsObj,
              [id]: {
                ...event,
                id,
              },
            };
            delete eventsObj[replaceEventId];
          }
        }
        return {
          ...state,
          inProgressObj,
          eventsObj,
        };
      }
      return state;

    case ADD_EVENT_WITH_TASK_SUCCESS: {
      let { id, taskId, eventId, calendarId, replaceEventId, clearInProgress } = action;
      id = id || eventId;
      let event = state.eventsObj[replaceEventId] || state.eventsObj[id];
      if (!event) {
        // probably replaced by external DB update
        return state;
      }

      event = {
        ...event,
        taskId,
        id,
        calendarId,
      };
      let eventsObj = {
        ...state.eventsObj,
        [id]: event,
      };
      if (replaceEventId) {
        delete eventsObj[replaceEventId];
      }
      let inProgressObj = state.inProgressObj;
      if (clearInProgress) {
        inProgressObj = { ...inProgressObj };
        delete inProgressObj[replaceEventId || id];
      }
      return {
        ...state,
        eventsObj,
        inProgressObj,
      };
    }

    case SET_SUGGESTION_EVENTS:
      return {
        ...state,
        suggestionEvents: action.events,
        canAcceptAllSuggestions: action.canAcceptAllSuggestions || false,
        overdueMode: action.overdueMode || false,
      };

    case COMPARE_AND_CLEAR_SUGGESTION_EVENTS: {
      const { events } = action;
      if (state.suggestionEvents !== events) return state;
      return {
        ...state,
        suggestionEvents: [],
        canAcceptAllSuggestions: false,
        overdueMode: false,
      };
    }

    case HIDE_SUGGESTION_EVENT: {
      const { suggestion } = action;
      const suggestionEvents = state.suggestionEvents.filter((event) => event.suggestion !== suggestion);
      return {
        ...state,
        suggestionEvents,
      };
    }

    case SET_AUTO_SUGGEST_MODE:
      return {
        ...state,
        autoSuggestMode: action.autoSuggestMode ?? !state.autoSuggestMode,
      };

    default:
      return state;
  }
};

export const getReadonlyCalendarsIds = createSelector<
  ReduxState,
  ReduxState['calendar']['calendarsObj'],
  ReduxState['account']['user'],
  Set<string>
>(
  (state) => state.calendar.calendarsObj,
  (state) => state.account.user,
  (calendarsObj, user) => {
    const integrations = user?.integrations || [];
    const readonlyCalendars = new Set<string>();
    Object.values(calendarsObj).forEach((calendar: Calendar) => {
      // console.log('GAGA calendar', calendar);
      if (calendar.integrationId) {
        const integration = integrations.find((i) => i.id === calendar.integrationId);
        // console.log('GAGA integration', calendar.integrationId, integration, integrations);
        if (integration?.readonly) {
          readonlyCalendars.add(calendar.id);
        }
      }
    });
    return readonlyCalendars;
  }
);

export const getVisibleEvents = createSelector<ReduxState, ReduxState['calendar']['eventsObj'], CalendarEvent[]>(
  (state) => state.calendar.eventsObj,
  (events) => ObjectValues(events).filter((event) => event.enabled)
);

export const getVisibleCalendarEvents = createSelector<
  ReduxState,
  CalendarEvent[],
  ReduxState['calendar']['inProgressObj'],
  Set<string>,
  EventApi[]
>(
  getVisibleEvents,
  (state) => state.calendar.inProgressObj,
  getReadonlyCalendarsIds,
  (events, inProgressObj, readonlyCalendarsIds) => {
    return events.map((event) => {
      const inProgress = inProgressObj[event.id];
      const editable = !inProgress && !readonlyCalendarsIds.has(event.calendarId);

      return eventToCalendarEvent(event, editable);
    });
  }
);

export const getVisibleEventsInCurrentRange = createSelector<
  ReduxState,
  ReduxState['ui']['viewDatesRange'],
  CalendarEvent[],
  CalendarEvent[]
>(
  (state) => state.ui.viewDatesRange,
  getVisibleEvents,
  (viewDatesRange, events) => {
    const start = moment(viewDatesRange.start);
    const end = moment(viewDatesRange.end);
    return events.filter((event) => start.isBefore(event.endDate) && end.isAfter(event.beginDate));
  }
);

export const getAllCalendarsSorted = createSelector<ReduxState, ReduxState['calendar']['calendarsObj'], Calendar[]>(
  (state) => state.calendar.calendarsObj,
  (calendarsObj) => {
    const calendars: Calendar[] = ObjectValues(calendarsObj);
    const key = (c: Calendar) =>
      (c.integrationId || '0') +
      '_' +
      detectOrigin(c.id) +
      '_' +
      (c.isDefault ? 'a' : 'z') +
      '_' +
      (c.order?.toString().padStart(3, '0') || '');
    return calendars.sort((a, b) => {
      return key(a).localeCompare(key(b));
    });
  }
);

export const getEnabledCalendars = createSelector<
  ReduxState,
  Calendar[],
  ReduxState['calendar']['disabledCalendarsObj'],
  Calendar[]
>(
  getAllCalendarsSorted,
  (state) => state.calendar.disabledCalendarsObj,
  (sortedCalendars, disabledCalendarsObj) => {
    return sortedCalendars.filter((calendar) => !disabledCalendarsObj[calendar.id]);
  }
);

export const getAccountEmailByCalendarId = (state: ReduxState, calendarId: string): string | undefined => {
  const calendar = state.calendar.calendarsObj[calendarId];
  if (!calendar) return;
  if (!calendar.integrationId) return state.account.user.email;
  const accountEmail = state.account.user?.integrations?.find((i) => i.id === calendar.integrationId)?.email;
  return accountEmail;
};

export function getAutoSuggestModeFromLocalStorage() {
  return localStorageGetItem('autoSuggestMode') === 'true';
}

export function saveAutoSuggestModeToLocalStorage(autoSuggestMode: boolean) {
  localStorageSetItem('autoSuggestMode', autoSuggestMode ? 'true' : 'false');
}
