import { Checkbox } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { theme } from './theme';

const RoundCheckboxIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <rect
      x="3"
      y="3"
      rx="6"
      ry="6"
      width="18"
      height="18"
      style={{ fill: 'none', strokeWidth: 2, stroke: 'currentColor' }}
    />
  </SvgIcon>
);

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const CheckboxWithEffect = ({ checked, onClick, listColor, event = false, ...props }) => {
  const _onClick = (e) => {
    if (event) {
      e.stopPropagation(); // prevents event menu from opening
      e.preventDefault(); // prevents event menu from opening, as well as onClick from firing
      onClick();
    } else {
      setTimeout(() => onClick(), 450); // delay for task to animate
    }
  };

  // preventDefault() gives error "Unable to preventDefault inside passive event listener invocation" in browser console. This way it doesn't prevent the menu opening as well.
  // I'm putting preventDefault to onClick
  const _onTouchStart = (e) => {
    e.stopPropagation(); // prevents event menu from opening
    e.preventDefault(); // prevents event menu from opening, as well as onClick from firing
    _onClick(e);
  };

  listColor = listColor || theme.palette.primary.main;

  const onTouchStart = isIOS ? _onTouchStart : undefined;
  return (
    <Checkbox
      // onTouchStart={event ? _onTouchStart : _onClick} // if event and touch, fires first to prevent onClick and Event Menu
      onTouchStart={onTouchStart}
      onClick={_onClick}
      key={Date.now()} // to force re-render, key=checked doesn't work when recurring task-event is completed
      defaultChecked={checked}
      icon={<RoundCheckboxIcon />}
      checkedIcon={<TaskAltIcon color={listColor} />}
      {...props}
      sx={{
        padding: '7px',
        color: theme.palette.info.main,
        '&:hover': { color: listColor },
        '&.Mui-checked': { color: listColor },
      }}
      className="task-checkbox"
    />
  );
};
