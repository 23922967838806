import { firebase_normalizeKey } from '../common/firebase';
import { getDatabase, ref, update } from 'firebase/database';
import { monitorTaskNote, monitorListNote } from '../api';
import Analytics from '../analytics.jsx';
import { getUid, store } from '.';

const db = getDatabase();

const noteMonitorObj = {};

const debounceNoteMonitor = (key, func, timeout = 25 * 1000) => {
  let existing = noteMonitorObj[key];
  if (existing === true) {
    noteMonitorObj[key] = func;
  } else if (typeof existing === 'function') {
    return;
  } else {
    noteMonitorObj[key] = true;
    func();
    setTimeout(() => {
      let ee = noteMonitorObj[key];
      if (typeof ee === 'function') ee();
      delete noteMonitorObj[key];
    }, timeout);
  }
};

export const saveTaskNote = (task, note) => {
  let id = firebase_normalizeKey(task.id);
  task.note = note;
  // console.log('task list', task.list)
  const list = store().getState().tasks.listsObj[task.listId];
  if (list.shared) debounceNoteMonitor(task.id, () => monitorTaskNote(task.id));
  return update(ref(db, `objectives/${getUid()}/${id}`), { note }).catch((err) => {
    console.log('Error saving task note:', err);
    alert('Sorry, there was an error saving your note. Please try again later');
    Analytics.event({ category: 'Task', action: 'Error saving task note', label: err.toString() });
  });
};

export const saveListNote = (list, note) => {
  let id = firebase_normalizeKey(list.id);
  list.note = note;
  if (list.shared) debounceNoteMonitor(list.id, () => monitorListNote(list.id));
  return update(ref(db, `lists/${getUid()}/${id}`), { note }).catch((err) => {
    console.log('Error saving list note:', err);
    alert('Sorry, there was an error saving your note. Please try again later');
    Analytics.event({ category: 'List', action: 'Error saving list note', label: err.toString() });
  });
};
