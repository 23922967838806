import React from 'react';
import { Alert, Snackbar, Button, IconButton, Dialog, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

var g_instanceNotificationsDock = null;

export class NotificationsDock extends React.Component {
  constructor() {
    super();
    this.state = {
      snackToggle: false,
      snack: '',
      snackTime: 4000,
      actionButtonContent: null,
      isError: false,
      modalContent: null,
    };
  }

  handleSnackClose = (e, reason) => {
    this.hideSnack();
    this.snackReset();
  };

  handleSnackAction = () => {
    this.state.snackAction?.();
    this.handleSnackClose();
  };

  hideSnack() {
    this.setState({ snackToggle: false });
  }
  snackReset() {
    this.setState({
      snack: '',
      snackTime: 4000,
      actionButtonContent: null,
      snackAction: null,
      isError: false,
    });
  }

  __showSnack(snack, setSnackTime, actionButtonContent = null, snackAction = null) {
    this.setState({
      snackToggle: true,
      snack: snack.substring(0, 70),
      snackTime: setSnackTime,
      actionButtonContent,
      snackAction,
      isError: false,
    });
  }
  __showError(text, actionButtonContent, snackAction) {
    this.setState({
      snackToggle: true,
      snack: text.substring(0, 70),
      snackTime: 10 * 1000,
      actionButtonContent,
      snackAction,
      isError: true,
    });
  }

  __showModal(content) {
    this.setState({ modalContent: content });
  }

  componentDidMount() {
    g_instanceNotificationsDock = this;
  }
  render() {
    const { isError, actionButtonContent } = this.state;

    const customAction = actionButtonContent ? (
      <Button color="secondary" size="small" onClick={this.handleSnackAction}>
        {actionButtonContent}
      </Button>
    ) : null;

    const action = (
      <>
        {customAction}
        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    );

    return (
      <>
        <Snackbar
          open={this.state.snackToggle}
          action={action}
          message={this.state.snack}
          autoHideDuration={this.state.snackTime}
          onClose={this.handleSnackClose}
          className="snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {isError ? (
            <Alert severity="error" onClose={this.handleSnackClose} action={action}>
              {this.state.snack}
            </Alert>
          ) : null}
        </Snackbar>
        <Dialog
          scroll="paper"
          open={this.state.modalContent !== null}
          onClose={() => this.setState({ modalContent: null })}
        >
          <DialogContent>{this.state.modalContent}</DialogContent>
          <DialogActions>
            <Button variant="text" color="primary" onClick={() => this.setState({ modalContent: null })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export class Notification {
  static show(
    text,
    setSnackTime = 4000,
    setSnackActionButton = null,
    setSnackAction = () => g_instanceNotificationsDock.hideSnack()
  ) {
    if (g_instanceNotificationsDock)
      g_instanceNotificationsDock.__showSnack(text, setSnackTime, setSnackActionButton, setSnackAction);
  }
  static showError(text = "Oops. Couldn't do it. Try again", actionButtonContent, actionButtonAction) {
    if (g_instanceNotificationsDock)
      g_instanceNotificationsDock.__showError(text, actionButtonContent, actionButtonAction);
    else alert(text);
  }
  static showModal(content) {
    if (g_instanceNotificationsDock) g_instanceNotificationsDock.__showModal(content);
  }
}
