import moment from 'moment';
import Analytics from '../analytics.jsx';

// Sales Intelligence

export const premiumIntelligence = (props, eventData) => {
  const { user, lists, totalTasks, completedTasks, scheduledTasks } = props;

  const actions = [
    'Purchased Plan: ' + eventData.product.name + ', id: ' + eventData.product.id,
    'User since days: ' + range(moment().diff(moment(user.createdAt), 'days')),
    'Integrations: ' +
      Object.keys(user.accounts)
        .filter((i) => user.accounts[i])
        .toString(),
    'Invitations Received: ' +
      range(Object.keys(user.invitations.received).filter((i) => user.invitations.received[i]).length),
    'Invitations Sent: ' + range(Object.keys(user.invitations.sent).filter((i) => user.invitations.sent[i]).length),
    'Free Events Left: ' + range(user.freeEventsLeft),
    'Lists: ' + range(lists),
    'Total Tasks: ' + range(totalTasks),
    'Scheduled Tasks: ' + range(scheduledTasks),
    'Completed Tasks: ' + range(completedTasks),
  ];
  for (const action of actions) {
    Analytics.event({ category: 'Premium Intel', label: user.uid, action });
  }
  return actions;
};

const range = (count) => {
  switch (true) {
    case count === 0:
      return '0';
    case count > 0 && count <= 3:
      return '1-3';
    case count > 3 && count <= 10:
      return '4-10';
    case count > 10 && count <= 15:
      return '11-15';
    case count > 15 && count <= 25:
      return '16-25';
    case count > 25 && count <= 50:
      return '26-50';
    case count > 50 && count <= 100:
      return '51-100';
    case count > 100 && count <= 150:
      return '101-150';
    case count > 150 && count <= 250:
      return '151-250';
    case count > 250 && count <= 500:
      return '251-500';
    case count > 500 && count <= 1000:
      return '501-1000';
    case count > 1000 && count <= 2000:
      return '1001-2000';
    case count > 2000 && count <= 5000:
      return '2001-5000';
    case count > 5000:
      return '5000+ Jedi Master Level';
    default:
      return count;
  }
};
