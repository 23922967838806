let tempIdIndex = 1;
const generateTempId = () => `${Math.random().toString().slice(2)}-${++tempIdIndex}`;

export const INITIAL_TASKS_LOAD = 'INITIAL_TASKS_LOAD';
export const initialTasksLoad = (listsObj, tasksObj, filtersObj) => {
  return {
    type: INITIAL_TASKS_LOAD,
    listsObj,
    tasksObj,
    filtersObj,
  };
};

export const EXTERNAL_TASKS_UPDATE = 'EXTERNAL_TASKS_UPDATE';
export const externalTasksUpdate = (listsObj, tasksObj, filtersObj) => {
  return {
    type: EXTERNAL_TASKS_UPDATE,
    listsObj,
    tasksObj,
    filtersObj,
  };
};

export const START_TASKS_WATCHER = 'START_TASKS_WATCHER';
export const startTasksWatcher = () => {
  return {
    type: START_TASKS_WATCHER,
  };
};

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const addTaskRequest = (title, listId, item_order, tempId = generateTempId()) => {
  return {
    type: ADD_TASK_REQUEST,
    tempId,
    title,
    listId,
    item_order,
  };
};

export const PUT_TASK_TO_UI = 'PUT_TASK_TO_UI';
export const putTaskToUI = (action) => {
  return {
    ...action,
    type: PUT_TASK_TO_UI,
  };
};

export const REMOVE_TASK_FROM_UI = 'REMOVE_TASK_FROM_UI';
export const removeTaskFromUI = (id, tempId) => {
  return {
    type: REMOVE_TASK_FROM_UI,
    id,
    tempId,
  };
};

export const ADD_TASK_FAILED = 'ADD_TASK_FAILED';
export const addTaskFailed = (requestAction, err) => {
  return {
    ...requestAction,
    type: ADD_TASK_FAILED,
    err,
  };
};

export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const addTaskSuccess = (requestAction, taskId, clearInProgress = false) => {
  return {
    ...requestAction,
    type: ADD_TASK_SUCCESS,
    id: taskId,
    clearInProgress,
  };
};

export const TURN_EVENT_INTO_TASK = 'TURN_EVENT_INTO_TASK';
export const turnEventIntoTask = (event) => {
  return {
    type: TURN_EVENT_INTO_TASK,
    event,
  };
};
export const TURN_EVENT_INTO_TASK_SUCCESS = 'TURN_EVENT_INTO_TASK_SUCCESS';
export const turnEventIntoTaskSuccess = (requestAction, taskId) => {
  return {
    ...requestAction,
    type: TURN_EVENT_INTO_TASK_SUCCESS,
    id: taskId,
  };
};
export const TURN_EVENT_INTO_TASK_FAILED = 'TURN_EVENT_INTO_TASK_FAILED';
export const turnEventIntoTaskFailed = (requestAction, err) => {
  return {
    ...requestAction,
    type: TURN_EVENT_INTO_TASK_FAILED,
    err,
  };
};

export const SET_TASK_DUE_DATE = 'SET_TASK_DUE_DATE';
export const setTaskDueDate = (taskId, dueDate) => {
  return {
    type: SET_TASK_DUE_DATE,
    taskId,
    dueDate,
  };
};

export const SET_TASK_RECURRENCE = 'SET_TASK_RECURRENCE';
export const setTaskRecurrence = (taskId, recurrence = null) => {
  return {
    type: SET_TASK_RECURRENCE,
    taskId,
    recurrence,
  };
};

export const COMPLETE_TASK_REQUEST = 'COMPLETE_TASK_REQUEST';
export const completeTask = (taskId, eventId, due = null) => {
  return {
    type: COMPLETE_TASK_REQUEST,
    id: taskId,
    taskId,
    eventId,
    due,
  };
};
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS';
export const completeTaskSuccess = (requestAction) => {
  return {
    ...requestAction,
    type: COMPLETE_TASK_SUCCESS,
  };
};
export const COMPLETE_TASK_FAILED = 'COMPLETE_TASK_FAILED';
export const completeTaskFailed = (requestAction, err) => {
  return {
    ...requestAction,
    type: COMPLETE_TASK_FAILED,
    err,
  };
};

export const UNCOMPLETE_TASK_REQUEST = 'UNCOMPLETE_TASK_REQUEST';
export const uncompleteTask = (taskId, eventId, due = null) => {
  return {
    type: UNCOMPLETE_TASK_REQUEST,
    id: taskId,
    taskId,
    eventId,
    due,
  };
};
export const UNCOMPLETE_TASK_SUCCESS = 'UNCOMPLETE_TASK_SUCCESS';
export const uncompleteTaskSuccess = (requestAction) => {
  return {
    ...requestAction,
    type: UNCOMPLETE_TASK_SUCCESS,
  };
};
export const UNCOMPLETE_TASK_FAILED = 'UNCOMPLETE_TASK_FAILED';
export const uncompleteTaskFailed = (requestAction, err) => {
  return {
    ...requestAction,
    type: UNCOMPLETE_TASK_FAILED,
    err,
  };
};

export const ADD_LIST_REQUEST = 'ADD_LIST_REQUEST';
export const addListRequest = (tempId, name, colorId, origin, isDefault = null) => {
  return {
    type: ADD_LIST_REQUEST,
    enabled: true,
    tempId,
    name,
    colorId,
    origin,
    isDefault,
  };
};
export const ADD_LIST_SUCCESS = 'ADD_LIST_SUCCESS';
export const addListSuccess = (requestAction) => {
  return {
    ...requestAction,
    type: ADD_LIST_SUCCESS,
  };
};
export const ADD_LIST_FAILED = 'ADD_LIST_FAILED';
export const addListFailed = (requestAction, err) => {
  return {
    ...requestAction,
    type: ADD_LIST_FAILED,
    err,
  };
};

export const CHANGE_LIST_ENABLED = 'CHANGE_LIST_ENABLED';
export const changeListEnabled = (listId, enabled, legacy_id) => {
  return {
    type: CHANGE_LIST_ENABLED,
    listId,
    enabled,
    legacy_id,
  };
};
