import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Grid,
} from '@mui/material';
import { Repeat } from '@mui/icons-material';
import { SelectDueDateWidget } from './SelectDueDateWidget';
import { SelectRecurrenceWidget } from './SelectRecurrenceWidgetProps';
import * as taskOps from '../../operations/task';

export const CombinedRecurrenceWidget = ({ task }) => {
  const [open, setOpen] = useState(false);
  const hasScheduledRecurrence = task.recurringEventIds || task.nextInstEventId;
  const hasFlexibleRecurrence = task.recurrence && task.dueDate;
  const initialType = hasScheduledRecurrence ? 'scheduled' : hasFlexibleRecurrence ? 'flexible' : null;
  const [recurrenceType, setRecurrenceType] = useState(initialType);
  const { recurrence, dueDate } = task;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSetRecurrenceType = (event) => {
    setRecurrenceType(event.target.value);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen} fullWidth startIcon={<Repeat />}>
        {hasScheduledRecurrence || hasFlexibleRecurrence ? 'Repeating' : 'Repeat'}
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ '.MuiDialog-paper': { maxWidth: '550px' } }}>
        <DialogTitle>Set Recurring Rules</DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup value={recurrenceType} onChange={handleSetRecurrenceType}>
              <FormControlLabel
                value="flexible"
                control={<Radio />}
                label="Flexible Recurrence"
                disabled={hasScheduledRecurrence}
              />
              <FormHelperText>
                While not scheduled automatically, flexible recurring tasks allow you to{' '}
                <strong>schedule each recurrence individually</strong>. Flexible recurrences can automatically be added
                to the AI Scheduling Queue, making it easy to find time for flexible habits. After each completion, the
                recurring task is hidden until its next recurrence.
              </FormHelperText>
              <br />
              <FormControlLabel
                value="scheduled"
                control={<Radio />}
                label="Scheduled Recurrence"
                disabled={hasFlexibleRecurrence}
              />
              {hasFlexibleRecurrence && <FormHelperText>Only one recurrence rule can be set at a time.</FormHelperText>}
              <FormHelperText>
                Similar to recurring calendar events, scheduled recurring tasks have a{' '}
                <strong>fixed time slot and each recurrence is displayed in the calendar timeline</strong>. Recurrences
                can be rescheduled or completed individually, making them ideal for tracking time-based habits. They can
                also be scheduled in the all-day section.
                <br />
                <strong>Note</strong>: Scheduled Recurrences can only be set for tasks scheduled in Google calendars.
              </FormHelperText>
              <br />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={2}>
            {recurrenceType === 'flexible' && (
              <>
                <Grid item xs={12}>
                  <p>Start repeating on: </p>
                  <SelectDueDateWidget
                    dueDate={dueDate}
                    onChange={(date) => taskOps.setTaskDueDate(task.id, date)}
                    buttonProps={{ justifyContent: 'flex-start' }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <p>Repeat every: </p>
                  <SelectRecurrenceWidget
                    recurrence={recurrence}
                    dueDate={dueDate}
                    onChange={(recurrence) => taskOps.setTaskRecurrence(task.id, recurrence, task.dueDate)}
                  />
                </Grid>
              </>
            )}
            {recurrenceType === 'scheduled' && (
              <>
                <img
                  src="/images/scheduled_recurrence_guide.svg"
                  style={{ width: '100%', margin: '30px 10px 0 10px' }}
                  alt="1. Schedule the task; 2. Click on the scheduled task to open the event menu; 3. Select Set Recurrence;"
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
