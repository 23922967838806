import React, { useCallback, useState, useEffect } from 'react';
import Analytics from '../../analytics.jsx';
import * as taskOps from '../../operations/task';
import { showModalErrorPerformingTaskEventOperation } from 'modules/tasks/showModalErrorPerformingTaskOperation';
import { Box, IconButton, Tooltip } from '@mui/material';
import { HighlightOff, Edit, MoveToInbox, TagSharp, ArrowDropDownSharp, PlaylistAddCheck } from '@mui/icons-material';
import { OpenInTodoist } from '../../components/OpenInTodoist';
import { AssignUserWidget } from '../../components/AssignUserWidget';
import { TaskScheduleWidget } from './TaskScheduleWidget';
import { SelectDurationWithAutoComplete } from './SelectDuration';
import { CheckboxWithEffect } from '../../CheckboxWithEffect';
import { styled } from '@mui/system';
import { TextFieldForm } from '../../components/TextFieldForm';
import * as eventOps from '../../operations/event';
import * as utils from 'common/utils';
import { RemoveWithConfirmListItem } from '../../components/RemoveWithConfirmListItem.jsx';
import MoveToListMenuItem from '../../components/MoveToListMenuItem.jsx';
import { ExpandMoreOutlined } from '../../components/CustomIcons.jsx';
import { theme } from '../../theme';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { resolveTaskTitleWithParents } from 'shared';

const FullWidthCenteredBox = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  minHeight: 42,
});

// Doesn't work though it should in theory
// https://styled-components.com/docs/advanced#referring-to-other-components
// temp make it inside OpenInTodoist show on hovver
const ShowOnPanelHover = styled(Box)({
  // opacity: 0.1,
  // [`${FullWidthCenteredBox} &`]: {
  //   opacity: 1,
  // },
});

const Title = styled(Box)({
  // flexGrow: 1,
  padding: '5px 8px 5px 3px',
  fontWeight: 500,
  whiteSpace: 'normal',
  wordBreak: 'break-word',
});

const ShowTaskOptionsIcon = ({ taskOptions, showTaskOptions, listColor = 'black' }) => {
  const handleClick = () => {
    showTaskOptions();
  };
  if (taskOptions) {
    return (
      <IconButton onClick={handleClick} sx={{ '& .MuiSvgIcon-root': { color: listColor } }}>
        <HighlightOff color="faded" />
      </IconButton>
    );
  } else {
    return (
      <IconButton onClick={handleClick}>
        <ExpandMoreOutlined size={'20px'} color={theme.palette.faded.main} />
      </IconButton>
    );
  }
};

export const TaskTitleWithControls = ({
  task,
  assignToList,
  hideScheduleButton,
  expandToggle, // Toggles expanded prop in TaskCard, used to expand scheduling options
  showTaskOptions, // Toggles taskOptions prop in TaskCard, used to expand task note and options
  listColor,
  taskOptions, // Prop of showTaskOptions
}) => {
  const [editing, setEditing] = useState(false);
  const effectiveEventId = task.eventId || task.nextInstEventId;

  const tasksObj = useSelector((state) => state.tasks.tasksObj);

  // task.duration = 105 // TESTING, also doesn't handle more than 30 days, 23h, 59min.

  const handleEditTask = (newTitle) => {
    taskOps.renameTask(task, { title: newTitle });
    setEditing(false);
    Analytics.event({
      category: 'Task',
      action: 'Edited Task',
    });
  };

  const handleRemove = async () => {
    showTaskOptions(null);

    if (effectiveEventId) {
      try {
        const event = await eventOps.getEventById(effectiveEventId);
        if (!event)
          return showModalErrorPerformingTaskEventOperation({
            operation: 'removal',
            task,
          });

        // if (!event.recurringEventId) {
        //   return eventOps.removeEventWithAssociatedTask(event);
        // }

        if (
          event.recurringEventId &&
          !window.confirm('This is a recurring event. Do you REALLY want to remove it with ALL instances?')
        ) {
          return;
          // await eventOps.removeEventWithAssociatedTask(event, task.id);
        }
      } catch (err) {
        utils.logError(err, ['Error removing event accosiated with task', task]);
        Notification.showError('Failed to remove associated event');
      }
    }

    try {
      await taskOps.removeTask(task);
    } catch (err) {
      Notification.showError('Failed to remove task');
    }
  };

  const activateEditMode = () => setEditing(true);

  const handleSelectOnChange = useCallback(
    (duration) => {
      return taskOps.setTaskDuration(task, duration).catch((err) => {
        console.error('Error setting task duration', err);
      });
    },
    [task]
  );

  let content;

  if (editing) {
    // Edit title mode
    content = (
      <>
        <Edit color="faded" style={{ padding: '8px', cursor: 'default' }} />
        <TextFieldForm
          initialValue={task.title}
          onSave={handleEditTask}
          onCancel={() => setEditing(false)}
          leftMargin={assignToList ? '87px' : '43px'}
        />
      </>
    );
  } else {
    // Default mode
    let taskOptionsRender;
    const toggleCompleteUncomplete = () => (task.completed ? taskOps.uncompleteTask(task) : taskOps.completeTask(task));

    if (taskOptions) {
      // Show Task Ops
      taskOptionsRender = (
        <>
          {utils.detectOrigin(task.id) === 'todoist' && <OpenInTodoist task={task} />}
          {/* {!!task.todoist && <OpenInTodoist task={task} />} */}
          <MoveToListMenuItem
            task={task}
            button={
              <Tooltip title="Move to List" placement="top" disableInteractive>
                <IconButton sx={{ borderRadius: '20px', paddingRight: '4px' }}>
                  <TagSharp sx={{ width: '22px', marginRight: '-4px' }} color="faded" />
                  <ArrowDropDownSharp sx={{ width: '20px' }} color="faded" />
                </IconButton>
              </Tooltip>
            }
          />
          <RemoveWithConfirmListItem
            onRemove={handleRemove}
            hint={task.eventId ? 'Task & Event' : 'Task'}
            iconButton={true}
          />
        </>
      );
    } else {
      // Default Ops
      taskOptionsRender = (
        <>
          {(task.eventId || task.nextInstEventId) && task.eventBeginDate ? (
            ''
          ) : (
            <SelectDurationWithAutoComplete task={task} listColor={listColor} onChange={handleSelectOnChange} />
          )}
          {!hideScheduleButton && <TaskScheduleWidget task={task} listColor={listColor} onClick={expandToggle} />}
        </>
      );
    }

    const taskTitleParentPath = resolveTaskTitleWithParents(task, tasksObj, true);

    content = (
      <>
        <CheckboxWithEffect onClick={toggleCompleteUncomplete} checked={!!task.completed} listColor={listColor} />

        {!!assignToList && <AssignUserWidget task={task} assignToList={assignToList} />}

        <Title onClick={activateEditMode} className="task-card-title">
          <span style={{ cursor: 'text' }}>
            {!!taskTitleParentPath && (
              <Markdown className="task-parent-path title-with-markdown">{taskTitleParentPath}</Markdown>
            )}
            <Markdown className="title-with-markdown">{task.title}</Markdown>
          </span>
        </Title>
        <span style={{ flexGrow: 1 }}></span>
        {/*<div>{task.created?(new Date(task.created)).toISOString():'n/a'}</div>*/}
        {taskOptionsRender}
        <ShowTaskOptionsIcon taskOptions={taskOptions} showTaskOptions={showTaskOptions} listColor={listColor} />
      </>
    );
  }
  return <FullWidthCenteredBox className="task-title-with-controls-content">{content}</FullWidthCenteredBox>;
};
