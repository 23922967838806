import { CalendarEvent, Task } from 'shared';

export const INITIAL_CALENDAR_LOAD = 'INITIAL_CALENDAR_LOAD';
export const initialCalendarLoad = (calendarsObj, eventsObj, disabledCalendarsObj) => {
  return {
    type: INITIAL_CALENDAR_LOAD,
    calendarsObj,
    eventsObj,
    disabledCalendarsObj,
  };
};

export const EXTERNAL_CALENDAR_UPDATE = 'EXTERNAL_CALENDAR_UPDATE';
export const externalCalendarsUpdate = (calendarsObj, eventsObj) => {
  return {
    type: EXTERNAL_CALENDAR_UPDATE,
    calendarsObj,
    eventsObj,
  };
};

export const START_CALENDAR_WATCHER = 'START_CALENDAR_WATCHER';
export const startCalendarWatcher = () => {
  return {
    type: START_CALENDAR_WATCHER,
  };
};

export const CHANGE_CALENDAR_DATE_RANGE = 'CHANGE_CALENDAR_DATE_RANGE';
export const changeCalendarDateRange = (start, end, applyToView = true) => {
  return {
    type: CHANGE_CALENDAR_DATE_RANGE,
    start,
    end,
    applyToView,
  };
};

// 1 day, 3 days, week, month
export const CHANGE_CALENDAR_RANGE = 'CHANGE_CALENDAR_RANGE';
export const changeCalendarRange = (calRange) => {
  return {
    type: CHANGE_CALENDAR_RANGE,
    calRange,
  };
};

// --- update calendar UI

export const PUT_EVENT_TO_UI = 'PUT_EVENT_TO_UI';
export const putEventToUI = (action: Partial<CalendarEvent & { inProgress: boolean }>) => {
  return {
    ...action,
    type: PUT_EVENT_TO_UI,
  };
};

export const REMOVE_EVENT_FROM_UI = 'REMOVE_EVENT_FROM_UI';
export const removeEventFromUI = (id: string) => {
  return {
    type: REMOVE_EVENT_FROM_UI,
    id,
  };
};

export const SHOW_OVERDUE_SUGGESTION_FOR_INST_ID = 'SHOW_OVERDUE_SUGGESTION_FOR_INST_ID';
export const showOverdueSuggestionForInstId = (id: string | null) => {
  return {
    type: SHOW_OVERDUE_SUGGESTION_FOR_INST_ID,
    id,
  };
};

export const HIDE_SUGGESTION_EVENT = 'HIDE_SUGGESTION_EVENT';
export const hideSuggestionEvent = (suggestion: any) => {
  return {
    type: HIDE_SUGGESTION_EVENT,
    suggestion,
  };
};

// --- events

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const addEventRequest = (
  title,
  // calendarId,
  taskId,
  taskCompleted,
  beginDate,
  endDate,
  allDay,
  suggestId,
  description
) => {
  return {
    type: ADD_EVENT_REQUEST,
    suggestId,
    title,
    // calendarId,
    taskId,
    taskCompleted,
    beginDate,
    endDate,
    allDay,
    description,
  };
};

export const ADD_EVENT_FAILED = 'ADD_EVENT_FAILED';
export const addEventFailed = (requestAction, err) => {
  return {
    ...requestAction,
    originalRequest: requestAction,
    type: ADD_EVENT_FAILED,
    err,
  };
};

export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const addEventSuccess = (requestAction, replaceEventId = null, clearInProgress = false) => {
  return {
    ...requestAction,
    type: ADD_EVENT_SUCCESS,
    replaceEventId,
    clearInProgress,
  };
};

// --- event update

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const updateEventRequest = (
  id,
  title,
  calendarId,
  beginDate,
  endDate,
  allDay,
  reminders,
  taskId,
  hintOperation,
  extraLongId,
  recurringEventMatchingTaskId
) => {
  return {
    type: UPDATE_EVENT_REQUEST,
    id,
    extraLongId,
    title,
    calendarId,
    beginDate,
    endDate,
    allDay,
    reminders,
    taskId,
    hintOperation,
    recurringEventMatchingTaskId,
  };
};

export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED';
export const updateEventFailed = (requestAction, err) => {
  return {
    ...requestAction,
    originalRequest: requestAction,
    type: UPDATE_EVENT_FAILED,
    err,
  };
};

export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const updateEventSuccess = (requestAction) => {
  return {
    ...requestAction,
    type: UPDATE_EVENT_SUCCESS,
  };
};

/// --- event + task

export const ADD_EVENT_WITH_TASK_REQUEST = 'ADD_EVENT_WITH_TASK_REQUEST';
export const addEventWithTaskRequest = (
  // userId,
  title,
  // calendarId,
  listId,
  suggestId,
  beginDate,
  endDate,
  allDay,
  tempId,
  description
) => {
  return {
    type: ADD_EVENT_WITH_TASK_REQUEST,
    // userId,
    suggestId,
    tempId,
    title,
    // calendarId,
    listId,
    beginDate,
    endDate,
    allDay,
    description,
  };
};
export const PUT_EVENT_WITH_TASK_TO_UI = 'PUT_EVENT_WITH_TASK_TO_UI';
export const putEventWithTaskToUI = (
  action: Partial<CalendarEvent & Task & { inProgress: boolean; tempId?: string }>
) => {
  return {
    ...action,
    type: PUT_EVENT_WITH_TASK_TO_UI,
  };
};

export const REMOVE_EVENT_WITH_TASK_FROM_UI = 'REMOVE_EVENT_WITH_TASK_FROM_UI';
export const removeEventWithTaskFromUI = (eventId, tempId) => {
  return {
    type: REMOVE_EVENT_WITH_TASK_FROM_UI,
    eventId,
    tempId,
  };
};

export const ADD_EVENT_WITH_TASK_FAILED = 'ADD_EVENT_WITH_TASK_FAILED';
export const addEventWithTaskFailed = (requestAction, err) => {
  return {
    ...requestAction,
    originalRequest: requestAction,
    type: ADD_EVENT_WITH_TASK_FAILED,
    err,
  };
};
export const ADD_EVENT_WITH_TASK_SUCCESS = 'ADD_EVENT_WITH_TASK_SUCCESS';
export const addEventWithTaskSuccess = (
  requestAction,
  taskId = null,
  replaceEventId = null,
  clearInProgress = false
) => {
  return {
    ...requestAction,
    type: ADD_EVENT_WITH_TASK_SUCCESS,
    taskId,
    replaceEventId,
    clearInProgress,
  };
};

export const CHANGE_CALENDAR_ENABLED = 'CHANGE_CALENDAR_ENABLED';
export const changeCalendarEnabled = (calendarId, enabled) => {
  return {
    type: CHANGE_CALENDAR_ENABLED,
    calendarId,
    enabled,
  };
};

export const RENAME_EVENT_REQUEST = 'RENAME_EVENT_REQUEST';
export const renameEventRequest = (id, title, calendarId, taskId) => {
  return {
    type: RENAME_EVENT_REQUEST,
    id,
    title,
    calendarId,
    taskId,
  };
};
export const RENAME_EVENT_FAILED = 'RENAME_EVENT_FAILED';
export const renameEventFailed = (requestAction, err) => {
  return {
    ...requestAction,
    originalRequest: requestAction,
    type: RENAME_EVENT_FAILED,
    err,
  };
};
export const RENAME_EVENT_SUCCESS = 'RENAME_EVENT_SUCCESS';
export const renameEventSuccess = (requestAction, taskId) => {
  return {
    ...requestAction,
    type: RENAME_EVENT_SUCCESS,
    taskId,
  };
};

export const SET_SUGGESTION_EVENTS = 'SET_SUGGESTION_EVENTS';
export const setSuggestionEvents = (events, canAcceptAllSuggestions = false, overdueMode = false) => {
  return {
    type: SET_SUGGESTION_EVENTS,
    events,
    canAcceptAllSuggestions,
    overdueMode,
  };
};

export const COMPARE_AND_CLEAR_SUGGESTION_EVENTS = 'COMPARE_AND_CLEAR_SUGGESTION_EVENTS';
export const compareAndClearSuggestionEvents = (events) => {
  return {
    type: COMPARE_AND_CLEAR_SUGGESTION_EVENTS,
    events,
  };
};

export const SET_AUTO_SUGGEST_MODE = 'SET_AUTO_SUGGEST_MODE';
export const setAutoSuggestMode = (mode?: boolean) => {
  return {
    type: SET_AUTO_SUGGEST_MODE,
    autoSuggestMode: mode,
  };
};
