import { localStorageGetItem, localStorageSetItem } from 'common/utils';
import { store } from '.';
import * as actions from 'actions';
import moment from 'moment';

const LOCAL_STORAGE_KEY = 'snoozeOverdueSuggestionsUntil';

export const snoozeOverdueSuggestionsFor = (minutes: number) => {
  const timeNow = moment().valueOf();
  const snoozeOverdueSuggestionsUntil = timeNow + minutes * 60 * 1000;
  store().dispatch(actions.snoozeOverdueSuggestionsUntil(snoozeOverdueSuggestionsUntil));
  localStorageSetItem(LOCAL_STORAGE_KEY, String(snoozeOverdueSuggestionsUntil));
};

export const getStoredSnoozeOverdueSuggestionsFor = () => {
  const value = localStorageGetItem(LOCAL_STORAGE_KEY, '0');
  return Number(value) || 0;
};
