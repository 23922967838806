import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ExternalIcon from '@mui/icons-material/OpenInNew';

export const OpenInTodoist = ({ task }) => {
  const todoistId = task.todoist?.id || task.id?.split('@')[0];
  return (
    <Tooltip title="Open in Todoist" placement="top">
      <IconButton color="faded">
        <a
          href={`https://todoist.com/app/task/${todoistId}`}
          target="_blank"
          rel="noopener noreferrer"
          draggable="false"
          style={{ color: '#ACBAC3', height: '24px' }}
          // style={{ color: '#db4035' }}
        >
          <ExternalIcon />
        </a>
      </IconButton>
    </Tooltip>
  );
};
