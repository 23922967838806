import { useState } from 'react';
import { IconButton, Menu } from '@mui/material';

export const IconMenu = ({ children, iconButtonElement, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        onClick={handleClick}
        style={{ cursor: 'pointer', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {iconButtonElement}
      </div>
      <Menu {...props} anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};
