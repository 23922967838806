import { CalendarEvent } from 'shared';
import { ajax_post } from './util';

export const setCalendarDefaultReminders = (calendarId: string, defaultReminders: any[]) =>
  ajax_post('/api/calendar/default_reminders', { calendarId, defaultReminders });

export const setListsVisibility = (data: { listIds: string[]; enabled: boolean }) =>
  ajax_post('/api/list/bulk_set_visibility', data);

export const setCalendarEventRecurrence = (data: {
  calendarId: string;
  eventId: string;
  recurrence: string | null;
  recurringEventId: string | undefined;
  taskId: string | undefined;
}) => ajax_post('/api/calendar/event_recurrence', data);

interface ResolveRecurringReturn {
  recurrenceResolved?: string[];
}

export const resolveRecurringEvent = (calendarId: string, recurringEventId: string) => {
  return ajax_post('/api/event/resolve_recurring', { calendarId, recurringEventId }) as Promise<ResolveRecurringReturn>;
};

export const resolveRecurrenceRule = (recurrence: string[]) => {
  return ajax_post('/api/event/resolve_recurring', { recurrence }) as Promise<ResolveRecurringReturn>;
};

export const fetchNextRecurringEventUncompletedInstances = (
  calendarId: string,
  recurringEventId: string,
  afterDate: string
) => {
  return ajax_post<{ instance: CalendarEvent | null }>('/api/event/next_recurring_uncompleted_instance', {
    calendarId,
    recurringEventId,
    afterDate,
  });
};

export const bulkCreateEventsFromSuggestions = (data: {
  suggestions: { taskId: string; title: string; beginDate: string; duration: string }[];
}) => ajax_post<{ ids: string[] }>('/api/event/bulk_create_from_suggestions', data);

export const bulkUpdateEvents = (data: { events: CalendarEvent[] }) => {
  return ajax_post('/api/event/bulk_update', data);
};

export const bulkRemoveEvents = (data: { eventIds: string[] }) => ajax_post('/api/event/bulk_remove', data);
