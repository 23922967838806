import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getEnabledLists } from 'reducers/tasks';
import hotkeys from 'hotkeys-js';

export const listNameToHashtag = (str) => `#${str.replace(/\s/g, '')}`;

export const getListNameWithSuffix = (list) => {
  if (list.origin === 'todoist') {
    return list.name + ' @Todoist';
  }
  return list.name;
};

const lastHashtagRegex = /.*(#[^\s#]*)$/;

const TextInput = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(80, 150, 190)',
  },
  '& .MuiInput-underline:hover': {
    borderBottomColor: 'rgb(80, 150, 190)',
  },
  height: '100%',
  // paddingTop: 10,
});

const MyPopper = (props) => {
  return <Popper {...props} placement="top-start" keepMounted />;
};
const filter = createFilterOptions({
  limit: 7,
});

interface TaskInputWithAutoCompleteProps {
  externalValue?: string;
  onChange: (value: string) => void;
  autofocus?: boolean;
  stripHashtagOnSelect?: boolean;
  onListSelect?: (listId: string) => void;
  onEnter?: (value: string, listId: string | null, selectedHashtag: string | null) => Promise<void>;
  placeholder?: string;
  keybindingFocusInput?: string;
}

export const TaskInputWithAutoComplete: React.FC<TaskInputWithAutoCompleteProps> = ({
  autofocus,
  stripHashtagOnSelect,
  externalValue,
  onChange,
  onListSelect,
  onEnter,
  placeholder,
  keybindingFocusInput,
}) => {
  const [_value, setValue] = React.useState('');
  const value = externalValue ?? _value;

  const refInput = React.useRef(null);

  const [selectedHashtag, setSelectedHashtag] = useState(null);
  const enabledLists = useSelector(getEnabledLists);
  const listsWithHashtags = {};
  const hashtags = [];
  enabledLists.forEach((list) => {
    let hash = listNameToHashtag(getListNameWithSuffix(list));
    listsWithHashtags[hash] = list;
    hashtags.push(hash);
  });

  const refLastMatch = React.useRef(null);

  // const notifyChange = (newValue) => {
  //   let res,
  //     newHashtags = [];
  //   const hashtagRegex = /#[^\s#]+/g;
  //   while ((res = hashtagRegex.exec(newValue)) !== null) {
  //     newHashtags.push(res[0]);
  //   }
  //   onChange(newValue, newHashtags);
  // };

  const hashtagFilter = (options, params) => {
    // console.log({ options, params });
    let res = lastHashtagRegex.exec(params.inputValue);
    if (!res) return [];
    let match = res[1];
    refLastMatch.current = match;
    return filter(options, { ...params, inputValue: match });
    // return AutoComplete.caseInsensitiveFilter(match, item)
    // return Autocomplete.fuzzyFilter(match, item)
  };

  const handleInputChange = (val) => {
    setValue(val);
    onChange(val);
  };

  const handleHashtagSelect = (newSelectedHashtag) => {
    setSelectedHashtag(newSelectedHashtag);
    onListSelect?.(listsWithHashtags[newSelectedHashtag]?.id);
    const replacePartialTextWith = stripHashtagOnSelect ? '' : newSelectedHashtag;
    handleInputChange(value.replace(new RegExp(refLastMatch.current + '$'), replacePartialTextWith));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedListId = listsWithHashtags[selectedHashtag]?.id || null;

    try {
      await onEnter(value, selectedListId, selectedHashtag);
      setValue('');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keybindingFocusInput && refInput.current) {
      hotkeys(keybindingFocusInput, () => {
        console.log('focus input');
        refInput.current.focus();
      });
    }
    return () => {
      if (keybindingFocusInput) {
        hotkeys.unbind(keybindingFocusInput);
      }
    };
  }, [keybindingFocusInput]);

  return (
    <form onSubmit={handleSubmit}>
      <Autocomplete
        freeSolo
        PopperComponent={MyPopper}
        renderInput={(params) => (
          <TextInput
            {...params}
            variant="standard"
            autoFocus={autofocus}
            inputRef={refInput}
            placeholder={placeholder ? placeholder : 'Add new task #list'}
            sx={{
              padding: '7px 4px 7px 0px',
            }}
          />
        )}
        // name="auto-input"
        disableClearable
        selectOnFocus
        // it's important to be in portal, otherwise calendar doesn't ignore clicks on options
        // and selection is destroyed before we can handle
        // disablePortal
        value={value}
        fullWidth
        filterOptions={hashtagFilter}
        onChange={(event, selectedItem, reason, details) => {
          console.log('onChange', { event, selectedItem, reason, details }, refLastMatch.current);
          handleHashtagSelect(selectedItem);
        }}
        onInputChange={(e, val, reason) => {
          // console.log('onInputChange', e, val, reason)
          if (reason === 'input') {
            handleInputChange(val);
          }
        }}
        options={hashtags}
      />
    </form>
  );
};
