import React, { useState, useRef } from 'react';
import { Menu, ListItem, ListItemButton, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { Delete, DeleteOutline } from '@mui/icons-material';
import { DrawerButton } from './EventContextMenu';

export const RemoveWithConfirmListItem = ({ hint, onRemove, disabled = false, button = false, iconButton = false }) => {
  const ref = useRef();
  const [confirm, setConfirm] = useState(false);
  let content;
  if (button) {
    content = (
      <DrawerButton
        ref={ref}
        onClick={() => setConfirm(true)}
        disabled={disabled}
        variant="outlined"
        sx={{
          background: 'none',
          color: 'rgb(255, 158, 83)',
          '&:hover': { color: (theme) => theme.palette.orange.main },
        }}
        startIcon={<Delete />}
      >
        Remove {hint}
      </DrawerButton>
    );
  } else if (iconButton) {
    content = (
      <IconButton
        ref={ref}
        onClick={() => setConfirm(true)}
        disabled={disabled}
        sx={{
          '&:hover .MuiSvgIcon-root': { color: 'red' },
        }}
      >
        <DeleteOutline color="faded" />
      </IconButton>
    );
  } else {
    content = (
      <ListItemButton ref={ref} onClick={() => setConfirm(true)} disabled={disabled}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>Remove</ListItemText>
        <span style={{ color: 'rgb(200,200,200)', margin: -1 }}>{hint}</span>
      </ListItemButton>
    );
  }
  return (
    <>
      {content}
      <Menu
        open={confirm}
        anchorEl={ref.current}
        PaperProps={{
          style: {
            width: ref.current?.offsetWidth + 10,
            minWidth: button || iconButton ? '300px' : 'auto',
          },
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={() => setConfirm(false)}
      >
        {/* <ListItem disabled>Are you sure?</ListItem> */}
        <ListItemButton
          sx={{
            color: 'red',
          }}
          onClick={onRemove}
        >
          <ListItemIcon>{<DeleteOutline sx={{ color: 'red' }} />}</ListItemIcon>
          {'Remove ' + hint}
        </ListItemButton>
      </Menu>
    </>
  );
};
