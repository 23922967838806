import moment from 'moment';
import { red, pink, deepPurple, lightBlue, cyan, teal, amber, deepOrange, brown, blueGrey } from '@mui/material/colors';
import * as Sentry from '@sentry/react';
import { detectProvider, generateEventIdWithProvider } from 'shared';

export * from './useLocalStorageState';

export const mobileScreen = '(max-width: 1050px)';
export const desktopScreen = '(min-width: 1050px)';

const red400 = red[400],
  pink400 = pink[400],
  deepPurple400 = deepPurple[400],
  lightBlue400 = lightBlue[400],
  cyan400 = cyan[400],
  teal400 = teal[400],
  amber600 = amber[600],
  deepOrange400 = deepOrange[400],
  brown400 = brown[400],
  blueGrey400 = blueGrey[400];

export const localStorageGetItem = (key: string, defaultValue = null, onError = (err: Error) => {}): string | null => {
  try {
    const localStorageValue = localStorage.getItem(key);
    return localStorageValue !== null ? localStorageValue : defaultValue;
  } catch (err) {
    console.error('Error reading from localStorage:', err);
    onError(err);
    return defaultValue;
  }
};

export const localStorageSetItem = (key: string, value: string, onError = (err: Error) => {}) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.error('Error writing to localStorage:', err);
    onError(err);
  }
};

export const localStorageRemoveItem = (key: string, onError = (err: Error) => {}) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error('Error removing from localStorage:', err);
    onError(err);
  }
};

export const encode = (data) => {
  return new Buffer(data).toString('base64');
};

export const createEventFromTask = function (task, duration = null) {
  return {
    // id: (new Date()).toISOString(),
    suggestId: generateEventId(),
    title: task.title,
    task: task,
    duration: duration && duration < 1440 ? moment('2000-01-01').add(duration, 'minutes').format('HH:mm') : undefined,
  };
};

export const rescheduleEventFromTask = function (task) {
  return {
    id: new Date().toISOString(),
    task,
    title: task.title,
    eventId: task.eventId,
    beginDate: task.eventBeginDate,
    endDate: task.eventEndDate,
  };
};

// it detects badly shaped events with no all-day flag and instead
// begin: 00:00 day1 and end 00:00 day2
const reHourStart = /:00:00\.000Z$/;
export const detectAllDayEvent = function (event) {
  const { beginDate, endDate } = event;
  if (typeof beginDate !== 'string' || typeof endDate !== 'string') return false;

  if (!reHourStart.test(beginDate) || !reHourStart.test(endDate)) return false;
  const start = moment(beginDate),
    end = moment(endDate);
  return start.hour() === 0 && end.hour() === 0;
};

export const defaultColor = 'rgb(80,150,190)';

export const listColors = {
  default: { rgb: defaultColor, display: 'Trevor Blue' },
  lightBlue: { rgb: lightBlue400, display: 'Light Blue' },
  cyan: { rgb: cyan400, display: 'Cyan' },
  teal: { rgb: teal400, display: 'Teal' },
  amber: { rgb: amber600, display: 'Amber' },
  deepOrange: { rgb: deepOrange400, display: 'Orange' },
  brown: { rgb: brown400, display: 'Brown' },
  red: { rgb: red400, display: 'Red' },
  pink: { rgb: pink400, display: 'Pink' },
  deepPurple: { rgb: deepPurple400, display: 'Deep Purple' },
  blueGrey: { rgb: blueGrey400, display: 'Grey' },
  black: { rgb: 'rgb(0,0,0)', display: 'Black' },
};

export const colorIdToRGB = (colorId) => {
  let cc = listColors[colorId];
  return cc ? cc.rgb : defaultColor;
};

export const ObjectValues = (obj) => Object.keys(obj).map((key) => obj[key]);
export const ObjectValuesWithKey = (obj) => Object.keys(obj).map((key) => ({ ...obj[key], key }));

export const highestItemOrderInList = (tasks, listId) =>
  tasks.reduce((value, task) => {
    if (task.listId === listId && task.item_order > value) return task.item_order;
    return value;
  }, 0);

let tempIdIndex = 1;
export const generateTempId = () => `${Math.random().toString().slice(2)}-${++tempIdIndex}`;

export const getDefaultEventProvider = (): string | null => {
  const defaultCalendarId = (window as any).reduxStore.getState().account.user.defaultCalendarId;
  // if(!defaultCalendarId) throw new Error('Cannot determine default event provider')
  if (!defaultCalendarId) return null;
  return detectProvider(defaultCalendarId);
};

export const generateEventId = (provider = getDefaultEventProvider() || 'n/a') => generateEventIdWithProvider(provider);

export const generateNewEventDescription = (id) => {
  const url = 'https://app.trevorai.com/app?event=' + encodeURIComponent(id);
  return `Open in Trevor AI:\n${url}`;
};

export const logError = (err, context) => {
  if (process.env.NODE_ENV === 'development') {
    // console.error(err, context)
    console.log('Error:', err, context);
  } else {
    if (typeof context === 'string') {
      context = {
        hint: context,
      };
    }
    Sentry.withScope((scope) => {
      scope.setExtra('extra', context);
      Sentry.captureException(err);
    });
  }
};

export const extractURLRequestParams = () => {
  //https://demo42.herokuapp.com/login_entry?
  //account_linking_token=ARQBZWrPD38uh1Odr29w5fnouISaccgXp7-sgivjX4gffnWeRmzHU7gxaCpims2RykSUucQYzoltRQiB3EcsficPoE_r54vtyFPqlo6YJmBAWg
  //redirect_uri=https%3A%2F%2Fmessenger.com%2Fmessenger_platform%2Faccount_linking%2F%3Faccount_linking_token%3DARQBZWrPD38uh1Odr29w5fnouISaccgXp7-sgivjX4gffnWeRmzHU7gxaCpims2RykSUucQYzoltRQiB3EcsficPoE_r54vtyFPqlo6YJmBAWg

  // a=42&b=33
  let parts = window.location.search.substring(1).split('&');
  let params = {}; // param: value
  parts.forEach((part) => {
    // a=42
    let pp = part.split('=');
    params[pp[0]] = decodeURIComponent(pp[1]);
  });
  return params;
};

export type Dateish = string | Date | moment.Moment;

export const Date_max = <T extends Dateish>(date1: T, date2: T) => (moment(date1).isAfter(date2) ? date1 : date2);
export const Date_min = <T extends Dateish>(date1: T, date2: T) => (moment(date1).isBefore(date2) ? date1 : date2);

export const isDateOverdue = (date: Dateish) => {
  const today = moment().startOf('day').add(1, 'seconds').toISOString();

  const endDate = typeof date === 'string' && date?.length > 10 ? date : moment(date).toISOString();

  return endDate < today;
};

export const isOverdue = (task) => {
  if (task.completed || !(task.eventId || task.nextInstEventId)) return false;

  return isDateOverdue(task.eventEndDate);
};

// returns 0 or unix timestamp of snoozeOverdueSuggestionsUntil
export const isOverdueSuggestionsSnoozed = (): number => {
  const snoozeOverdueSuggestionsUntil = (window as any).reduxStore.getState().ui.snoozeOverdueSuggestionsUntil;
  const timeNow = moment().valueOf();
  if (!snoozeOverdueSuggestionsUntil || snoozeOverdueSuggestionsUntil < timeNow) return 0;
  return snoozeOverdueSuggestionsUntil;
};

export const isPremium = (user) => {
  let { premiumUntil, premiumPlan } = user;
  if (!premiumUntil || premiumPlan === 'free') return false;

  let date = moment().format('YYYY-MM-DD');
  return date <= premiumUntil;
};

export const activeModules = (user, userIsPremium) => {
  const modules = user.modules || {};
  if (userIsPremium) {
    // modules['schedule'] = true;
    modules['accounts'] = true;
    modules['progress'] = true;
  } else {
    return {};
  }
  for (const id in modules) {
    if (!modules[id]) delete modules[id];
  }
  return modules;
};

export const isExperimental = () => {
  return window.sessionStorage.getItem('experimental') === 'true';
};

export const getRecaptchaToken = (onToken) => {
  console.log('set recaptcha');
  (window as any).grecaptcha
    .execute('6LdNGWoUAAAAAC8NOhWtNjkE6R8YH1OmVKIt1HbL', { action: 'share_list' })
    .then((token) => {
      onToken(token);
    });
};

export const getFirstDayOfWeek = () => {
  const firstDayOfWeek = localStorageGetItem('firstDayOfWeek');
  if (firstDayOfWeek) {
    return Number(firstDayOfWeek);
  }
  if (localStorageGetItem('calendarLocale') === 'en-gb') {
    return 1; // Monday
  }
  return 0; // Sunday
};

// TODO move to common lib
const googleSuffix = '@google';
const reGoogleSuffix = new RegExp(googleSuffix + '$');
const microsoftSuffix = '@microsoft';
const reMicrosoftSuffix = new RegExp(microsoftSuffix + '$');
const trevoraiSuffix = '@trevorai';
const reTrevoraiSuffix = new RegExp(trevoraiSuffix + '$');
const todoistSuffix = '@TrevorOrig:2';
const reTodoistSuffix = new RegExp(todoistSuffix + '$');
// const reTrevor = /^-.+$/;

export const detectOrigin = function (listOrTaskId) {
  if (reGoogleSuffix.test(listOrTaskId)) {
    return 'google';
  } else if (reMicrosoftSuffix.test(listOrTaskId)) {
    return 'microsoft';
  } else if (reTrevoraiSuffix.test(listOrTaskId)) {
    return 'trevorai';
  } else if (reTodoistSuffix.test(listOrTaskId)) {
    return 'todoist';
  }
  // else if (reTrevor.test(listOrTaskId)) {
  //   return 'trevor';
  // }
  const pieces = listOrTaskId.split('@');
  if (pieces.length === 1) {
    return 'trevor';
  } else if (pieces.length >= 2) {
    return pieces.pop();
  }
  console.log('Id', listOrTaskId, ' - cannot detect origin');
  return null;
};

export const linkToEvent = (event, accountEmail) => {
  const origin = detectOrigin(event.id);
  switch (origin) {
    case 'google':
      return event.htmlLink + (accountEmail ? `&authuser=${encodeURIComponent(accountEmail)}` : '');
    case 'microsoft':
      // seems better way, but doesn't work correctly when opened in outlook
      //return event.webLink;
      const id = event.id.replace(microsoftSuffix, '');
      // it may need to be more complex than that, check webLink on https://learn.microsoft.com/en-us/graph/api/resources/event?view=graph-rest-1.0
      return `https://outlook.live.com/calendar/item/${encodeURIComponent(id)}`;
    default:
      return null;
  }
};
