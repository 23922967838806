import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './common/firebase_init';
import {
  fbSignOut,
  listenForUserChange,
  saveToUserProfile,
  signInOrLinkWithCredentialAndUpdateProfile,
} from './common/firebase';
import { sendEmailVerification, signInAnonymously, getAuth } from 'firebase/auth';
import { logError, extractURLRequestParams, localStorageSetItem, isExperimental } from 'common/utils';
import {
  checkEmailVerified,
  attachGoogleAccount,
  attachMicrosoftAccount,
  connectIntegrationFinal,
} from './api/account';
import { predictDuration } from './api';

import './styles.scss';
// import LoginPage from './login.jsx';
// import MuiThemeProvider from '@mui/material/styles/MuiThemeProvider'
import TermsOfService, { IubendaPolicy } from './components/TOS.jsx';
import { SpinnerWithFunnyStatuses } from './components/SpinnerWithFunnyStatuses.jsx';
import Analytics from './analytics.jsx';

/* Uncomment below code to fallback to smallchat */
// import * as scriptjs from 'scriptjs';
// (window as any).__chatFallback = true;
// scriptjs('https://embed.small.chat/T52NZSV7YG52P18UAJ.js');

const { location } = window;
// const originalHref = location.href;

const { REACT_APP_SENTRY_DSN } = process.env;
if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    // integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

let App;
let googleSignInInProgress;
let urlParams;
if (location.search) {
  urlParams = extractURLRequestParams();
  // window.history.replaceState(null, null, window.location.pathname);
  if (urlParams && urlParams.mode !== 'select') window.history.pushState(null, null, window.location.pathname);
}

// FB messenger login
const isFbMessengerLogin = /^\/login_entry/.test(window.location.pathname);
console.log(window.location.pathname, { isFbMessengerLogin, urlParams });

const storeAccessToken = (accessToken) => {
  // document.cookie=`new_access_token=${accessToken}`;
  // window.localStorage.setItem('accessToken', accessToken);
  localStorageSetItem('accessToken', accessToken, (err) => {
    console.log('Error storing access token', err, 'Try sessionStorage');
    sessionStorage.setItem('accessToken', accessToken);
  });
};

const restoreRefcodeFromSessionStore = () => {
  const refcode = window.sessionStorage.getItem('refcode');
  if (refcode) {
    window.sessionStorage.removeItem('refcode');
  }
  console.log('REFCODE', refcode);
  return refcode;
};

const root = createRoot(document.getElementById('app_root')!);
(window as any).app_root = root;

const render = (component) => {
  root.render(component);
};

const ResendLink = ({ user }) => {
  const [sendingStatus, setSendingStatus] = React.useState(null);

  if (sendingStatus === null)
    return (
      <a
        href=""
        style={{ color: 'white', textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          setSendingStatus(false);
          Analytics.event({ category: 'App', action: 'Resend verification link' });
          sendEmailVerification(user).then(() => setSendingStatus(true));
        }}
      >
        Resend
      </a>
    );
  if (sendingStatus === false) return <span>...</span>;
  return <span>Sent.</span>;
};

let googleCredConsumed = false;
let chatInitialOpen = false;

const ROUTE = (path) => window.location.pathname.includes(path);

async function onUserChange(user) {
  if (user) {
    let token = await user.getIdToken();
    storeAccessToken(token);
  }

  const { LiveChatWidget, LiveChatFab } = await import(
    /* webpackChunkName: "livechat" */ './components/LiveChatWidget'
  );
  const chatComponent = !(window as any).__chatFallback ? (
    <>
      {user ? (
        <LiveChatWidget initialOpen={chatInitialOpen} user={user} />
      ) : (
        <LiveChatFab
          onClick={() => {
            chatInitialOpen = true;

            signInAnonymously(getAuth())
              .then(() => {
                console.log('Signed in anonymously');
              })
              .catch((error) => {
                console.error('Error signing in anonymously:', error);
              });
          }}
        />
      )}
    </>
  ) : (
    <span />
  );

  if (!user || user.isAnonymous) {
    if (!googleCredConsumed && urlParams && urlParams.id_token) {
      googleCredConsumed = true;
      return signInOrLinkGoogleAccount(urlParams);
    }

    if (googleSignInInProgress) return;

    if (ROUTE('/app/terms')) {
      return render(
        <TermsOfService
          open
          onClose={() => {
            window.location.pathname = '/app';
          }}
        />
      );
    } else if (ROUTE('/app/privacy-policy')) {
      return render(
        <IubendaPolicy
          open
          onClose={() => {
            window.location.pathname = '/app';
          }}
        />
      );
      // return render(<MuiThemeProvider><IubendaPolicy open onClose={() => {
      //     window.location.pathname = '/app'
      // }} /></MuiThemeProvider>)
    }

    let mode;
    if (ROUTE('/app/landing')) {
      mode = 'premium';
    } else if (ROUTE('/app/todoist-landing')) {
      mode = 'todoist';
    }

    import(/* webpackChunkName: "login" */ './login.jsx')
      .then(({ default: LoginPage }) => {
        render(
          <>
            <LoginPage
              mode={mode}
              redirect_uri={window.location.origin + window.location.pathname}
              data={JSON.stringify(urlParams)}
            />
            {chatComponent}
          </>
        );
      })
      .catch((err) => {
        console.log('Error loading login module', err);
      });
  } else {
    if (!user.emailVerified) {
      if (user.providerData.find((p) => p.providerId === 'facebook.com')) {
        checkEmailVerified();
      } else {
        render(
          <>
            <div className="login-entry-logged-in">
              <div>
                Your email {user.email} is not yet verified. Please check you email and click the verification link.
              </div>
              <hr />
              <div>
                Cannot find it or expired? <ResendLink user={user} />
              </div>
              <hr />
              <a href="" style={{ color: 'white', textDecoration: 'underline' }} onClick={() => fbSignOut()}>
                Logout
              </a>
            </div>
            {chatComponent}
          </>
        );
        return;
      }
    }

    if (!googleCredConsumed && urlParams && urlParams.id_token) {
      googleCredConsumed = true;

      if (ROUTE('/app/settings/connect-google-calendar')) {
        Analytics.event({ category: 'Auth', action: 'Add Google Calendar Account' });
        console.log('Attach another Google account', urlParams.id_token);

        return attachGoogleAccount(urlParams)
          .catch(function (error) {
            console.log('Error attaching google account:', error);
            alert('Could not attach google account. Please try again');
          })
          .then(() => {
            location.href = '/app/settings';
          });
      }

      return signInOrLinkGoogleAccount(urlParams, user);
    } else if (isFbMessengerLogin) {
      let textForUser = 'You will be redirected in a few moments.';

      let { account_linking_token, redirect_uri } = urlParams || {};
      if (!account_linking_token) {
        // it happens after redirect back from google signin
        if (urlParams && urlParams.data) {
          const fbData = JSON.parse(urlParams.data);
          account_linking_token = fbData.account_linking_token;
          redirect_uri = fbData.redirect_uri;
          Analytics.event({ category: 'Auth', action: 'Messenger login success' });
        } else {
          textForUser = 'Something went wrong. Try to logout and then log in again.';
          Analytics.event({ category: 'Auth', action: 'Messenger login error' });
        }
      }
      console.log('FB params:', { account_linking_token, redirect_uri });

      render(
        <>
          <div className="login-entry-logged-in">
            <div>
              Signed in as
              <br />
              {user.email}
              <hr />
              {textForUser}
            </div>
            <hr />
            <a href="" style={{ color: 'white', textDecoration: 'underline' }} onClick={() => fbSignOut()}>
              Logout
            </a>
          </div>
          {chatComponent}
        </>
      );

      // if google sign, give it time to store tokens and
      return setTimeout(() => window.location.replace(redirect_uri + '&authorization_code=' + user.uid), 1000);
    } else if (ROUTE('/app/settings/connect-outlook-calendar')) {
      Analytics.event({ category: 'Auth', action: 'Add Outlook Calendar Account' });
      console.log('Attach another microsoft account', urlParams);
      return attachMicrosoftAccount(urlParams)
        .catch(function (error) {
          console.log('Error attaching outlook account:', error);
          alert('Could not attach outlook account. Please try again');
        })
        .then(() => {
          location.href = '/app/settings';
        });
    } else if (ROUTE('/app/settings/verify-connect-integration')) {
      Analytics.event({ category: 'Auth', action: 'Connected integration' });
      console.log('Integration validation');
      return connectIntegrationFinal(urlParams)
        .catch(function (error) {
          console.log('Error validating integration:', error);
          alert('Could not validate integration. Please try again');
        })
        .then(() => {
          location.href = '/app/settings';
        });
    }

    try {
      if (App === undefined) {
        App = await import(/* webpackChunkName: "app" */ './app');
      }
      App.renderApp(user, chatComponent);
    } catch (err) {
      App = null;
      logError(err, 'Loading main module');
      // if (confirm('There was an error loading main module. Do you want to reload?')) {
      //     location.reload()
      // }
    }
  }
}

function signInOrLinkGoogleAccount(urlParams, existingUser = null) {
  Analytics.event({ category: 'Auth', action: 'Completed Google Sign in' });
  console.log('Sign in with google credential', urlParams.id_token);
  googleSignInInProgress = true;
  signInOrLinkWithCredentialAndUpdateProfile(urlParams.id_token, existingUser)
    .then((user) => {
      googleSignInInProgress = false;

      if (urlParams.access_token) {
        let data: any = {
          googleAccessToken: urlParams.access_token,
          googleAccessTokenExpiryDate: urlParams.expiry_date,
        };
        if (urlParams.refresh_token) {
          Analytics.event({ category: 'Auth', action: 'Completed Google Sign in with Refresh Token' });
          data.googleRefreshToken = urlParams.refresh_token;
        }

        // GMT+02:00 will return -120, we'll keep it in more natural format
        data.timezoneOffset = new Date().getTimezoneOffset() * -1;
        try {
          const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
          if (timeZone && locale) data = { ...data, timeZone, locale };
        } catch (err) {
          console.log('Error getting timezone and locale', err);
        }

        const refcode = restoreRefcodeFromSessionStore();
        if (refcode) {
          data.refcode = refcode;
        }

        return saveToUserProfile(user.uid, data).then(() => user);
      }
    })
    .then((user) => {
      predictDuration(['init trevor ml']).catch((err) => console.log('init ml did not went well', err));

      // if(isFbMessengerLogin) {
      //     const { account_linking_token, redirect_uri } = JSON.parse(urlParams.data)
      //     console.log('FB params:', { account_linking_token, redirect_uri })

      //     return window.location.replace(redirect_uri + '&authorization_code=' + user.uid);
      //     // return asyncFetchPSID(FB_PAGE_ACCESS_TOKEN, account_linking_token)
      //     //         .then(psId => {
      //     //             user.fb_messenger_psid = psId
      //     //             return saveToUserProfile(user.uid, user)
      //     //         })
      //     //         .then(() => {
      //     //             window.location.replace(redirect_uri + '&authorization_code=' + user.uid);
      //     //         })
      // }
      // let mode = window.sessionStorage.getItem('login_mode')
      // if(mode === 'popup')
      if (!isFbMessengerLogin && window.name === 'Sign In') {
        Analytics.event({ category: 'App', action: 'Auto close after sign in' });
        console.log('will close in 1 sec...');
        setTimeout(() => window.close(), 1000);
      }
    })
    .catch(function (error) {
      console.log('Error signing in to firebase:', error);
      logError(error, ['Error signing in to firebase', urlParams]);
      alert(`Could not sign in. ${error.message || 'Please try again'}`);
      location.href = '/';
    });
}

// main

if (urlParams) {
  if (urlParams.experimental) {
    window.sessionStorage.setItem('experimental', 'true');
  }
  if (urlParams.date) {
    window.sessionStorage.setItem('goto-date', urlParams.date);
  }
  if (urlParams.event) {
    window.sessionStorage.setItem('initial-event', urlParams.event);
  }
  if (urlParams.initial) {
    window.sessionStorage.setItem('initial-screen', urlParams.initial);
  }
  if (urlParams.login_mode) {
    window.sessionStorage.setItem('login_mode', urlParams.login_mode);
  }
  if (urlParams.ref) {
    window.sessionStorage.setItem('refcode', urlParams.ref);
  }
}

// const FB_PAGE_ACCESS_TOKEN = ''
// function asyncFetchPSID(account_linking_token)
// {
//     let url = `https://graph.facebook.com/v2.6/me?access_token=${FB_PAGE_ACCESS_TOKEN}&fields=recipient&account_linking_token=${account_linking_token}`;
//     return fetch(url)
//         .then(res => {
//             if(res.status !== 200) return Promise.reject(res.statusText)
//             return res.json()
//         })
//         .then(data => {
//             console.log('PSID retrieval result:', data)
//             return data.recipient
//         })
// }

render(<SpinnerWithFunnyStatuses />);
// it seems calling this too soon on mobile sometimes leads to error and 30 sec timeout
setTimeout(() => {
  listenForUserChange(onUserChange);
}, 1000);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (isExperimental()) {
  serviceWorkerRegistration.register();
}
