import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material';
import { desktopScreen, mobileScreen } from 'common/utils';
// import { grey } from '@mui/material/colors';

// let colorScheme = 'default' // get from local storage
// let gradientLeft, gradientRight, boxBackground
// switch(colorScheme) {
//   case 'deepmode':
//     gradientLeft = '#2e6aa6' // slightly brighter than the old: rgba(41,93,145,1)
//     gradientRight = '#72ccb4' // slightly darker than old: rgba(130,230,203,1)
//     boxBackground = 'rgb(225,235,240)' // imitates slightly transparent glass with blue/green hint
//     mainBackground = 'none' // Removes calendar background
//     mainShadow = 'none'
//     break;

//   case 'darkmode':
//     gradientLeft = '#2e6aa6' // slightly brighter than the old: rgba(41,93,145,1)
//     gradientRight = '#72ccb4' // slightly darker than old: rgba(130,230,203,1)
//     boxBackground = 'rgb(225,235,240)' // imitates slightly transparent glass with blue/green hint
//     mainBackground = 'none' // Removes calendar background
//     mainShadow = 'none'
//     break;

//   default:
//     gradientLeft = '#2e6aa6' // slightly brighter than the old: rgba(41,93,145,1)
//     gradientRight = '#72ccb4' // slightly darker than old: rgba(130,230,203,1)
//     boxBackground = 'rgb(225,235,240)' // imitates slightly transparent glass with blue/green hint
//     mainBackground = 'rgb(225,235,240)' // imitates slightly transparent glass with blue/green hint
//     mainShadow = ''
// }

const opts = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // $mobileXSBreakPoint: 768px;
      md: 1050, // $mobileBreakPoint: 1050px;
      lg: 1200, // $smallScreenBreakpoint: 1200px;
      xl: 1536,
    },
  },
  mobileScreen,
  desktopScreen,
  palette: {
    // textColor: grey[900],
    primary: {
      main: 'rgb(80,150,190)',
      light: 'rgb(90,160,200)',
      dark: 'rgb(2, 119, 189)',
      highlight: 'rgba(80,150,190,0.3)',
    },
    secondary: {
      main: '#FF8C2C', // old rgb(240, 160, 55)
    },
    purple: {
      main: '#505fbe',
    },
    orange: {
      main: 'rgb(255,112,67)',
    },
    warning: {
      main: '#eb9c2b', // dark yellow, contrasting with orange list color
    },
    overdue: {
      main: '#e8893c',
    },
    danger: {
      main: '#ef3432', // red
    },
    teal: {
      main: '#50beaf',
    },
    info: {
      main: 'rgb(107, 107, 107)', // grey
      light: 'rgb(175,175,175)', // light grey
    },
    gold: {
      main: '#FFD700',
    },
    darkBlue: {
      main: '#638EAF',
    },
    faded: {
      main: '#ACBAC3', // faded gray with blue tint
    },
    gradient: {
      lightBlue: 'linear-gradient(90deg, #4ED8FF 0%, #82E6CB 100%)',
    },
    // primary1Color: 'rgb(80,150,190)',
    // disabledColor: 'rgb(200,200,200)',
    // pickerHeaderColor: 'rgb(80,150,190)',
    // gradientLeft: '#2e6aa6', // slightly brighter than the old: rgba(41,93,145,1)
    // gradientRight: '#72ccb4', // slightly darker than old: rgba(130,230,203,1)
    boxBackground: 'rgb(225,235,240)', // imitates slightly transparent glass with blue/green hint
    whiteBackground: 'white', // invert manually in dark mode, since not all boxes use Paper
  },
  shadow: {
    soft: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    box: '0px 0px 15px rgba(0,0,0,0.15)',
    task: '0px 1px 3px rgba(0,0,0,0.25)',
    taskHover: 'rgba(0, 0, 0, 0.18) 0px 1px 5px, rgba(0, 0, 0, 0.18) 0px 1px 6px',
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: 5,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '30px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // I am Groot
          textAlign: 'center',
          boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
          lineHeight: 1,
          zIndex: 1,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // I am Groot
          textAlign: 'center',
          boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // I am Groot
          paddingTop: '24px !important', // Mui sets this to 0 if a dialog title is present
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // I am Groot
          borderRadius: '8px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // I am Groot
          zIndex: '1499', // Godlike view, but 1 below tour popups for mobile
          borderRadius: '8px',
        },
      },
    },
  },
  leftPane: {
    width: '460px',
    hidden: {
      width: '0',
    },
    slim: {
      width: '380px',
    },
    full: {
      width: '100%',
    },
  },
  appBar: {
    height: '56px',
    background: 'none',
    boxShadow: 'none',
    logoHeight: '42px',
  },
  mobileBar: {
    height: '54px',
  },
  pageNav: {
    height: '48px',
  },
  mainBox: {
    margin: '5px',
    padding: '6px',
    paddingInt: 6,
    radius: '28px',
    radiusInt: 28,
    background:
      'linear-gradient(125deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.70) 38.11%, rgba(255, 255, 255, 0.80) 76.05%, rgba(255, 255, 255, 0.50) 100%)',
  },
  inbox: {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  eventContextMenu: {
    width: '540px',
    widthExpanded: '50vw', // 80vw on mobile
  },
  svgIcon: {
    color: 'rgb(110,110,110)',
  },
  datePicker: {
    selectColor: 'rgb(80,150,190)',
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    shadow: any;
    leftPane: any;
    appBar: any;
    mobileBar: any;
    pageNav: any;
    mainBox: any;
    inbox: any;
    eventContextMenu: {
      width: string;
      widthExpanded: string;
    };
    svgIcon: any;
    datePicker: any;
  }
}

export const theme = createTheme(opts as ThemeOptions);
