import React from 'react';
import { simulateMouseEvent } from './Draggable';
const { $ } = window;

export class TouchmoveAfterLongPress extends React.PureComponent {
  LONG_PRESS_TIMEOUT = 300;

  constructor() {
    super();
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  onDragStart(event) {
    let { onDragStart } = this.props;
    this.drag = true;
    simulateMouseEvent(event, 'mouseover');
    simulateMouseEvent(event, 'mousemove');
    simulateMouseEvent(event, 'mousedown');
    $(document.body).addClass('user-select-none');
    $(event.target).on('touchmove', (e) => {
      simulateMouseEvent(e, 'mousemove');
    });
    onDragStart && onDragStart();
  }
  onDragStop(event) {
    if (!this.drag) return;

    let { onDragStop } = this.props;
    this.drag = false;
    $(event.target).removeClass('touchmove');
    $(document.body).removeClass('user-select-none');
    simulateMouseEvent(event, 'mouseup');

    onDragStop && onDragStop();
  }

  onTouchStart(e) {
    // console.log('onTouchStart')
    let event = e.nativeEvent;
    this.timeout = setTimeout(() => {
      // console.log('Drag detected')
      this.onDragStart(event);
    }, this.LONG_PRESS_TIMEOUT);
  }
  onTouchMove(e) {
    if (this.drag) return;
    // console.log('onTouchMove')
    clearTimeout(this.timeout);
  }
  onTouchEnd(e) {
    // console.log('onTouchEnd')
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.onDragStop(e.nativeEvent);
  }
  render() {
    return React.cloneElement(React.Children.only(this.props.children), {
      onTouchStart: this.onTouchStart,
      onTouchMove: this.onTouchMove,
      onTouchEnd: this.onTouchEnd,
    });
    // 	>
    // 		{this.props.children}
    // 	</div>
    // )
  }
}
