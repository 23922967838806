import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/system';
import MediaQuery from 'react-responsive';
import { theme } from '../theme';
import Analytics from './../analytics.jsx';

const DraggableItem = styled('div')`
  margin: 0 0 3px 0;

  & .task-card,
  & .list {
    margin: 0 !important;
  }
`; // Passing the provided.draggableProps.style as a prop creates performance issues

const getListStyle = (isDraggingOver) => ({
  listStyle: 'none',
  paddingLeft: 0,
  margin: '0 6px',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const SortableList = ({
  className,
  handleClass, // can we and do we need to use it?
  placeholderClass, // TODO use it
  // onTouchDrag, onTouchDragStop,
  items: _items,
  children,
  renderItem,
  onChange,
  onDragStart,
  dragAndDropTaskScheduling, // We handle lists and tasks differently
  disableSorting,
}) => {
  const items = _items || children || [];
  // console.log('SortableList', items);
  const prefix = useMemo(() => Date.now(), []);
  const [modifiedItems, setModifiedItems] = useState(null);
  useEffect(() => {
    setModifiedItems(null);
  }, [items]);

  const cachedContents = useRef({});
  const isDragging = useRef(false);

  const handleDragStart = (result) => {
    // console.log('onDragStart', result);

    isDragging.current = true;
    if (dragAndDropTaskScheduling) {
      onDragStart?.(); // change from Inbox to Calendar view on mobile
    }
  };

  const onDragEnd = (result) => {
    // console.log('onDragEnd', result);
    isDragging.current = false;

    // dropped outside the list
    if (!result.destination || result.source.index === result.destination.index) {
      if (dragAndDropTaskScheduling) {
        Analytics.event({
          category: 'Scheduling',
          action: 'Dragged Task from Inbox',
        });
        // Reorder Tasks and Reorder Lists already available for Analytics
      }
      return;
    }
    const currentItems = modifiedItems || items;

    // list [0,1,2,3,4,5,6,7,8,9]
    const indexList = currentItems.map((it, index) => index);

    const newItemsIds = reorder(indexList, result.source.index, result.destination.index);
    const newItems = reorder(currentItems, result.source.index, result.destination.index);
    setModifiedItems(newItems);

    // console.log('onDragEnd newOrder', newItemsIds, newItems);
    onChange?.(newItemsIds);
  };
  return (
    <>
      <MediaQuery query={theme.desktopScreen}>
        <div style={{ position: 'relative' }} key={items.length}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
            <Droppable droppableId="droppable" isDropDisabled={disableSorting}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={className}
                >
                  {(modifiedItems || items).map((it, index) => {
                    const key = it.id || `${prefix}-${index}`;
                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            isDragging.current = true;
                          }
                          let content;
                          if (isDragging.current) {
                            content = cachedContents.current?.[key];
                          } else {
                            content = renderItem?.(it, index) || it;
                            cachedContents.current[key] = content;
                          }
                          return (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              className={snapshot.isDragging && dragAndDropTaskScheduling ? 'task-in-move' : undefined}
                            >
                              {content}
                            </DraggableItem>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </MediaQuery>
      <MediaQuery query={theme.mobileScreen}>
        <div style={{ position: 'relative' }}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
            {/* Use renderClone for mobile, since we hide the Inbox - https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/table/with-clone.jsx */}
            <Droppable
              droppableId="droppable"
              renderClone={(provided, snapshot, rubric) => {
                const item = modifiedItems?.[rubric.source.index] || items?.[rubric.source.index];
                // const key = item?.id || `${prefix}-${rubric.source.index}`;
                const content = renderItem?.(item, rubric.source.index) || item;
                return (
                  <DraggableItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className={snapshot.isDragging && dragAndDropTaskScheduling ? 'task-in-move' : undefined}
                  >
                    {content}
                  </DraggableItem>
                );
              }}
              getContainerForClone={() => document.body}
              isDropDisabled={disableSorting}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={className}
                >
                  {(modifiedItems || items).map((it, index) => {
                    const key = it.id || `${prefix}-${index}`;
                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            isDragging.current = true;
                          }
                          let content;
                          if (isDragging.current) {
                            content = cachedContents.current?.[key];
                          } else {
                            content = renderItem?.(it, index) || it;
                            cachedContents.current[key] = content;
                          }
                          return (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              className={snapshot.isDragging && dragAndDropTaskScheduling ? 'task-in-move' : undefined}
                            >
                              {content}
                            </DraggableItem>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </MediaQuery>
    </>
  );
};

// export class SortableListOld extends React.Component {
//   constructor() {
//     super();
//     this.__refreshPrefix();
//   }
//   __refreshPrefix() {
//     this.prefix = Date.now();
//   }
//   componentDidMount() {
//     const { placeholderClass } = this.props;
//     let element = $(this.refs.container);
//     let outside;
//     element.sortable({
//       // handle: handleClass && `.${handleClass}`,
//       handle: '.drag-handle',
//       placeholder: placeholderClass,
//       cursorAt: { left: 150, top: 6 },
//       appendTo: 'body',
//       // change: function( event, ui ) {
//       // 	console.log("change", event, ui)
//       // },
//       over: function (event, ui) {
//         outside = false;
//       },
//       out: function (event, ui) {
//         outside = true;
//       },
//       update: (event, ui) => {
//         if (outside) {
//           $('.ui-sortable').sortable('cancel');
//         }
//       },
//       deactivate: (event, ui) => {
//         let newOrder = element.sortable('toArray').map((it) => Number(it));
//         this.__refreshPrefix();
//         this.props.onChange(newOrder);
//       },
//     });
//   }
//   render() {
//     const { className, onTouchDrag, onTouchDragStop, items, renderItem } = this.props;
//     const style = { listStyle: 'none', paddingLeft: 0 };
//     return (
//       <ul ref="container" style={style} className={className}>
//         {items.map((it, index) => {
//           const content = renderItem ? renderItem(it, index) : <li id={index}>{it}</li>;

//           return (
//             <TouchmoveAfterLongPress
//               key={`${this.prefix}-${index}`}
//               // these are not used???
//               onDragStart={() => onTouchDrag && onTouchDrag()}
//               onDragStop={() => onTouchDragStop && onTouchDragStop()}
//             >
//               {/* moved to SortableItemWithTaskProps because of inability to set data with prediction */}
//               {/* <li
//                 id={index}
//                 ref={(el) => {
//                   if (child.props['data-droppable-info']) {
//                     const [propname, data] = child.props['data-droppable-info'];
//                     $(el).data(propname, data);
//                   }
//                 }}
//               > */}
//               {content}
//               {/* </li> */}
//             </TouchmoveAfterLongPress>
//           );
//         })}
//       </ul>
//     );
//   }
// }
