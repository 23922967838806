import MainBox from './MainBox.jsx';
import { styled } from '@mui/system';
import { Box, Stack } from '@mui/material';

const PageBox = styled(MainBox)(({ theme }) => ({
  // backgroundColor: theme.palette.whiteBackground,
  flexDirection: 'column',
  padding: 0,
  backgroundColor: 'rgba(255,255,255,0.3)',
}));

const PageNav = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  // minHeight: theme.pageNav.height, // without padding
  padding: '3px ' + theme.mainBox.padding,
  boxShadow: theme.shadow.soft,
  zIndex: 1,
  ['& > *']: {
    maxHeight: theme.pageNav.height,
    lineHeight: theme.pageNav.height,
    marginTop: 0,
    marginBottom: 0,
  },
  ['& > h1, & > h2']: {
    fontWeight: 300,
  },
}));

const PageScroll = styled(Stack)(({ theme }) => ({
  padding: theme.mainBox.paddingInt * 3 + 'px ' + theme.mainBox.padding,
  overflowY: 'auto',
}));

export { PageBox, PageNav, PageScroll };
