import moment from 'moment';
import { Planner } from './planner';
import { generateDatesRange, wrapToTimeGrid, momentToFloat } from './utils';
import { PlanningContext } from './types';

export const generateSuggestionsNaive = (
  ctx: PlanningContext,
  fromDate: moment.Moment,
  toDate: moment.Moment,
  events: { beginDate: string; endDate: string; allDay: boolean }[],
  duration: number
): moment.Moment[] => {
  const suggestions = [];

  const eventsStartEnds = events.filter((ev) => !ev.allDay).map((ev) => ({ start: ev.beginDate, end: ev.endDate }));

  const {
    momenttz,
    prefs: { user_schedule },
  } = ctx;

  const timeNow = momenttz();

  const datesRange = generateDatesRange(ctx, fromDate, toDate);
  // const workTimeStart = user_schedule.zones[0].start / 60;
  // const workTimeEnd = user_schedule.zones[0].end / 60;

  console.log('generateSuggestions', {
    datesRange,
    // workTimeStart,
    // workTimeEnd,
    duration,
    eventsStartEnds,
    user_schedule,
  });
  for (const date of datesRange) {
    // let zones = [workTimeStart, workTimeEnd];
    if (timeNow.isSame(date, 'date')) {
      const nextSlot = moment(timeNow).add(wrapToTimeGrid(timeNow.minutes()), 'minutes');
      eventsStartEnds.push({ start: moment().startOf('day').toISOString(), end: nextSlot.toISOString() });
      // if (moment(nextSlot).add(duration, 'minutes').hours() > workTimeEnd) continue;
      // zones = [momentToFloat(nextSlot), workTimeEnd];
    }
    const planner = new Planner(date, { zones: user_schedule.zones });
    const summary = planner.getSummaryByZone(eventsStartEnds);
    const { freeZones } = Object.values(summary)[0];
    const bigEnoughZones = freeZones
      .filter((zz) => timeNow.isBefore(zz.end))
      .filter((zz) => planner.zoneDurationMinutes(zz) >= duration);
    console.log('summary', date.format(), bigEnoughZones);
    bigEnoughZones.forEach((zone) => suggestions.push(zone.start));
  }

  return suggestions;
};
