import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Analytics from '../../analytics.jsx';
import * as plannerOps from '../../operations/schedule';
import { Button, IconButton, Grid, Divider, MenuItem, Menu } from '@mui/material';
import { PremiumModalDock } from '../../premium.jsx';
import { ArrowDropDown, MoreHoriz } from '@mui/icons-material';

export const QuickSuggestions = connect((state) => {
  const { isPremium, freeEventsLeft } = state.account.user;
  // const freeLimitReached = !isPremium && !freeEventsLeft;
  const freeLimitReached = false; // 'schedule' upgrade module not currently in use
  return { freeLimitReached };
})(({ task, freeLimitReached }) => {
  const buttons = [
    'TODAY',
    'TOMORROW',
    'WEEKEND',
    'NEXT WEEK',
    'IN 2 WEEKS',
    'IN A MONTH',
    'IN 3 MONTHS',
    'SOME DAY',
  ].map((title, index) => (
    <Button
      variant="outlined"
      key={title}
      sx={{
        fontSize: '0.8rem', // index >= 4 ? 12 : 14
        width: '100%',
        height: '100%',
        padding: '5px 7px',
      }}
      onClick={() => {
        if (freeLimitReached && !task.eventId) {
          PremiumModalDock.showUpgradeModule('schedule');
          Analytics.event({
            category: 'Premium',
            action: 'Free events limit reached',
          });
          return;
        }
        plannerOps.scheduleTask(task, title);
        Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Quick Suggestions' });
      }}
    >
      {title}
    </Button>
  ));
  // return <div className="task-quick-suggestions-bar">{buttons}</div>;
  return (
    <Grid
      container
      spacing={0.5}
      justifyContent="space-around"
      sx={{ padding: '12px', alignItems: 'stretch', alignContent: 'stretch' }}
    >
      {buttons.map((b, idx) => (
        <Grid item xs={6} lg={3} key={idx}>
          {b}
        </Grid>
      ))}
    </Grid>
  );
});

export const QuickSuggestionsDropdown = ({ task, styles = {} }) => {
  const [rescheduleOptionsAnchorEl, setRescheduleOptionsAnchorEl] = useState(null);

  let reschedOps = [
    'Today',
    'Tomorrow',
    'Weekend',
    'Next week',
    <Divider key="reshed-div" />,
    'In 2 weeks',
    'In a month',
    'In 3 months',
    'Some day',
  ].map((when) => {
    if (typeof when === 'object') return when;

    return (
      <MenuItem
        key={when}
        onClick={() => {
          plannerOps.scheduleTask(task, when);
          Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Quick Suggestions' });
        }}
      >
        {when}
      </MenuItem>
    );
  });

  const handleOpen = (e) => {
    setRescheduleOptionsAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    return () => {
      setRescheduleOptionsAnchorEl(null);
    };
  }, []);

  return (
    <>
      <Button color="primary" variant="text" fullWidth sx={styles} onClick={(e) => handleOpen(e)}>
        <MoreHoriz color="inherit" />
      </Button>
      <Menu
        PaperProps={{
          sx: { minWidth: 180 },
        }}
        anchorEl={rescheduleOptionsAnchorEl}
        open={!!rescheduleOptionsAnchorEl}
        onClose={() => setRescheduleOptionsAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {reschedOps}
      </Menu>
    </>
  );
};
