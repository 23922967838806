export const LOAD_EXTENSION = 'LOAD_EXTENSION';
export const loadExtension = (extension) => ({
  type: LOAD_EXTENSION,
  extension,
});

export const UNLOAD_EXTENSION = 'UNLOAD_EXTENSION';
export const unloadExtension = (extension) => ({
  type: UNLOAD_EXTENSION,
  extension,
});

export const STORE_EXTENSION_DATA = 'STORE_EXTENSION_DATA';
export const storeExtensionData = (extension, data) => ({
  type: LOAD_EXTENSION,
  extension,
  data,
});

export const EXTENSION_DATA_LOADED = 'EXTENSION_DATA_LOADED';
export const extensionDataLoaded = (extension, data) => ({
  type: EXTENSION_DATA_LOADED,
  extension,
  data,
});
