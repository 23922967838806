import { FC } from 'react';
import { Select, Divider, MenuItem, ListItemIcon, ListItemText, ListItemButton, Tooltip, Button } from '@mui/material';
import { BorderlessSelect } from '../../components/BorderlessSelect';
import { Repeat } from '@mui/icons-material';
import moment from 'moment';
import { theme } from '../../theme';

const choises = ['Weekly', 'Every 2 weeks', 'Monthly', 'Workdays', 'Weekend', 'Everyday'];

interface SelectRecurrenceWidgetProps {
  recurrence: string | undefined;
  dueDate: string | undefined;
  onChange: (recurrence: string | null) => void;
  theme?: string;
}

export const SelectRecurrenceWidget: FC<SelectRecurrenceWidgetProps> = ({ recurrence, dueDate, onChange }) => {
  const day = (dueDate ? moment(dueDate) : moment()).format('dddd');
  return (
    <Select
      variant="outlined"
      color="primary"
      sx={{
        height: '34px',
        borderRadius: '8px',
        color: theme ? theme.palette.primary.main : 'inherit',
        border: '1px solid',
        borderColor: 'rgba(80,150,190,0.5)',
        ['&:hover']: { borderColor: 'rgba(80,150,190,1)' },
        ['svg']: { color: theme ? theme.palette.primary.main : 'inherit' },
        ['fieldset']: { border: 'none' },
        ['.MuiSelect-select']: { padding: '0 8px' },
        minWidth: '120px',
        width: '100%',
        padding: 0,
      }}
      className="task-recurrence-widget"
      value={recurrence || ''}
      displayEmpty
      renderValue={(value) => {
        return (
          <Tooltip
            title="Tasks on repeat are hidden until their next recurrence upon completion. Each recurrence is to be scheduled invidiually."
            placement="top"
            disableInteractive
          >
            <ListItemButton sx={{ padding: '2px' }}>
              <ListItemIcon sx={{ minWidth: '24px', marginRight: '8px' }}>
                <Repeat />
              </ListItemIcon>
              <ListItemText>
                {(value === 'Weekly' && `Every ${day}`) || (!!value && `Repeat: ${value}`) || 'Repeat'}
              </ListItemText>
            </ListItemButton>
          </Tooltip>
        );
      }}
      onChange={(e) => onChange((e.target.value as string) || null)}
    >
      {choises.map((choice, idx) =>
        choice === null ? (
          <Divider key={idx} />
        ) : (
          <MenuItem key={choice} value={choice}>
            {choice === 'Weekly' ? `Every ${day}` : choice}
          </MenuItem>
        )
      )}
      <Divider />
      <MenuItem value={null}>None</MenuItem>
    </Select>
  );
};
