import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../theme';

const MainBox = styled(Box)({
  background: theme.mainBox.background,
  height: 'calc(100% - ' + theme.appBar.height + ' - 5px)',
  margin: '0 5px 5px 5px', // calculate margin in height
  borderRadius: theme.mainBox.radius,
  boxShadow: theme.shadow.box,
  padding: theme.mainBox.padding,
  boxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  // overflowY: 'auto', // caused scrolling issues
  display: 'flex',
  width: 'calc(100% - ' + theme.mainBox.margin + ' - ' + theme.mainBox.margin + ')',
  // Gradient stroke for a shiny glass effect
  position: 'relative',
  zIndex: 0,
  '&::before': {
    width: '100%',
    height: '100%',
    content: '""',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    zIndex: '-1',
    inset: '0',
    padding: '1px',
    borderRadius: 'inherit',
    background:
      'linear-gradient(150deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.4) 33%, rgba(255,255,255,0.7) 66%, rgba(255,255,255,0.9) 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    boxSizing: 'inherit',
    MozBoxSizing: 'inherit',
    WebkitBoxSizing: 'inherit',
  },
});

// const MainBox = ({children, sx}) => {
//     return(<StyledMainBox id="main" sx={sx}>{children}</StyledMainBox>)
// }

export default MainBox;
