import React from 'react';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DesktopOnlyBottomNotch = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={2}
      sx={{
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
        height: theme.mainBox.radius,
        zIndex: '8',
        boxShadow: theme.shadow.soft,
        borderRadius: `calc(${theme.mainBox.radius} - 4px)`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    />
  );
};
