import { useSelector } from 'react-redux';
import { useWatchTaskPredictions } from '../common/firebase';
import { ReduxState } from '../reducers';
import { Task, extractTodoistDuration } from 'shared';

export const usePredictDurationEnabled = () => {
  return useSelector<ReduxState, boolean>((state) => state.account.user?.prefs?.duration_predictions) ?? true;
};

export const getTopPrediction = (predictions: { value: number; prob: number }[]) => {
  const suggestedDurations = predictions || [];

  // Add Smart Suggestions to the list, based on Confidence
  let orderedSuggestedDurations: number[] = suggestedDurations?.map((d) => d.value) || [];

  // const topPrediction = useSelector((state) => state.tasks.predictionsByTaskId?.[task.id])?.[0].duration;
  const topPrediction = orderedSuggestedDurations[0];
  return { topPrediction, orderedSuggestedDurations };
};

export const usePredictDuration = (task: Task) => {
  const predictDurationEnabled = usePredictDurationEnabled();

  const presetDuration = extractTodoistDuration(task);

  const { data: predictionsData, loading } = useWatchTaskPredictions(task.id, {
    skip: !predictDurationEnabled || !!presetDuration,
  });
  const taskPredictions = predictionsData || { duration: [] };
  // console.log('taskPredictions', taskPredictions);

  const { topPrediction, orderedSuggestedDurations } = getTopPrediction(taskPredictions.duration);

  return {
    topPrediction: task.duration || presetDuration || topPrediction,
    orderedSuggestedDurations,
    predictDurationEnabled,
    loading,
  };
};
