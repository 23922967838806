import { eventCompleteById, renameEventWithAssociatedTask } from './event';
import * as actions from '../actions';
import { ajax_post, ajax_put, ajax_delete } from '../api/util';
import { logError } from 'common/utils';
import { Notification } from '../components';
import Analytics from '../analytics.jsx';
import { store } from './index';
import { fbOps } from '../common/firebase';
import moment from 'moment';
import { List, Task, getExtensionsRegistry } from 'shared';
import { setDuration } from 'api/todoist';
import { scheduleTrainRequest } from 'api';
import { showModalErrorPerformingTaskEventOperation } from 'modules/tasks/showModalErrorPerformingTaskOperation';

export const addNewTask = (task) => {
  store().dispatch(actions.addTaskRequest(task.title, task.listId || null));
};

export const renameTaskWithAssociatedEvent = (task, taskParams) => {
  let event = task.eventId && store().getState().calendar.eventsObj[task.eventId];
  if (event) {
    renameEventWithAssociatedTask({ ...event, title: taskParams.title });
  } else {
    renameTask(task, taskParams);
  }
};

export const renameTask = (task, taskParams) => {
  let taskData = Object.assign({}, task, taskParams);
  ajax_put('/api/task', {
    task: taskData,
  })
    .then(() => {
      Notification.show('Task renamed.', 3000);
    })
    .catch((err) => {
      logError(err, { hint: 'Rename task' });
      Analytics.event({ category: 'Task', action: 'Failed to Edit Task', label: err.toString() });
      Notification.showError('Failed to rename the task.');
    });
};

export const assignTask = (taskId, assigneeEmail) => {
  return ajax_put('/api/task', {
    task: {
      id: taskId,
      assignedTo: assigneeEmail,
    },
  })
    .then(() => {
      Notification.show('Task assignee changed.', 3000);
    })
    .catch((err) => {
      logError(err, { hint: 'Assign task' });
      Analytics.event({ category: 'Task', action: 'Failed to Assign Task', label: err.toString() });
      Notification.showError('Failed to assign the task.');
    });
};

export const moveTaskToList = (task, listId) => {
  const fromList = store().getState().tasks.listsObj[task.listId];
  const toList = store().getState().tasks.listsObj[listId];

  const fromListExtension = getExtensionsRegistry()[fromList.origin];

  if (fromList.origin !== toList.origin) {
    alert('Sorry - moving tasks between different integrations is not yet supported');
    Analytics.event({
      category: 'Task',
      action: 'Attempt to Move Task from ' + fromList.origin + ' to ' + toList.origin,
    });
    return;
  } else if (fromListExtension?.capabilities?.move_task === false) {
    alert('Sorry - this integration does not yet support moving tasks between lists');
    Analytics.event({
      category: 'Task',
      action: 'Attempt to Move Task from ' + fromList.origin + ' - not supported by integration',
    });
    return;
  }

  ajax_post('/api/task/move_to_list', {
    taskId: task.id,
    fromListId: fromList.id,
    toListId: listId,
  })
    .then(() => {
      Notification.show('Task moved to another List.', 3000);
      Analytics.event({ category: 'Task', action: 'Move Task to List' });
    })
    .catch((err) => {
      logError(err, ['Move task', { task, listId }]);
      Analytics.event({ category: 'Task', action: 'Failed to Move Task to another list', label: err.toString() });
      // this.realUpdateTasks()
      Notification.showError('Failed to move Task to another List. Please try again.');
    });
};

export const completeTask = (task) => {
  if (task.nextInstEventId) {
    return eventCompleteById(task.nextInstEventId, task.id, true).catch((err) => {
      if (err.message === 'Event not found') {
        showModalErrorPerformingTaskEventOperation({
          operation: 'completion',
          task,
        });
      }
      // logError(err, ['Complete task', { task }]);
      // Analytics.event({ category: 'Task', action: 'Failed to Complete Task', label: err.toString() });
      // Notification.showError('Failed to complete Task. Error reported to support.');
    });
  }

  Analytics.event({
    category: 'Task',
    action: 'Completed Task',
  });
  store().dispatch(actions.completeTask(task.id, task.eventId, task.todoist?.due));
};

export const uncompleteTask = (task) => {
  Analytics.event({
    category: 'Task',
    action: 'Uncompleted Task',
  });
  store().dispatch(actions.uncompleteTask(task.id, task.eventId, (task as any).due));
};

export const removeTaskById = (taskId) => removeTask({ id: taskId });

export const removeTask = (task) => {
  Analytics.event({
    category: 'Task',
    action: 'Deleted Task',
  });

  return ajax_delete('/api/task/' + encodeURIComponent(task.id))
    .then(() => {
      Notification.show('Task removed.', 3000);
    })
    .catch((err) => {
      logError(err, ['Remove task', { task }]);
      // this.tasksObj[task.id] = task;
      Analytics.event({ category: 'Task', action: 'Failed to Remove Task', label: err.toString() });
      // this.realUpdateTasks()
      Notification.showError('Failed to remove Task. Please try again.');
    });
};

export const reorderTasks = (tasksList) => {
  console.log('TODO reorder tasks in backend', tasksList);

  let data = tasksList.map((task) => ({ id: task.id, item_order: task.item_order, indent: task.indent }));
  ajax_put('/api/task/reorder', data)
    .then(() => {
      console.log('Reorder successful');
      Analytics.event({ category: 'Task', action: 'Reordered Tasks' });
    })
    .catch((err) => {
      logError(err, { hint: 'Reorder tasks' });
      Analytics.event({ category: 'Task', action: 'Failed to Reorder Tasks', label: err.toString() });
    });
};

export const dragTask = (inProgress = true) => {
  if (inProgress && window.navigator && window.navigator.vibrate) {
    window.navigator.vibrate(50);
  }
  console.log('set dragInProgress:', inProgress);
  // this.setState({dragInProgress: inProgress})
  store().dispatch(actions.changeActiveView(1));
};

const _setTaskDueDate = async (taskId: string, dueDate: string | null) => {
  await fbOps.setTaskDueDate(taskId, dueDate);
  store().dispatch(actions.setTaskDueDate(taskId, dueDate));
};

export const setTaskDueDate = async (taskId: string, dueDate: string | null) => {
  Analytics.event({ category: 'Task', action: dueDate ? 'Set Due Date' : 'Clear Due Date' });
  await _setTaskDueDate(taskId, dueDate);
  if (!dueDate) {
    return _setTaskRecurrence(taskId, null);
  }
};

const _setTaskRecurrence = async (taskId: string, recurrence: string | null) => {
  await fbOps.setTaskRecurrence(taskId, recurrence);
  store().dispatch(actions.setTaskRecurrence(taskId, recurrence));
};

export const setTaskRecurrence = async (taskId: string, recurrence: string | null, dueDate: string | null) => {
  if (!dueDate) {
    await _setTaskDueDate(taskId, moment().format('YYYY-MM-DD'));
  }
  Analytics.event({ category: 'Task', action: recurrence ? 'Set Recurrence' : 'Clear Recurrence' });
  return _setTaskRecurrence(taskId, recurrence);
};

export const setTaskDuration = async (task: Task, duration: number) => {
  Analytics.event({ category: 'Task', action: 'Set Duration' });
  const todoistAccessToken = store().getState().account.user?.accounts?.todoist?.accessToken;
  await fbOps.setTaskDuration(task.id, duration);

  if (!!task.todoist && !!todoistAccessToken) {
    return setDuration(todoistAccessToken, task.todoist.id, duration).catch((err) => {
      console.error('Error setting task duration in Todoist', err);
    });
  }

  await scheduleTrainRequest().catch(() => {});
};

export const toggleSchedulingQueue = (task: Task) => {
  const val = !task.queue;
  Analytics.event({
    category: 'Task',
    action: val ? 'Add to Scheduling Queue' : 'Remove from Scheduling Queue',
  }); // Key Metric
  fbOps.setTaskScheduleQueue(task.id, val);
};

export const toggleListShedulingQueue = (list: List) => {
  const val = !list.queue;
  Analytics.event({
    category: 'List',
    action: val ? 'Add to Scheduling Queue' : 'Remove from Scheduling Queue',
  }); // Key Metric
  fbOps.setListScheduleQueue(list.id, val);
};
