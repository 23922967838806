import { eventChannel } from 'redux-saga';
import { fork, put, select, take, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { Notification } from '../components';
import {
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILED,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,
  RENAME_EVENT_FAILED,
  ADD_EVENT_WITH_TASK_SUCCESS,
  ADD_EVENT_WITH_TASK_FAILED,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILED,
  COMPLETE_TASK_SUCCESS,
  COMPLETE_TASK_FAILED,
  UNCOMPLETE_TASK_SUCCESS,
  UNCOMPLETE_TASK_FAILED,
  selectList,
  ADD_LIST_SUCCESS,
  ADD_LIST_FAILED,
  TURN_EVENT_INTO_TASK_SUCCESS,
  TURN_EVENT_INTO_TASK_FAILED,
} from '../actions';
import * as taskOps from '../operations/task';

const show = Notification.show;
const showError = Notification.showError;

const hour = (time, is24h = false) => {
  return moment(time).format(is24h ? 'kk:mm' : 'LT');
};
const date = (dd) => {
  const daysFromNow = moment(dd).startOf('day').diff(moment().startOf('day'), 'days');
  return moment(dd).format(daysFromNow >= 0 && daysFromNow < 7 ? 'dddd' : 'Do of MMM');
};

function* actionsPoster(channel) {
  while (true) {
    let action = yield take(channel);
    yield put(action);
  }
}

export default function* notificationService() {
  let postAction;
  const postActionChannel = eventChannel((emit) => {
    postAction = (action) => emit(action);
    return () => {};
  });
  yield fork(actionsPoster, postActionChannel);

  yield takeEvery('*', function* logger(action) {
    switch (action.type) {
      case ADD_EVENT_SUCCESS:
      case ADD_EVENT_WITH_TASK_SUCCESS:
      case UPDATE_EVENT_SUCCESS: {
        const { title, beginDate, allDay, hintOperation } = action;
        const re = action.type === UPDATE_EVENT_SUCCESS ? 're' : '';
        const _atHour = allDay ? '' : ` at ${hour(beginDate)}`;
        let text = `'${title.substring(0, 30)}' ${re}scheduled for ${date(beginDate)}${_atHour}.`;
        if (hintOperation === 'reminder') {
          text = 'Event reminders updated';
        }
        return show(
          text,
          5000,
          'GO TO',
          () => window.calendar.gotoDate(moment(beginDate).toISOString()) // TODO
        );
      }
      case ADD_EVENT_FAILED:
      case ADD_EVENT_WITH_TASK_FAILED:
      case UPDATE_EVENT_FAILED: {
        const { hintOperation } = action;
        const re = action.type === UPDATE_EVENT_FAILED ? 're' : '';
        let text = `Failed to ${re}schedule Task.`;
        if (hintOperation === 'reminder') {
          text = 'Event reminders update failed';
        } else if (
          action.type === UPDATE_EVENT_FAILED &&
          action.err &&
          action.err !== 'Bad Request' &&
          action.err !== 'Internal Server Error'
        ) {
          text = action.err;
        }
        return showError(text, 'RETRY', () => postAction(action.originalRequest));
      }
      case RENAME_EVENT_FAILED:
        return showError('Renaming event failed.', 'RETRY', () => postAction(action.originalRequest));
      case ADD_TASK_SUCCESS: {
        const { title, listId } = action;
        const listsObj = yield select((state) => state.tasks.listsObj);
        const listName = listsObj[listId].name;
        return show(`'${title.substring(0, 40)}' added to #${listName}`, 3000, 'GO TO', () =>
          postAction(selectList(listId))
        );
      }
      case ADD_TASK_FAILED: {
        return showError('Oops, adding new task failed. Please try again.');
      }
      case TURN_EVENT_INTO_TASK_SUCCESS: {
        const {
          event: { title },
          listId,
        } = action;
        const listsObj = yield select((state) => state.tasks.listsObj);
        const listName = listsObj[listId].name;
        return show(`'${title.substring(0, 40)}' added to #${listName}`, 3000, 'GO TO', () =>
          postAction(selectList(listId))
        );
      }
      case TURN_EVENT_INTO_TASK_FAILED: {
        return showError('Oops, turing into task failed. Please try again.');
      }

      case COMPLETE_TASK_SUCCESS:
        return show(`Aww yeah! Task completed.`, 3000, 'UNDO', () => {
          taskOps.uncompleteTask(action);
        });
      case UNCOMPLETE_TASK_SUCCESS:
        return show(`Task uncompleted.`, 3000);
      case COMPLETE_TASK_FAILED:
      case UNCOMPLETE_TASK_FAILED: {
        const un = action.type === UNCOMPLETE_TASK_FAILED ? 'un' : '';
        return showError(`Failed to ${un}complete Task`);
      }
      case ADD_LIST_SUCCESS:
        return show('List created successfully.', 3000);
      case ADD_LIST_FAILED:
        return showError('Failed to create List. Please try again.');
      case 'REFRESH_EVENTS_ERRORS':
        return showError('Sync trouble: ' + (action.message || 'Try again in a few moments.'), 'Troubleshoot', () => {
          Notification.showModal(
            <div>
              <h3>Failed to sync calendar events. Please try again in a minute.</h3>
              <p>If you keep getting these errors with Google Calendar:</p>
              <ol>
                <li>
                  Revoke permissions for TrevorAI on&nbsp;
                  <a href="https://myaccount.google.com/permissions" target="_blank" rel="noopener noreferrer">
                    https://myaccount.google.com/permissions
                  </a>
                </li>
                <li>
                  Log out of Trevor AI and then <strong>Sign-in with Google</strong>.
                </li>
                <li>Grant Trevor AI permission to access your Google Calendar again.</li>
              </ol>
              <p>
                Didn't work? Contact us via the message bubble or at{' '}
                <a href="mailto:support@trevorlabs.com">support@trevorlabs.com</a>.
              </p>
            </div>
          );
        });
      case 'REFRESH_TASKS_ERRORS':
        return showError(
          'Tasks Sync trouble: ' + (action.message || 'Try again in a few moments.'),
          'Troubleshoot',
          () => {
            Notification.showModal(
              <div>
                <h3>Failed to sync tasks. Please try again in a minute.</h3>
                <p>If you keep getting these errors with Todoist:</p>
                <ol>
                  <li>
                    Open{' '}
                    <a href="/app/settings" target="_blank" rel="noopener noreferrer">
                      Account Settings
                    </a>{' '}
                    and find the <strong>Todoist</strong> section.
                  </li>
                  <li>
                    Click <strong>Reconnect Todoist</strong>
                  </li>
                </ol>
                <p>
                  Didn't work? Contact us via the message bubble or at{' '}
                  <a href="mailto:support@trevorlabs.com">support@trevorlabs.com</a>.
                </p>
              </div>
            );
          }
        );

      default:
    }
  });
}
