import React, { useState } from 'react';
import { Button, TextField, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Check, Close } from '@mui/icons-material';

const StyledButton = styled(Button)({
  maxWidth: '63px',
});

export const TextFieldForm = ({ initialValue, multiline = false, onSave, onCancel, leftMargin = 0 }) => {
  const [value, setValue] = useState(initialValue);

  const handleBlur = () => {
    setTimeout(onCancel, 500);
  };

  const handleEditTask = (e) => {
    e.preventDefault();
    onSave(value);
  };

  const input = (
    <TextField
      name="text-field"
      variant="standard"
      // multiline={multiline}
      sx={{
        flexGrow: 1,
        marginLeft: 0,
        fontSize: '1.3em',
        fontWeight: '300',
        '& .MuiInputBase-input': { padding: '7px 10px 7px 2px' },
        '& .MuiInputBase-multiline': { padding: '3px' },
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required
      autoFocus
    />
  );

  const buttons = (
    <>
      <IconButton type="submit" color="primary">
        <Check />
      </IconButton>
      <IconButton onClick={onCancel}>
        <Close />
      </IconButton>
    </>
  );
  return (
    <form onSubmit={handleEditTask} onBlur={handleBlur} style={{ flexGrow: 1, marginLeft: '0' }}>
      {multiline ? (
        <>
          <Stack spacing={0.5}>
            <Stack spacing={0.5}>{input}</Stack>
            <Stack spacing={0.5} direction="row">
              {buttons}
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack spacing={0.5} direction="row">
          {input}
          {buttons}
        </Stack>
      )}
    </form>
  );
};
