import React, { useState, useEffect, useRef } from 'react';
import { Note } from '../../components';
import * as eventOps from '../../operations/event';
import * as taskOps from '../../operations/task';
import * as sharingOps from '../../operations/sharing';
import { Box, Button, Paper, Collapse, Grid, Tooltip } from '@mui/material';
import { ArrowForward, ReadMore, EventBusy, Repeat, PlaylistAddCheck } from '@mui/icons-material';
import { SmartSuggestions } from './SmartSuggestions';
import { TaskTitleWithControls } from './TaskTitleWithControls';
import { ListNameWithIcon } from 'components';
import { SelectDueDateWidget } from './SelectDueDateWidget';
import Analytics from '../../analytics.jsx';
import { QuickSuggestionsDropdown } from './QuickSuggestions';
// TODO remove
import { SelectRecurrenceWidget } from './SelectRecurrenceWidgetProps';
import { CombinedRecurrenceWidget } from './CombinedRecurrenceWidget';
import { detectOrigin } from 'common/utils';
import { SmartSchedulingIcon } from '../../components/CustomIcons';
import { useMutableCallback } from 'common/useMutableCallback';
import { useSelector } from 'react-redux';
import { CenteredSpinner } from 'components/CenteredSpinner';

const TaskListSubscript = ({ list, onClick }) => {
  return (
    <div className="task-list">
      <a
        href=""
        style={{ color: list.color }}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {/* {'#' + list.name} */}
        <ListNameWithIcon list={list} />
      </a>
    </div>
  );
};

const PostponeButton = (props) => (
  <Button sx={{ height: '34px' }} variant="outlined" fullWidth startIcon={<EventBusy />} {...props}>
    Unschedule
  </Button>
);

const defaultRenderPreview = ({ task, onToggleExpand, listColor }) => {
  return <TaskTitleWithControls task={task} expandToggle={onToggleExpand} listColor={listColor} />;
};

export const TaskCard = React.memo((props) => {
  const {
    task,
    list,
    showList,
    onListClick,
    renderPreview = defaultRenderPreview,
    hintExpand = false,
    onToggleExpand,
    showNoteInPreview = false,
    expandNote,
    collapseNote,
    defaultCollapsed,
  } = props;
  const mutableOnToggleExpand = useMutableCallback(onToggleExpand);
  const [prefs, inProgressObj] = useSelector((state) => [state.account.user.prefs, state.tasks.inProgressObj]);

  const isInProgress = inProgressObj[task.id];

  // const [expanded = hintExpand, setExpanded] = useState();
  const expanded = hintExpand;
  const [isTaskOptions, setIsTaskOptions] = useState(false);
  const showTaskOptions = expanded && isTaskOptions;
  const showSchedulingOptions = expanded && !isTaskOptions;

  const autoAddedToQueue = prefs.due_date_in_scheduling_queue && !!task.dueDate;
  const inQueue = task.queue || autoAddedToQueue;

  const taskCardRef = useRef();
  // const [expanded, setExpanded] = useState(initialExpand);
  if (expanded) {
    console.log('expanded task:', task);
  }

  useEffect(() => {
    // if (taskOptions || expanded) {
    if (expanded) {
      if (showTaskOptions) {
        // Auto-focus the Note field in the referenced task with task options open
        setTimeout(() => {
          if (taskCardRef.current) {
            const noteField = taskCardRef.current.querySelector('.ql-editor');
            if (noteField) {
              noteField.focus();
              // workaround to focus the END of the <p> string in Quill
              const range = document.createRange();
              range.selectNodeContents(noteField);
              range.collapse(false);
              const selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        }, 0);
      }
      // Close task options and/or schedule options on click outside of the referenced task card
      const handleClickOutside = (event) => {
        if (taskCardRef.current && !taskCardRef.current.contains(event.target)) {
          const appRoot = document.getElementById('app_root'); // Prevent closing when clicking popovers and dialogs
          const calendarClasses = [
            'previous-day-button',
            'next-day-button',
            'fc-view-harness',
            'fc-header-toolbar',
            'calendar-mode-button',
          ]; // Prevent closing when managing calendar
          const calendarElements = calendarClasses.map((className) => document.getElementsByClassName(className));
          if (
            appRoot &&
            appRoot.contains(event.target) &&
            !calendarElements.some((elements) => Array.from(elements).some((element) => element.contains(event.target)))
          ) {
            setTimeout(() => {
              // setTaskOptions(false);
              // setExpanded(false);
              mutableOnToggleExpand(false);
            }, 80); // allows for consecuitve opening of task scheduling options on different task cards. Do not set to 0.
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [showTaskOptions, expanded, mutableOnToggleExpand]);

  const handleToggleExpand = () => {
    if (expanded && !isTaskOptions) {
      return onToggleExpand?.(false);
    }
    setIsTaskOptions(false);
    if (!expanded) {
      onToggleExpand?.(true);
    }
  };

  const handleShowTaskOptions = () => {
    if (expanded && isTaskOptions) {
      return onToggleExpand?.(false);
    }
    setIsTaskOptions(true);
    if (!expanded) {
      onToggleExpand?.(true);
    }
  };

  // TODO: Focus note on taskOptions = true
  const noteWidget = (
    <Note
      className="task-note"
      key={'note-' + task.id}
      uniqueKey={'note-' + task.id}
      content={task.note}
      onSave={(content) => sharingOps.saveTaskNote(task, content)}
      expand={expandNote}
      collapse={collapseNote}
      defaultCollapsed={defaultCollapsed}
    />
  );

  const toggleSchedulingQueue = (task) => {
    taskOps.toggleSchedulingQueue(task);
    handleToggleExpand();
  };

  let schedulingSuggestionButtons = (
    <>
      <SmartSuggestions task={task} limit={2} />
      <Box sx={{ display: 'inline-flex', paddingLeft: '4px', paddingTop: '4px', width: '100%' }}>
        <QuickSuggestionsDropdown
          task={task}
          styles={{
            height: '34px',
          }}
        />
      </Box>
    </>
  );
  if (task.recurringEventIds) {
    // hide if recurring scheduled
    schedulingSuggestionButtons = '';
  }

  let schedulingOps;
  if (task.eventId || task.recurringEventIds) {
    schedulingOps = (
      <>
        <Grid item xs={12} sm={!task.completed ? 12 : 6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: '34px' }}
            onClick={() => {
              window.calendar.gotoDate(task.eventBeginDate);
              Analytics.event({ category: 'Schedule', action: 'Show in Schedule' });
            }}
            startIcon={<ArrowForward />}
          >
            Show in Schedule
          </Button>
        </Grid>
        {!task.completed && (
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="Unfinished - Saves the Calendar Event and returns the Task to the Task Hub for rescheduling."
              placement="top"
              disableInteractive
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => eventOps.eventPartialComplete(task.eventId)}
                startIcon={<PlaylistAddCheck />}
              >
                Unfinished
              </Button>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <PostponeButton
            onClick={() => eventOps.eventPostponeById(task.eventId, task.id)}
            disabled={task.recurringEventIds ? true : false}
          />
        </Grid>
        {schedulingSuggestionButtons}
      </>
    );
  } else {
    schedulingOps = (
      <>
        {!list.queue && (
          <Tooltip
            title="Adds the task to Trevor's AI scheduling queue. The tasks are then shown as scheduling suggestions in the timeline at their predicted time slots and can easily be scheduled individually or in bulk. Tasks with a due date are added automatically."
            disableInteractive
          >
            <Grid item xs={12} sm={12}>
              <Button
                className="smart-scheduling-button"
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<SmartSchedulingIcon color="inherit" size="18px" />} // TODO: remove (soon) and replace icon with Trevor Icon
                onClick={() => toggleSchedulingQueue(task)}
                disabled={autoAddedToQueue}
              >
                {inQueue ? 'Remove from' : 'Add to'} Scheduling Queue
              </Button>
            </Grid>
          </Tooltip>
        )}
        {detectOrigin(task.id) === 'trevor' && !task.recurringEventIds && (
          <>
            <Grid item xs={12} sm={6}>
              <SelectDueDateWidget
                dueDate={task.dueDate}
                hideSetButton={!!task.recurrence}
                onChange={(date) => taskOps.setTaskDueDate(task.id, date)}
                buttonProps={{ height: '34px' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CombinedRecurrenceWidget task={task} />
            </Grid>
          </>
        )}
        {schedulingSuggestionButtons}
      </>
    );
  }

  return (
    <Paper
      elevation={2}
      className="task-card"
      ref={taskCardRef}
      sx={{
        // the class here is important - it's used in combination with .ui-sortable-helper during dragging
        borderRadius: 2,
        margin: '3px 0px',
        padding: '1px',
        boxShadow: (theme) => theme.shadow.task,
        [`&:hover`]: {
          boxShadow: (theme) => theme.shadow.taskHover,
          transition: 'box-shadow 0.1s linear',
        },
      }}
    >
      {isInProgress ? (
        <CenteredSpinner />
      ) : (
        renderPreview({
          task,
          onToggleExpand: handleToggleExpand,
          onShowTaskOptions: handleShowTaskOptions,
          taskOptions: showTaskOptions,
        })
      )}
      {/* Shows List in certain filters like "Next 7 days" */}
      {showList && (
        <Box
          sx={{
            padding: '0 20px 10px 0',
            textAlign: 'right',
          }}
        >
          <TaskListSubscript list={list} onClick={() => onListClick?.(list)} />
        </Box>
      )}
      {showNoteInPreview && <Box className="task-note-preview">{noteWidget}</Box>}
      <Collapse in={showTaskOptions}>{isTaskOptions && !showNoteInPreview && noteWidget}</Collapse>
      <Collapse in={showSchedulingOptions}>
        {showSchedulingOptions && (
          <>
            <Grid
              container
              spacing={0.5}
              justifyContent="flex-end"
              sx={{
                padding: '6px',
                // gap: '4px 0',
                ['.MuiButton-root']: {
                  height: '34px',
                  fontSize: '13px',
                  lineHeight: '14px',
                  textOverflow: 'ellipsis',
                },
              }}
            >
              {schedulingOps}
            </Grid>
          </>
        )}
      </Collapse>
    </Paper>
  );
});
