import { FC, useEffect, useState } from 'react';
import { resolveRecurringEvent } from '../api/calendar';
import { CalendarEvent } from 'shared';

export const RecurringEventDetails: FC<{ event: CalendarEvent }> = ({ event }) => {
  const { calendarId, recurringEventId } = event;
  const [text, setText] = useState('');
  useEffect(() => {
    resolveRecurringEvent(calendarId, recurringEventId)
      .then((data) => {
        if (data?.recurrenceResolved?.[0]) {
          setText(data.recurrenceResolved[0]);
        }
      })
      .catch(console.error);
  }, [calendarId, recurringEventId]);

  return text ? <span>Repeats {text}</span> : <span>Repeats...</span>;
};
