import React from 'react';

const scrollIntoViewAndHighlight = (element) => {
  let wrap = window.$(element);
  let raw = wrap.get(0);
  if (raw.scrollIntoViewIfNeeded) {
    raw.scrollIntoViewIfNeeded();
  } else if (raw.scrollIntoView) {
    raw.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
  wrap.effect('highlight', { color: 'rgba(80,150,190,0.1)' }); //, 3000)
};

export default class ScrollIntoViewOnMount extends React.Component {
  componentDidMount() {
    const { activate } = this.props;
    if (activate) {
      scrollIntoViewAndHighlight(this.refs.element);
    }
  }
  render() {
    return <div ref="element">{this.props.children}</div>;
  }
}
