import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { IconButton, Tooltip, Box, Badge } from '@mui/material';
import AccountBox from '@mui/icons-material/AccountBox';
import ActionEvent from '@mui/icons-material/Event';
import ToggleCheckBox from '@mui/icons-material/CheckBox';
import { History } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const TransparentBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgba(239, 52, 50, 0.15)',
    backdropFilter: 'blur(5px)',
    color: 'inherit',
    top: '-3px !important',
    right: '-3px !important',
  },
}));

class __InboxFilters extends React.Component {
  render() {
    const {
      disabled,
      showUser = false,
      changeCompleted,
      changeScheduled,
      toggleFilterUserTasks,
      changeOverdue,
      overdueTasksCount,
    } = this.props;

    const { showCompleted, showScheduled, showOnlyUserTasks, showOverdueOnly } = this.props.filter;

    return (
      <Box
        className="task-panel-tabs-scheduled-completed-filter" // hightlight()
      >
        {showUser && (
          <Tooltip title="Show assigned to me/all tasks">
            <IconButton
              onClick={() => {
                toggleFilterUserTasks();
              }}
              // style={{ display: 'inline-block', width: '48px', padding: '12px' }}
              disabled={disabled}
            >
              <AccountBox color={showOnlyUserTasks ? 'primary' : undefined} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Show/hide scheduled tasks">
          <IconButton
            onClick={() => {
              changeScheduled(!showScheduled);
            }}
            // style={{ display: 'inline-block', width: '48px', padding: '12px' }}
            disabled={disabled}
          >
            <ActionEvent color={showScheduled ? 'primary' : undefined} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Show/hide completed tasks">
          <IconButton
            onClick={() => changeCompleted(!showCompleted)}
            // style={{ display: 'inline-block', width: '48px', padding: '12px' }}
            disabled={disabled}
          >
            <ToggleCheckBox color={showCompleted ? 'primary' : undefined} />
          </IconButton>
        </Tooltip>
        {(overdueTasksCount > 0 || showOverdueOnly) && (
          <Tooltip title="Show only overdue tasks" disableInteractive>
            <IconButton
              onClick={() => changeOverdue(!showOverdueOnly)}
              sx={{ margin: '0 4px 0 0' }}
              disabled={disabled}
            >
              <TransparentBadge badgeContent={overdueTasksCount} sx={{ color: (theme) => theme.palette.danger.main }}>
                <History
                  sx={{ color: showOverdueOnly ? (theme) => theme.palette.danger.main : 'rgba(0, 0, 0, 0.54)' }}
                />
              </TransparentBadge>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }
}
export const InboxFilters = connect(
  (state) => ({
    filter: state.ui.filter,
    disabled: state.ui.tab === 'project',
    overdueTasksCount: state.ui.currentViewOverdueTasksCount,
  }),
  (dispatch) => ({
    changeCompleted: (val) => dispatch(val ? actions.filterCompletedOn() : actions.filterCompletedOff()),
    changeScheduled: (val) => dispatch(val ? actions.filterScheduledOn() : actions.filterScheduledOff()),
    toggleFilterUserTasks: (val) => dispatch(actions.toggleFilterUserTasks()),
    changeOverdue: (val) => dispatch(val ? actions.filterOverdueOn() : actions.filterOverdueOff()),
  })
)(__InboxFilters);
