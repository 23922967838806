import { CalendarOptions, EventApi } from '@fullcalendar/core';
import { detectAllDayEvent } from 'common/utils';
import moment from 'moment';
import { CalendarEvent, Task } from 'shared';
import { CalendarEventInput } from 'types';

export const eventToCalendarEvent = (event, editable = true): EventApi => {
  let ev: any = {
    id: event.id || event.suggestId || 'n/a', //or just event.id?
    origEvent: { ...event },
    title: event.title,
    start: event.beginDate,
    allDay: event.allDay,
    color: event.color,
    editable,
  };
  if (event.endDate) {
    ev.end = event.endDate;
    if (detectAllDayEvent(event)) {
      ev.allDay = true;
    }
  }

  // console.log('Created calendar event:', event.title, event.beginDate, event.endDate)
  return ev;
};

export const eventFromCalendarEvent = (
  calEvent: EventApi,
  // defaultCalendarId: string,
  default_task_duration: number,
  listId: string | null = null
) => {
  // if (!defaultCalendarId) {
  //   alert('Cannot find default calendar');
  //   throw new Error('Cannot find default calendar');
  // }

  const { suggestId } = calEvent.extendedProps;
  const task: Task | undefined = calEvent.extendedProps.task;

  const { start, end, id, allDay, title } = calEvent;

  const event: CalendarEventInput = {
    id,
    beginDate: start.toISOString(),
    endDate: end?.toISOString(),
    allDay,
    title,
    // calendarId: defaultCalendarId,
    suggestId,
  };
  if (task) {
    event.taskId = task.id;
    event.taskCompleted = task.completed;
  } else {
    event.taskListId = listId;
  }

  if (!end) {
    // is it possible??
    const endDate = moment(start).local();
    if (allDay) {
      endDate.add(1, 'day');
    } else if (
      task &&
      task.eventEndDate &&
      typeof task.eventBeginDate === 'string' &&
      task.eventBeginDate.length > 10 // not just date like 2018-01-01
    ) {
      // if it's been scheduled before, use the same duration
      const diffMin = moment(task.eventEndDate).diff(task.eventBeginDate, 'minutes');
      endDate.add(diffMin, 'minutes');
    } else {
      endDate.add(default_task_duration, 'minutes');
    }
    event.endDate = endDate.toISOString();
  }

  if (event.allDay) {
    // it's to guarantee that the server will receive correct date
    // otherwise it's just 00:00 and 24:00 in local tz and when it reaches the server
    // it sends the info too google and they set the wrong date
    event.beginDate = moment(event.beginDate).format('YYYY-MM-DD');
    event.endDate = moment(event.endDate).format('YYYY-MM-DD');

    if (event.beginDate === event.endDate) {
      event.endDate = moment(event.endDate).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return event;
};

export const partialEventFromCalendarEvent = (calEvent: EventApi, suggestedDuration = 60): CalendarEvent => {
  const allDay = calEvent.allDay;
  const beginDate = moment(calEvent.start).clone().local();
  const endDate = calEvent.end
    ? moment(calEvent.end).clone().local()
    : moment(beginDate).add(suggestedDuration, 'minutes');
  const newOrigEvent = {
    ...calEvent.extendedProps.origEvent,
    beginDate: allDay ? beginDate.format('YYYY-MM-DD') : beginDate.toISOString(),
    allDay: allDay,
    endDate: allDay ? endDate.format('YYYY-MM-DD') : endDate.toISOString(),
  };
  return newOrigEvent;
};

export const calendarViews: CalendarOptions['views'] = {
  // export const calendarViews = {
  daily: {
    type: 'timeGrid',
    titleFormat: { year: 'numeric', month: 'long' },
    dayHeaderFormat: { weekday: 'long', day: 'numeric' },
    slotDuration: '00:15:00',
  },
  threeDay: {
    type: 'timeGrid',
    duration: { days: 3 },
    buttonText: '3 days',
    titleFormat: { year: 'numeric', month: 'long' },
    dayHeaderFormat: { weekday: 'long', day: 'numeric' },
    slotDuration: '00:15:00',
  },
  weekly: {
    type: 'timeGridWeek',
    titleFormat: { year: 'numeric', month: 'long' },
    dayHeaderFormat: { weekday: 'short', day: 'numeric' },
    slotDuration: '00:15:00',
  },
  month: {
    type: 'dayGridMonth',
    dayHeaderFormat: { weekday: 'short', day: 'numeric' },
  },
  selectDate: {
    // type: 'dayGridMonth',
    type: 'multiMonthYear',
    multiMonthMaxColumns: 1,
    // duration: { months: 1 },
    buttonText: 'Date Picker',
  },
};
