import { Notification } from '../components';
import { fbOps } from '../common/firebase';
import Analytics from '../analytics.jsx';
import { ajax_post, ajax_put, ajax_delete } from '../api/util';
import * as actions from '../actions';
import { logError } from 'common/utils';
import { getUid, store } from '.';

export const changeListEnabled = async (list, enabledFlag) => {
  if (!enabledFlag) {
    Analytics.event({ category: 'List', action: 'Disabled List' });
  } else {
    Analytics.event({ category: 'List', action: 'Enabled List' });
  }
  store().dispatch(actions.changeListEnabled(list.id, enabledFlag, list.legacy_id));

  try {
    await ajax_post('/api/list/bulk_set_visibility', {
      listsIds: [list.id],
      enabled: enabledFlag,
    });
  } catch (err) {
    logError(err, { hint: 'Change list enabled' });
    Notification.showError('Failed to change List visibility. Please try again.');
    store().dispatch(actions.changeListEnabled(list.id, !enabledFlag, list.legacy_id));
  }
};

export const addNewList = (list, cb) => {
  let { name, colorId, origin } = list;
  store().dispatch(actions.addListRequest(Date.now(), name, colorId, origin));
};

export const editList = (list) => {
  ajax_put('/api/list', { list: list })
    .then(() => Notification.show('List updated successfully', 3000))
    .catch((err) => {
      logError(err, { hint: 'Edit list' });
      Analytics.event({ category: 'List', action: 'Failed to Edit List', label: err.toString() });
      Notification.showError('Failed to update List. Please try again.');
    });
};

export const removeList = (list) => {
  ajax_delete('/api/list/' + encodeURIComponent(list.id))
    .then(() => Notification.show('List removed.', 3000))
    .catch((err) => {
      logError(err, { hint: 'Remove list' });
      Analytics.event({ category: 'List', action: 'Failed to Remove List', label: err.toString() });
      Notification.showError('Failed to remove List. Please try again.');
    });
};

export const setDefaultList = (list) => {
  if (!list.enabled) {
    changeListEnabled(list, true);
  }
  ajax_post('/api/list/setdefault', {
    list: {
      id: list.id,
    },
  })
    .then(() => Notification.show('List set as default.', 3000))
    .catch((err) => {
      logError(err, { hint: 'Set default list' });
      Analytics.event({ category: 'List', action: 'Failed to Set Default List', label: err.toString() });
      Notification.showError('Failed to set List as default. Please try again.');
    });
};

export const reorderLists = (lists, newIdxList) => {
  fbOps
    .reorderLists(getUid(), lists, newIdxList)
    .then(() => Notification.show('Lists successfully reordered', 3000))
    .catch((err) => {
      logError(err, { hint: 'Reorder lists' });
      Analytics.event({ category: 'List', action: 'Failed to Reorder Lists', label: err.toString() });
      Notification.showError('Failed to reorder Lists. Please try again.');
    });
};
