import { ChatMessage, ChatMessageData } from 'shared';
import { ajax_post } from './util';

export const shareList = async (listId, listName, listColor, email, recaptchaToken) => {
  await ajax_post('/api/list/share', { listId, listName, listColor, email, recaptchaToken });
};
export const removeShareList = async (listId, email) => {
  await ajax_post('/api/list/share/remove', { listId, email });
};
export const acceptInvitation = async (invitationId) => {
  await ajax_post(`/api/list/invitation/accept/${encodeURIComponent(invitationId)}`);
};
export const declineInvitation = async (invitationId) => {
  await ajax_post(`/api/list/invitation/decline/${encodeURIComponent(invitationId)}`);
};

export const monitorTaskNote = async (taskId) => {
  await ajax_post('/api/task/monitor/' + encodeURIComponent(taskId));
};
export const monitorListNote = async (listId) => {
  await ajax_post('/api/list/monitor/' + encodeURIComponent(listId));
};

export const refreshTasksPredictions = async (taskIds) => {
  await ajax_post('/api/task/refresh_predictions', { taskIds });
};

export const predictDuration = async (titles, limit = 5) => {
  const res: any = await ajax_post('/api/predict_duration', { titles, limit });
  return res.predictions;
};

export const scheduleTrainRequest = async () => {
  await ajax_post('/api/schedule_training_request');
};

export const generateAiAssistance = async (taskId, list, note, type, isPremium) => {
  return ajax_post('/api/task/generate_ai_assistance', { taskId, list, note, type, isPremium });
};

export const sendChatMessage = async (content: string, conversationId: string, amendMsgId: string | null) => {
  return ajax_post<string>('/chat', {
    content,
    conversationId,
    amendMsgId,
  });
};

export const sendChatStatusRequest = async (conversationId: string) => {
  return ajax_post<string>('/chat/status', {
    conversationId,
  });
};

export * from './account';
