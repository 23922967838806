export const premiumModules = [
  {
    id: 'accounts',
    title: 'Multiple Calendar Accounts',
    benefit: 'Manage life and work in one place',
    description: `Sync both work and personal life into a unified schedule for better clarity and more accurate scheduling suggestions.`,
    imageSuffix: 'accounts_module.png',
    imagePosition: '50% 55%',
    // imageSize: '80px',
    isPremium: true,
  },
  {
    id: 'ai',
    title: 'Personal AI Model',
    benefit: 'Schedule with AI tailored to you',
    description: `Your Trevor’s AI adapts to you with each planning session, dramatically improving prediction accuracy for all AI scheduling features, while keeping your data completely private.`,
    imageSuffix: 'ai_module.png',
    imagePosition: '50% 55%',
    isPremium: true,
  },
  {
    id: 'scheduling-queue',
    title: 'Smart Scheduling Queue',
    benefit: 'Prioritize important tasks',
    description:
      'Effortlessly add important tasks and lists to the Smart Scheduling Queue, which your assistant will proactively help you schedule, even in bulk.',
    imageSuffix: 'scheduling_queue_module.png',
    imagePosition: '50% 55%',
    isPremium: true,
  },
  {
    id: 'recurring',
    title: 'Recurring Tasks',
    benefit: 'Develop productive habits',
    description:
      'Build consistency and momentum with both flexible and scheduled recurring tasks, while your Trevor assists in scheduling and rescheduling as needed.',
    imageSuffix: 'recurring_module.png',
    imagePosition: '50% 55%',
    isPremium: true,
  },
  {
    id: 'overdue',
    title: 'Rescheduling Suggestions',
    benefit: 'Never forget a scheduled task',
    description: `Trevor will provide rescheduling suggestions for overdue scheduled tasks and tasks with past due dates.`,
    imageSuffix: 'overdue_module.png',
    imagePosition: '50% 55%',
    // imageSize: '70px',
    isPremium: true,
  },
  {
    id: 'progress',
    title: 'Scheduling Insights',
    benefit: 'Improve how you invest your time',
    description: `Visualise your efforts with the “My Progress” dashboard and receive daily scheduling advice via email personalised to you.`,
    imageSuffix: 'progress_module.svg',
    imagePosition: '50% 55%',
    // imageSize: '100px',
    isPremium: true,
  },
  // {
  //   id: 'duration',
  //   title: 'Task Duration Predictions',
  //   description:
  //     'Enables your Trevor to auto-assign a temporary duration for each of your tasks based on your personal AI model.',
  //   imageSuffix: 'duration_module.png',
  //   imagePosition: '50% 55%',
  //   isPremium: true,
  // },
  // {
  //   id: 'actionplans',
  //   title: 'AI Action Plan for Tasks',
  //   description:
  //     'Enables your Trevor to generate an action plan for each of your tasks, powered by the latest GPT models, compared to the most economical ones for free tier users.',
  //   imageSuffix: 'actionplan_module.png',
  //   imagePosition: '50% 55%',
  //   isPremium: true,
  // },
  // {
  //   id: 'support',
  //   title: 'Priority Human Support',
  //   description: `Enables your Trevor to send your support requests to Trevor AI's support team.`,
  //   imageSuffix: 'support_module.png',
  //   imagePosition: '50% 55%',
  //   isPremium: true,
  // },
];
