import { FC, useState } from 'react';
import { BorderlessSelect } from 'components/BorderlessSelect';
import { Divider, MenuItem, ListItemIcon, ListItemText, Tooltip, IconButton, Menu } from '@mui/material';
import { Clear, ViewDay } from '@mui/icons-material';
import { useUserPrefs } from 'common/useUserPrefs';
import { UserScheduleConfigItem } from 'shared';

const defaultColor = 'gray';

interface UserScheduleZonesDropdownProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  rightAction?: JSX.Element;
  tooltip?: string;
}

interface UserScheduleZonesIconProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  rightAction?: JSX.Element;
  tooltip?: string;
  listColor?: string;
}

export const UserScheduleZonesDropdown: FC<UserScheduleZonesDropdownProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  rightAction,
  tooltip,
}) => {
  const userSchedule = useUserPrefs().user_schedule;

  const handleSelect = (e) => {
    onSelectItem(e.target.value || '');
  };

  const selectedItemIdx = userSchedule.moreItems?.findIndex((l) => l?.id === selectedItemId);
  const selectedItem = userSchedule.moreItems?.[selectedItemIdx];

  if (!userSchedule.moreItems) return null;

  return (
    <BorderlessSelect
      variant="outlined"
      displayEmpty
      sx={{
        height: '48px',
        width: '100%',
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': { textDecoration: 'underline' },
      }}
      value={selectedItemId || ''}
      renderValue={(val) => (
        <Tooltip title={tooltip}>
          <div
            className="truncate"
            style={{ color: defaultColor, display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9em' }}
          >
            {selectedItem ? itemName(selectedItem, selectedItemIdx) : placeholder}
          </div>
        </Tooltip>
      )}
      onChange={handleSelect}
      className="add-new-task-panel-lists"
    >
      {!!selectedItemId && (
        <MenuItem style={{ color: defaultColor }}>
          <ListItemIcon>
            <Clear />
          </ListItemIcon>
          <ListItemText>Clear</ListItemText>
        </MenuItem>
      )}
      {!!selectedItemId && <Divider />}
      {userSchedule?.moreItems.map((item, idx) => (
        <MenuItem key={item.id} value={item.id} style={{ color: 'rgb(50,50,50)' }}>
          <ListItemText>{itemName(item, idx)}</ListItemText>
          {rightAction}
        </MenuItem>
      ))}
    </BorderlessSelect>
  );
};

export const UserScheduleZonesButton: FC<UserScheduleZonesIconProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  rightAction,
  tooltip,
  listColor = 'rgb(50,50,50)',
}) => {
  const userSchedule = useUserPrefs().user_schedule;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => {
    onSelectItem(id);
    handleClose();
  };

  const selectedItemIdx = userSchedule.moreItems?.findIndex((l) => l?.id === selectedItemId);
  const selectedItem = userSchedule.moreItems?.[selectedItemIdx];

  if (!userSchedule.moreItems) return null;

  return (
    <>
      <Tooltip title={tooltip || ''}>
        <IconButton onClick={handleClick}>
          <ViewDay sx={{ color: selectedItemId ? listColor : 'gray' }} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!!selectedItemId && [
          <MenuItem key="clear" onClick={() => handleSelect('')} style={{ color: defaultColor }}>
            <ListItemIcon>
              <Clear />
            </ListItemIcon>
            <ListItemText>Clear</ListItemText>
          </MenuItem>,
          <Divider key="divider" />,
        ]}
        {userSchedule?.moreItems.map((item, idx) => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item.id)}
            sx={{
              backgroundColor: selectedItemId === item.id ? 'primary.highlight' : 'none',
              color: 'rgb(50,50,50)',
            }}
          >
            <ListItemText>{itemName(item, idx)}</ListItemText>
            {rightAction}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

function itemName(item: UserScheduleConfigItem, idx: number) {
  return item.displayName || `Schedule Zone ${idx}`;
}
