import React from 'react';
import Analytics from '../../analytics.jsx';
import { Notification } from '../../components';
import { Button, Stack } from '@mui/material';
import { StyledList } from './ListsSettings.jsx';

class ListInvite extends React.Component {
  state = {
    inProgress: false,
  };
  render() {
    const { inProgress } = this.state;
    const {
      inviterDisplayName,
      inviterEmail,
      listId,
      listName,
      listColor,
      invitationId,
      onAcceptInvitation,
      onDeclineInvitation,
    } = this.props;
    const lock = () => this.setState({ inProgress: true });
    const unlock = () => this.setState({ inProgress: false });
    const accept = async () => {
      lock();
      try {
        await onAcceptInvitation(invitationId);
        Notification.show('Collaboration accepted');
        Analytics.event({ category: 'List', action: 'Collaboration Accepted' });
      } catch (err) {
        unlock();
        Notification.showError();
        Analytics.event({ category: 'List', action: 'Failed to Accept Collaboration' });
      }
    };
    const decline = async () => {
      lock();
      try {
        await onDeclineInvitation(invitationId);
        Notification.show('Collaboration declined');
        Analytics.event({ category: 'List', action: 'Collaboration Declined' });
      } catch (err) {
        unlock();
        Notification.showError();
        Analytics.event({ category: 'List', action: 'Failed to Decline Collaboration' });
      }
    };
    return (
      <StyledList key={inviterEmail + listId} className="list">
        <h1 style={{ color: listColor, fontSize: '1.3em', fontWeight: 300 }}>{'#' + listName}</h1>
        <p>
          <strong>{inviterDisplayName || inviterEmail}</strong> invited you to collaborate on this list
        </p>
        <Stack spacing={2} direction="row">
          <Button variant="contained" color="primary" onClick={accept} disabled={inProgress}>
            Accept
          </Button>
          <Button variant="text" onClick={decline} disabled={inProgress}>
            Decline
          </Button>
        </Stack>
      </StyledList>
    );
  }
}

export default ListInvite;
