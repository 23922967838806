import { useState, useEffect } from 'react';
import { localStorageGetItem, localStorageSetItem } from './utils';

export const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const localStorageValue = localStorageGetItem(key);
    if (localStorageValue !== null) {
      return JSON.parse(localStorageValue);
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    localStorageSetItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
