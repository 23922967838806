import { SvgIcon } from '@mui/material';
import { theme } from '../theme';

const defaultIconColor = theme.palette.faded.main || 'rgb(110,110,110)';
// Trevor Icon (without circle) used to indicate intelligent scheduling suggestions
export const TrevorIcon = ({ color = defaultIconColor, size = '21px', ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 176 169"
      className="trevor-icon"
      style={{ width: size, height: size, strokeWidth: 0, stroke: 'none', ...otherProps }}
    >
      <path
        fill={color}
        d="m159.9 0l-55.8 96.5 15.7 27.2 55.7-96.6zm-144.2 32.5l-15.6 27.2h111.4l15.7-27.2zm29 39.1l55.7 96.5h31.3l-55.7-96.5z"
      ></path>
    </SvgIcon>
  );
};

export const ExpandMoreOutlined = ({ color = defaultIconColor, size = '21px', ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      className="trevor-icon"
      style={{ width: size, height: size, strokeWidth: 0, stroke: 'none', padding: '2px', ...otherProps }}
    >
      <path
        d="M10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0Z"
        fill={color}
      />
      <path d="M13.0007 7.59L10.0008 10.58L7.00009 7.59L5.59009 9L10.0008 13.41L14.4107 9L13.0007 7.59Z" fill={color} />
    </SvgIcon>
  );
};

export const SmartSchedulingIcon = ({ color = defaultIconColor, size = '22px', ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 22 22"
      // className="trevor-icon"
      style={{ width: size, height: size, strokeWidth: 0, stroke: 'none', ...otherProps }}
    >
      <path
        d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V8H19V20Z"
        fill={color}
        transform="translate(-1, -2)"
      />
      <path
        d="M13.5 12.5L12 9.19995L10.5 12.5L7.19995 14L10.5 15.5L12 18.8L13.5 15.5L16.8 14L13.5 12.5Z"
        fill={color}
        transform="translate(-1, -2)"
      />
    </SvgIcon>
  );
};

export const SchedulingSuggestionsIcon = ({ color = defaultIconColor, size = '22px', ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      // className="trevor-icon"
      style={{ width: size, height: size, strokeWidth: 0, stroke: 'none', ...otherProps }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.98 2H19.98C21.08 2 21.98 2.9 21.98 4L21.99 10C21.99 11.1 21.09 12 19.99 12H4C2.89 12 2 11.1 2 10V4C2 2.9 2.88 2 3.99 2H4.99H5.49H6.49H6.99H16.98H17.98H18.98ZM5.75 5.75L7 3L8.25 5.75L11 7L8.25 8.25L7 11L5.75 8.25L3 7L5.75 5.75Z"
        fill={color}
        // transform="translate(-1, -1)"
      />
      <path
        d="M18 19H17H16H15L8.99996 19H8.49996H7.49996H6.99996H5.99996C5.0001 19 4 19 4 19V20.035C4 21.1157 4.89 22 6 22L18 22C19.1 22 20 21.1157 20 20.0349V19C20 19 19.1 19 18 19Z"
        fill={color}
        // transform="translate(-1, -1)"
      />
      <path
        d="M18.9899 14L17.9899 14H16.9899H6.99989H6.49989H5.49989H4.99989C4.00003 14 3 14 3 14V15.0349C3 16.1157 3.89 17 5 17L18.9899 17C20.0899 17 20.9899 16.1157 20.9899 15.035V14C20.9899 14 20.0899 14 18.9899 14Z"
        fill={color}
        // transform="translate(-1, -1)"
      />
    </SvgIcon>
  );
};

export const InSchedulingQueueIcon = ({ color = defaultIconColor, size = '22px', ...otherProps }) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      // className="trevor-icon"
      style={{ width: size, height: size, strokeWidth: 0, stroke: 'none', ...otherProps }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H17C18.1 5 19 5.9 19 7V17C19 18.1 18.1 19 17 19H7C5.9 19 5 18.1 5 17V7C5 5.9 5.9 5 7 5ZM12 6.5L13.7188 10.2812L17.5 12L13.7188 13.7188L12 17.5L10.2812 13.7188L6.5 12L10.2812 10.2812L12 6.5Z"
        fill={color}
      />
    </SvgIcon>
  );
};
