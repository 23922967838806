import { TodoistApi } from '@doist/todoist-api-typescript';

const escapeFilter = (f: string) => {
  let res = f.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
  if (res.includes(',')) {
    res = res
      .split(',')
      .map((s) => '(' + s.trim() + ')')
      .join(' | ');
  }

  return res;
};

export const loadTasksIdsWithFilter = async (token: string, filterQuery: string, lang: string = 'en') => {
  const api = new TodoistApi(token);
  console.log('loadTasksIdsWithFilter', filterQuery);
  return api.getTasks({ filter: escapeFilter(filterQuery), lang }).then((tasks) => {
    console.log('Loaded tasks:', tasks);
    return tasks.map((task) => task.id);
  });
};

export const setDuration = async (token: string, taskId: string, duration: number) => {
  const api = new TodoistApi(token);
  return api.updateTask(taskId, { duration, durationUnit: 'minute' });
};

export const clearDueDate = async (token: string, taskId: string) => {
  const api = new TodoistApi(token);
  return api.updateTask(taskId, { dueString: 'no date' });
};
