import React from 'react';
import Quill from 'quill';
import Delta from 'quill-delta';
import 'quill/dist/quill.snow.css';
import throttle from 'lodash.throttle';
// import debounce from 'lodash.debounce'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import './Note.css';
import debounce from 'lodash.debounce';
import cx from 'classnames';

const ExpandCollapse = ({ collapsed, onClick }) => {
  return <IconButton onClick={onClick}>{collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>;
};

export const note2text = (note) => {
  let delta = new Delta(JSON.parse(note));
  let text = delta
    .map(function (op) {
      if (typeof op.insert === 'string') {
        return op.insert;
      } else {
        return '';
      }
    })
    .join('');
  // console.log('## note text', text, note)
  return text;
};

export class Note extends React.Component {
  state = {
    collapsed: false,
  };
  constructor(props) {
    super(props);
    let { content, uniqueKey, defaultCollapsed = false } = this.props;
    if (content && uniqueKey) {
      let collapsedStr = window.sessionStorage.getItem('note-' + uniqueKey + '-collapsed');
      if (collapsedStr) {
        this.state.collapsed = collapsedStr === 'true';
        return;
      }
    }
    this.state.collapsed = defaultCollapsed;
  }
  componentDidUpdate() {
    let { content } = this.props;
    if (!content) return;

    // console.log('Note new content:', content)
    if (this.unsaved) {
      // console.log('Unsaved content - ignore external update')
      return;
    }
    let range = this.quill.getSelection();
    this.quill.setContents(JSON.parse(content));
    if (range) this.quill.setSelection(range);
  }
  setCollapsedState = (collapsed) => {
    let { uniqueKey } = this.props;
    this.setState({ collapsed });
    if (uniqueKey) {
      window.sessionStorage.setItem('note-' + uniqueKey + '-collapsed', collapsed);
    }
  };
  componentWillReceiveProps(props) {
    let { expand, collapse } = props;
    if (expand === true || collapse === true) {
      let collapsed = collapse ? true : false;
      this.setCollapsedState(collapsed);
    }
  }
  componentDidMount() {
    let { content, onSave, quillRef } = this.props;

    var options = {
      modules: {
        history: {
          userOnly: true,
        },
      },
      placeholder: '...',
    };

    let quill = new Quill(this.refs.element, options);

    if (quillRef) {
      quillRef.current = quill;
    }

    let tsave = debounce(() => {
      let newContent = JSON.stringify(quill.getContents());
      if (content === newContent) return; // ignore
      // console.log('actual save!!')
      onSave(newContent);
      this.unsaved = null;
    }, 700);

    quill.on('text-change', (delta) => {
      // console.log('quill text-change')
      this.unsaved = true;
      tsave();
    });

    if (content) {
      quill.setContents(JSON.parse(content));
    }
    this.quill = quill;
  }
  render() {
    const { content, focused, compact } = this.props;
    const { collapsed } = this.state;

    const toggle = () => this.setCollapsedState(!collapsed);

    return (
      <div className={cx({ 'note-container': true, collapsed, compact })}>
        <div ref="element" onFocus={() => focused && focused()}></div>
        {!!content && (
          <div className="show-more-less">
            <ExpandCollapse collapsed={collapsed} onClick={toggle} />
          </div>
        )}
      </div>
    );
  }
}
