import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as taskOps from '../operations/task';
import { IconButton, Menu, MenuItem, ListItemIcon, Divider, Avatar, ListItemText } from '@mui/material';
import PersonOutline from '@mui/icons-material/PersonOutline';
import { styled } from '@mui/system';

const StyledAvatar = styled(Avatar)({
  height: 25,
  width: 25,
});

export const AssignUserWidget = ({ task, assignToList }) => {
  const user = useSelector((state) => state.account.user);

  const [anchorEl, setAnchorEl] = useState(null);

  let bgcolor = 'transparent';
  if (task.assignedTo) {
    bgcolor = task.assignedTo === user.email ? 'primary.main' : 'info';
  }
  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.target)} sx={{ marginRight: '9px' }} color="info">
        <StyledAvatar
          sx={{
            bgcolor,
            fontSize: '0.75rem',
            margin: '0',
          }}
        >
          {task.assignedTo ? (
            assignToList.find((u) => u.email === task.assignedTo).displayName.charAt(0)
          ) : (
            <PersonOutline color="info" />
          )}
        </StyledAvatar>
      </IconButton>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} className="task-assign">
        {assignToList.map((u) => {
          const isYou = u.email === user.email;
          return (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                taskOps.assignTask(task.id, u.email);
              }}
              key={u.email}
              selected={u.email === task.assignedTo}
            >
              <ListItemIcon>
                <StyledAvatar alt={u.displayName} src={isYou ? user.photoUrl : undefined} />
              </ListItemIcon>
              <ListItemText>{isYou ? 'You' : u.displayName || u.email}</ListItemText>
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            taskOps.assignTask(task.id, null);
          }}
        >
          Not Assigned
        </MenuItem>
      </Menu>
    </>
  );
};
