import { useEffect } from 'react';

const promiseDelay = (delayMsec: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayMsec));
};

export const useCancellableEffect = (
  effect: (cancelHolder: { isCancelled: boolean }) => Promise<unknown>,
  deps: unknown[],
  delayMsec = 0
) => {
  const cancelHolder = {
    isCancelled: false,
  };
  useEffect(() => {
    console.log('useCancellableEffect start');
    let unsubscribe: () => void | null = null;
    promiseDelay(delayMsec)
      .then(() => {
        if (cancelHolder.isCancelled) {
          console.log('useCancellableEffect cancelled');
          return;
        }
        return effect(cancelHolder);
      })
      .then((unsub) => {
        console.log('useCancellableEffect end');
        unsubscribe = unsub as any;
      })
      .catch((err) => {
        console.log('useCancellableEffect effect exception', err);
      });
    return () => {
      console.log('useCancellableEffect cancel');
      cancelHolder.isCancelled = true;
      try {
        if (typeof unsubscribe === 'function') {
          unsubscribe?.();
        }
      } catch (err) {
        console.log('useCancellableEffect unsubscribe exception', err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
