import { WorkspacePremium } from '@mui/icons-material';
import { PremiumModalDock } from '../premium.jsx';
import { Tooltip } from '@mui/material';

export const LockIcon = ({ moduleId = 'overdue' }) => {
  return (
    <Tooltip title="Pro feature" onClick={() => PremiumModalDock.showUpgradeModule(moduleId)}>
      <WorkspacePremium style={{ color: 'rgba(0, 0, 0, 0.33)', marginLeft: '10px' }} />
    </Tooltip>
  );
};
