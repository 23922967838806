import React, { Component } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';

export class EmailPrefs extends Component {
  state = {
    inProgress: false,
  };
  render() {
    let { prefs, onChange } = this.props;
    let { inProgress } = this.state;

    const change = async (prefs) => {
      try {
        this.setState({ inProgress: true });
        await onChange(prefs);
      } catch (err) {
        console.log('error updating changes', err);
      } finally {
        this.setState({ inProgress: false });
      }
    };
    return (
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            sx={{ textAlign: 'left', marginBottom: '15px' }}
            control={
              <Checkbox
                checked={prefs.platform}
                onChange={(e) => change({ ...prefs, platform: e.target.checked })}
                disabled={inProgress}
              />
            }
            label="Your Trevor - Important notifications and service-related content"
          />
          <FormControlLabel
            sx={{ textAlign: 'left', marginBottom: '15px' }}
            control={
              <Checkbox
                checked={prefs.news}
                onChange={(e) => change({ ...prefs, news: e.target.checked })}
                disabled={inProgress}
              />
            }
            label="Our Team - Newsletter to help you get started and master scheduling"
          />
        </FormGroup>
      </FormControl>
    );
  }
}

export class EmailPrefsDetailed extends Component {
  state = {
    inProgress: false,
  };
  render() {
    let { prefs, onChange } = this.props;
    let { inProgress } = this.state;

    const change = async (prefs) => {
      try {
        this.setState({ inProgress: true });
        await onChange(prefs);
      } catch (err) {
        console.log('error updating changes', err);
      } finally {
        this.setState({ inProgress: false });
      }
    };
    return (
      <FormControl component="fieldset">
        <FormGroup>
          <p style={{ textAlign: 'left' }}>
            <strong>Your Trevor</strong> (recommended)
          </p>
          <FormControlLabel
            sx={{ textAlign: 'left', marginBottom: '15px' }}
            control={
              <Checkbox
                checked={prefs.platform}
                onChange={(e) => change({ ...prefs, platform: e.target.checked })}
                disabled={inProgress}
              />
            }
            label="Important notifications and service-related content"
          />
          <FormControlLabel
            sx={{ textAlign: 'left', marginBottom: '15px', marginLeft: '20px' }}
            control={
              <Checkbox
                checked={prefs.startMyDay || false}
                onChange={(e) => change({ ...prefs, startMyDay: e.target.checked })}
                disabled={inProgress || !prefs.platform}
              />
            }
            label="Start My Day - your daily digest"
          />
          <hr />
          <p style={{ textAlign: 'left' }}>
            <strong>Our Team</strong>
          </p>
          <FormControlLabel
            sx={{ textAlign: 'left', marginBottom: '15px' }}
            control={
              <Checkbox
                checked={prefs.news}
                onChange={(e) => change({ ...prefs, news: e.target.checked })}
                disabled={inProgress}
              />
            }
            label="Newsletter to help you get started and master scheduling"
          />
        </FormGroup>
      </FormControl>
    );
  }
}
