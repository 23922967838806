import { initializeApp } from 'firebase/app';

// Initialize Firebase
const config =
  typeof process.env.REACT_APP_FIREBASE_CLIENT_CONFIG !== 'undefined'
    ? JSON.parse(process.env.REACT_APP_FIREBASE_CLIENT_CONFIG)
    : {
        apiKey: 'AIzaSyCL5Pf1NVI_Ax_W_pE9j9vtQrcBz1ic1W0',
        authDomain: 'trevor-web-dev.firebaseapp.com',
        databaseURL: 'https://trevor-web-dev.firebaseio.com',
        projectId: 'trevor-web-dev',
        storageBucket: 'trevor-web-dev.appspot.com',
        messagingSenderId: '755214637960',
      };

export const firebaseApp = initializeApp(config);
console.log('DB initialized', config.projectId);
