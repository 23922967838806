import { EXTENSION_DATA_LOADED, UNLOAD_EXTENSION } from '../actions/extensions';

const defaultState = {};

export const extensions = (state = defaultState, action) => {
  switch (action.type) {
    case EXTENSION_DATA_LOADED:
      return {
        ...state,
        [action.extension]: action.data,
      };
    case UNLOAD_EXTENSION: {
      let newState = { ...state };
      delete newState[action.extension];
      return newState;
    }
    default:
  }
  return state;
};
