import { FC, useEffect, useMemo, useState } from 'react';
import { MAX_FREE_EVENTS } from '../sagas/settings';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers';

const getProperGreetingTime = () => {
  let g = null; //return g

  const m = moment();

  var split_afternoon = 12; //24hr time to split the afternoon
  var split_evening = 17; //24hr time to split the evening
  var currentHour = parseFloat(m.format('HH'));

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = 'afternoon';
  } else if (currentHour >= split_evening) {
    g = 'evening';
  } else {
    g = 'morning';
  }

  return g;
};

const generateText = (firstName: string, freeEventsLeft: number) => {
  if (freeEventsLeft === MAX_FREE_EVENTS) {
    return (
      `Good ` +
      getProperGreetingTime() +
      ', ' +
      firstName +
      `. Let's start by scheduling ` +
      freeEventsLeft +
      ` of your priority tasks for the day.`
    );
  }
  if (freeEventsLeft === 0) {
    return `Daily priorities set! Let's make this ` + getProperGreetingTime() + ' count.';
  }
  if (freeEventsLeft === 1) {
    return `Great planning! Just ` + freeEventsLeft + ` more priority task to go.`;
  }
  if (freeEventsLeft < MAX_FREE_EVENTS) {
    return (
      `Good planning, ` +
      firstName +
      `. Just ` +
      freeEventsLeft +
      ` more task` +
      (freeEventsLeft === 1 ? '' : 's') +
      ` to schedule.`
    );
  }
  return firstName + `, you have ` + freeEventsLeft + ` priority tasks left to schedule.`;
};

const Typer: FC<{ text: string }> = ({ text }) => {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    setIdx(0);
    const interval = setInterval(() => {
      setIdx((prev) => {
        const next = prev + 1;
        if (next <= text.length) {
          return next;
        }
        clearInterval(interval);
        return prev;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [text]);

  return <div>{text.slice(0, idx)}</div>;
};

let g_instanceTrevorMessageBubble = null;

const TrevorMessageBubble = () => {
  const [message, setMessage] = useState(null);
  useEffect(() => {
    g_instanceTrevorMessageBubble = { __showMessage: setMessage };
  }, []);

  const user = useSelector<ReduxState, any>((state) => state.account.user);
  const freeEventsLeft = user?.freeEventsLeft || 0;
  const firstName = user.displayName.split(' ')[0];

  const _text = useMemo(() => generateText(firstName, freeEventsLeft), [firstName, freeEventsLeft]);

  const text = message || _text;

  useEffect(() => {
    const element = document.querySelector('.trevor-text-field') as HTMLElement;
    if (element) {
      element.classList.remove('animation-fade');
      const tm = setTimeout(() => {
        element.classList.add('animation-fade');
      }, 10);
      return () => clearTimeout(tm);
    }
  }, [text]);

  return (
    <div
      className="trevor-message-bubble hidden-mobile-xs trevor-font"
      style={{ fontSize: '0.9em', fontWeight: '400' }}
    >
      <a className="trevor-text-field animation-fade">
        <Typer text={text} />
      </a>
    </div>
  );
};

export default TrevorMessageBubble;

export class TrevorMessage {
  static show(text) {
    if (g_instanceTrevorMessageBubble) g_instanceTrevorMessageBubble.__showMessage(text);
  }
  static reset() {
    if (g_instanceTrevorMessageBubble) g_instanceTrevorMessageBubble.__showMessage(null);
  }
  static showError(text = ' ') {
    if (g_instanceTrevorMessageBubble) g_instanceTrevorMessageBubble.__showMessage(text);
    else alert(text);
  }
}
