import { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultListId, getEnabledLists } from 'reducers/tasks';
import { Paper, Toolbar, Box, Button, Chip, CircularProgress } from '@mui/material';
import { Event } from '@mui/icons-material';
import { TaskInputWithAutoComplete } from '../modules/tasks';
// TODO fix this import
import { __ListsDropdown as ListsDropdown } from '../modules/tasks';
import * as chrono from 'chrono-node';
import moment from 'moment';
import { theme } from '../theme';
import Analytics from '../analytics.jsx';
import * as taskOps from '../operations/task';
import { usePredictDurationEnabled } from '../operations/ml';
import { getSinglePrediction } from '../operations/schedule';
import * as eventOps from '../operations/event';
import { ReduxState } from 'reducers';
import { defaultColor, generateEventId } from 'common/utils';
import { List, taskTitleWithList } from 'shared';

// Define custom rules for chrono-node date parser
let customParser = chrono.casual.clone();
customParser.refiners.push({
  refine: (context, results) => {
    results = results.filter((result) => {
      // If there is no AM/PM (meridiem) specified, let all time between 1:00 - 4:00 be PM (13.00 - 16.00)
      if (!result.start.isCertain('meridiem') && result.start.get('hour') >= 1 && result.start.get('hour') < 4) {
        result.start.assign('meridiem', 1);
        result.start.assign('hour', result.start.get('hour') + 12);
      }
      // Ignore seconds
      if (/\b(\d+)(s|sec|seconds|second)\b|\b(s|sec|seconds|second)\b/i.test(result.text)) {
        return false; // Ignore this result
      }
      // Ignore abbreviations, like 'd' or '3d' for day, week, month and year.
      if (/\b(\d+)(d|w|m|y)\b|\b(d|w|m|y)\b/i.test(result.text)) {
        return false; // Ignore this result
      }
      return true; // keep this result
    });
    return results;
  },
});

interface AddNewTaskPanelProps {
  onAdd?: () => void;
  requestedListId?: { id: string };
  autofocus?: boolean;
  onUnselectList?: () => void;
}

export const AddNewTaskPanel: FC<AddNewTaskPanelProps> = ({
  onAdd,
  requestedListId,
  onUnselectList,
  autofocus = false,
}) => {
  const lists = useSelector<ReduxState, List[]>(getEnabledLists);
  const currentListId = useSelector<ReduxState, string>((state) => state.ui.selectedListId);
  const defaultListId = useSelector(getDefaultListId);
  const default_task_duration = useSelector<ReduxState, number>(
    (state) => state.account.user?.prefs?.default_task_duration || 60
  );
  const add_task_nlp = useSelector<ReduxState, number>((state) => state.account.user?.prefs?.add_task_nlp ?? true);
  // const defaultCalendarId = useSelector<ReduxState, string>((state) => state.account.user.defaultCalendarId);

  const [_taskName, setTaskName] = useState('');
  const [_selectedListId, setSelectedListId] = useState(undefined);
  const selectedListId = requestedListId || currentListId || _selectedListId;
  const selectedListTitle = lists.find((l) => l.id === (selectedListId || defaultListId))?.name || 'na';

  const [savingInProgress, setSavingInProgress] = useState(false);
  const [parsedDate, setParsedDate] = useState(null);

  const predictDurationEnabled = usePredictDurationEnabled();

  const today = moment().toDate();

  // console.log('TEST Selected: ', selectedListId);
  // console.log('TEST Current: ', currentListId);
  // console.log('TEST Requested: ', requestedListId);

  const onAddNew = async (e?: any) => {
    e && e.preventDefault();
    const taskName = _taskName?.trim();

    if (taskName?.length < 2 || savingInProgress) {
      return;
    }
    const findDefaultList = () => lists.find((list) => list.default) || lists.find((list) => list.enabled);

    // Use Chrono-node to parse the task name for a date/time
    const parsedResult = add_task_nlp ? customParser.parse(taskName, today, { forwardDate: true }) : [];
    console.log('PARSE', taskName, 'parsedResult:', parsedResult);

    let taskNameWithoutDate = taskName;
    let start = null;
    let allDay;
    if (parsedResult.length > 0) {
      // If date/time, Set start and allDay
      const dateText = taskName.slice(parsedResult[0].index, parsedResult[0].index + parsedResult[0].text.length);
      taskNameWithoutDate = taskName.replace(dateText, '').trim();
      start = moment(parsedResult[0].start.date());

      // Round to nearest 15 minutes interval based on snapDuration
      let minutes = start.minutes();
      let remainder = minutes % 15;
      if (remainder <= 7) {
        start.minutes(minutes - remainder);
      } else {
        start.minutes(minutes + (15 - remainder));
      }
      start.seconds(0);

      allDay = parsedResult[0].start.isCertain('hour') ? false : true;
      if (start && predictDurationEnabled) {
        setSavingInProgress(true);
        // If a date/time was parsed, schedule the task
        const titleWithList = taskTitleWithList(taskName, selectedListTitle);
        let duration = (await getSinglePrediction(titleWithList)) || default_task_duration;
        const end = start.clone().add(duration, 'minutes');
        eventOps.createNewAssociatedEvent({
          title: taskNameWithoutDate,
          allDay,
          beginDate: allDay ? moment(start).format('YYYY-MM-DD') : moment(start).toISOString(),
          endDate: allDay ? moment(end).format('YYYY-MM-DD') : moment(end).toISOString(),
          // calendarId: defaultCalendarId,
          suggestId: generateEventId(),
          taskListId: selectedListId || currentListId || findDefaultList()?.id,
        });
        Analytics.event({
          category: 'Scheduling',
          action: 'Scheduled Task from AddNewTaskPanel',
        });
        setSavingInProgress(false);
        // TODO: ADD_EVENT_WITH_TASK_SUCCESS notification's "Go To" function expects "2023-07-04T08:00:00.000Z" but receives undefined
      }
    } else {
      const newTask = {
        title: taskNameWithoutDate,
        listId: selectedListId || currentListId || findDefaultList()?.id,
      };
      taskOps.addNewTask(newTask);
    }

    Analytics.event({
      category: 'Task',
      action: 'Created Task',
    });
    setTaskName('');
    setParsedDate(null);
    onAdd && onAdd();
  };

  const handleTaskOnChange = (taskName) => {
    // Show parsed date to user in real-time
    const parsedResult = add_task_nlp ? customParser.parse(taskName, today, { forwardDate: true }) : [];
    console.debug('TEST Task:', taskName);
    setParsedDate(null); // clear if date is deleted by user
    if (parsedResult.length > 0) {
      const dateText = taskName.slice(parsedResult[0].index, parsedResult[0].index + parsedResult[0].text.length);
      console.debug('TEST Time', dateText);
      setParsedDate(dateText); // set date to be shown to user
    }

    setTaskName(taskName);
  };

  const handleSelectList = (listId) => setSelectedListId(listId);

  const handleUnselectList = () => {
    setSelectedListId(null);
    onUnselectList?.();
  };

  let currentList = lists.find((l) => l.id === currentListId);
  const selectedList = selectedListId ? lists.find((l) => l.id === selectedListId) : null;

  const trunkateListLength = 9;

  let renderListOps;
  if (selectedList) {
    renderListOps = (
      <Box
        style={{
          padding: 2,
        }}
        className="add-new-task-panel-lists"
      >
        <Chip
          style={{
            margin: 10,
            float: 'right',
            backgroundColor: selectedList.color || defaultColor,
            color: 'white',
          }}
          onDelete={handleUnselectList}
          label={'#' + selectedList.name.substring(0, trunkateListLength) + '..'}
        />
      </Box>
    );
  } else if (!selectedList && currentListId) {
    renderListOps = (
      <ListsDropdown
        lists={lists}
        onSelectList={handleSelectList}
        placeholder={'#' + currentList.name}
        flexGrow={1.5}
      />
    );
  } else {
    renderListOps = (
      <Box className="list-dropdown-placeholder">
        <ListsDropdown lists={lists} onSelectList={handleSelectList} placeholder="#" />
      </Box>
    );
  }

  return (
    <Paper
      id="add-new-task-panel"
      className="list-create-task"
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: '8',
        boxShadow: (theme) => theme.shadow.soft,
        borderRadius: (theme) => theme.mainBox.radiusInt - 4 + 'px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        height: parsedDate ? '98px' : '56px',
        padding: '0 10px 0 16px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
          // mobile
          borderRadius: theme.mainBox.radiusInt / 2 + 'px',
        },
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          width: '100%',
          height: 'auto',
          gap: '15px',
        }}
      >
        {parsedDate && (
          <Chip
            sx={{
              marginTop: '10px',
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            }}
            icon={<Event sx={{ color: 'white !important', fontSize: '20px' }} />}
            label={parsedDate}
          />
        )}
      </Box>
      <Toolbar variant="dense" style={{ padding: 0, height: '100%' }} className="add-new-task-panel-container">
        <Box style={{ flexGrow: 2 }} className="add-new-task-panel-input">
          <TaskInputWithAutoComplete
            externalValue={_taskName}
            onChange={(taskName) => handleTaskOnChange(taskName)}
            onEnter={() => onAddNew()}
            stripHashtagOnSelect
            onListSelect={(selectedListId) => {
              setSelectedListId(selectedListId);
              Analytics.event({ category: 'Task', action: 'Selected List with Hashtag' });
            }}
            autofocus={requestedListId ? true : autofocus}
            placeholder="New task at 9am #list"
            keybindingFocusInput="ctrl+space,option+space"
          />
        </Box>
        {renderListOps}
        <Button
          variant="contained"
          type="submit"
          // edge="end"
          color="primary"
          className="add-new-task-panel-button"
          onClick={onAddNew}
          sx={{ borderRadius: (theme) => theme.mainBox.radiusInt / (2 / 3) + 'px' }}
          disabled={savingInProgress}
        >
          {savingInProgress ? <CircularProgress size="1.5rem" color="primary" /> : 'Add'}
        </Button>
      </Toolbar>
    </Paper>
  );
};
