import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

const BackToHomeButton = () => {
  return (
    <Tooltip title="Back to Home">
      <IconButton sx={{ height: (theme) => theme.pageNav.height, width: (theme) => theme.pageNav.height }}>
        <Link to="/app" style={{ display: 'inline-flex' }}>
          <ArrowBack style={{ color: 'grey' }} />
        </Link>
      </IconButton>
    </Tooltip>
  );
};

export default BackToHomeButton;
