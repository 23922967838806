import { localStorageGetItem, localStorageSetItem } from 'common/utils';
import { store } from '.';
import * as actions from 'actions';

const LOCAL_STORAGE_KEY = 'local_prefs';

export const saveToLocalPrefs = (key, value) => {
  const existingValueJSON = localStorageGetItem(LOCAL_STORAGE_KEY, '{}');
  try {
    const existingValue = JSON.parse(existingValueJSON);
    const newValue = { ...existingValue, [key]: value };
    localStorageSetItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue));

    store().dispatch(actions.updateLocalPrefs({ [key]: value }));
  } catch (err) {
    console.error('Error saving local prefs:', err);
  }
};

export const getLocalPrefs = () => {
  const prefsJSON = localStorageGetItem(LOCAL_STORAGE_KEY, '{}');
  try {
    const prefs = JSON.parse(prefsJSON);
    return prefs;
  } catch (err) {
    console.error('Error parsing existing local prefs:', err);
    return {};
  }
};
