import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import * as plannerOps from '../../operations/schedule';
import { getVisibleEvents } from 'reducers/calendar';
import Analytics from '../../analytics.jsx';
import { Button, Grid } from '@mui/material';
import { PremiumModalDock } from '../../premium.jsx';
import { usePredictDuration } from '../../operations/ml';
import * as actions from 'actions';
import { theme } from '../../theme';
import {
  Task,
  enhanceTaskWithUserScheduleConfig,
  formatHumanDate,
  generateDatesRange,
  generateSuggestionsForTasks,
  isRecurringTask,
} from 'shared';
import { ReduxState } from 'reducers';
import { useCancellableEffect } from 'common/useCancellableEffect';
import { Date_max } from 'common/utils';
import { getCachedTaskPredictions } from 'common/firebase';

const MinAllDuration = 24 * 60;
const MAX_PERIOD_DAYS = 7;
const MAX_NUM_SUGGESTIONS = 4;

// export const SmartSuggestions = React.memo(({ task, limit = 5 }) => {
//   const { rawDatesRange, defaultTaskDuration, workTimeStart, events, freeLimitReached } = useSelector((state) => {
//     // const { isPremium, freeEventsLeft } = state.account.user;
//     // const freeLimitReached = !isPremium && !freeEventsLeft;
//     const freeLimitReached = false; // 'schedule' upgrade module not currently in use
//     return {
//       rawDatesRange: state.ui.viewDatesRange,
//       defaultTaskDuration: state.account?.user?.prefs?.default_task_duration || 60,
//       workTimeStart: state.account?.user?.prefs?.start_of_day || 9,
//       events: getVisibleEvents(state),
//       freeLimitReached,
//     };
//   }, shallowEqual);
export const SmartSuggestions: FC<{ task: Task; limit?: number }> = React.memo(({ task, limit = 5 }) => {
  const { rawDatesRange, prefs, events, freeLimitReached } = useSelector((state: ReduxState) => {
    // const { isPremium, freeEventsLeft } = state.account.user;
    // const freeLimitReached = !isPremium && !freeEventsLeft;
    const freeLimitReached = false; // 'schedule' upgrade module not currently in use

    return {
      rawDatesRange: state.ui.viewDatesRange,
      prefs: state.account?.user?.prefs,
      events: getVisibleEvents(state),
      freeLimitReached,
    };
  }, shallowEqual);
  const dispatch = useDispatch();

  const listsObj = useSelector<ReduxState, ReduxState['tasks']['listsObj']>(
    (state: ReduxState) => state.tasks.listsObj
  );
  const listColor = task ? listsObj[task.listId]?.color : '';

  const taskJSON = JSON.stringify(task);
  const shallowEventsJSON = JSON.stringify(
    events?.map(({ allDay, beginDate, endDate, isFree = false }) => ({ allDay, beginDate, endDate, isFree }))
  );

  const { topPrediction, predictDurationEnabled, loading: _predictionIsLoading } = usePredictDuration(task);
  const predictionIsLoading = _predictionIsLoading || (predictDurationEnabled && !topPrediction);

  const eventDuration = task.eventId ? moment(task.eventEndDate).diff(task.eventBeginDate, 'minutes') : null;
  const defaultTaskDuration = prefs?.default_task_duration || 60;

  let duration = eventDuration || task.duration || topPrediction || defaultTaskDuration;
  let allDay = false;
  let originalDuration; // used to calculate difference in start and end for all-day events
  if (duration >= MinAllDuration) {
    originalDuration = duration;
    duration = defaultTaskDuration;
    allDay = true;
  }

  const onSchedule = (beginDate, endDate, allDay) => {
    if (freeLimitReached && !task.eventId) {
      PremiumModalDock.showUpgradeModule('schedule');
      Analytics.event({
        category: 'Premium',
        action: 'Free events limit reached',
      });
      return;
    }
    const hint = {
      beginDate,
      endDate,
      allDay,
    };
    plannerOps.scheduleTask(task, hint);
    Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Smart Suggestions' });
  };

  useEffect(() => {
    if (task.dueDate && moment(task.dueDate).diff(rawDatesRange.end, 'days') > 0) {
      dispatch(
        actions.changeCalendarDateRange(moment(task.dueDate).subtract(MAX_PERIOD_DAYS, 'days'), task.dueDate, false)
      );
    }
  }, [task.dueDate, rawDatesRange.end, dispatch]);

  const [suggestionEvents, setSuggestionEvents] = useState([]);

  useCancellableEffect(
    async (cancelHolder) => {
      const task = JSON.parse(taskJSON) as Task;
      if (task.completed) return;

      const shallowEvents = JSON.parse(shallowEventsJSON);

      const timeNow = moment();
      const start = moment(rawDatesRange.start);

      let fromDate = Date_max(timeNow, start);
      let toDate = moment(fromDate).add(MAX_PERIOD_DAYS, 'days');

      if (task.dueDate && timeNow.isSameOrBefore(task.dueDate, 'date')) {
        toDate = moment(task.dueDate).endOf('day');
        fromDate = moment(task.dueDate).startOf('day');
        if (!(isRecurringTask(task) ? prefs.recurring_due_date_is_exact_date : prefs.due_date_is_exact_date)) {
          fromDate.subtract(MAX_PERIOD_DAYS, 'days');
        }
        fromDate = Date_max(timeNow, fromDate);
      }

      const ctx = {
        momenttz: moment,
        prefs,
      };

      let dates: moment.Moment[] = generateDatesRange(ctx, fromDate, toDate);

      console.log('SmartSuggestions generateSuggestionsForTasks:', shallowEvents);

      const suggestionsWithScore = [];
      for (const date of dates) {
        if (cancelHolder.isCancelled) return;

        const daySuggestions = await generateSuggestionsForTasks({
          ctx,
          tasks: [enhanceTaskWithUserScheduleConfig(task, prefs.user_schedule, listsObj)],
          fromDate: date,
          toDate: date,
          events: shallowEvents,
          strategy: 'predicted',
          limit: 1,
          hintDatetime: task.eventBeginDate,
          getCachedTaskPredictions,
        });
        suggestionsWithScore.push(...daySuggestions);
      }

      const suggestions = suggestionsWithScore
        .sort((a, b) => b.score - a.score)
        .slice(0, MAX_NUM_SUGGESTIONS)
        .map(({ beginDate }) => beginDate)
        .sort((a, b) => a.diff(b, 'minutes'));

      const suggestionEvents = suggestions.map((suggestion) => {
        let start = allDay ? moment(suggestion).format('YYYY-MM-DD') : moment.utc(suggestion).format();
        let end = allDay
          ? moment(suggestion).add(originalDuration, 'm').format('YYYY-MM-DD')
          : moment(suggestion).add(duration, 'm').utc().format();
        return {
          title: task.title,
          start,
          end,
          allDay,
          className: 'suggestion-event',
          editable: false,
          suggestionTask: task,
          backgroundColor: listColor,
          borderColor: listColor,
          textColor: listColor,
        };
      });

      if (cancelHolder.isCancelled) return;
      setSuggestionEvents(suggestionEvents);
    },
    [rawDatesRange, allDay, prefs, shallowEventsJSON, duration, originalDuration, taskJSON, listColor]
    // 1200
  );

  useEffect(() => {
    if (predictionIsLoading) return;
    dispatch(actions.setSuggestionEvents(suggestionEvents));

    return () => {
      dispatch(actions.compareAndClearSuggestionEvents(suggestionEvents));
    };
  }, [suggestionEvents, predictionIsLoading, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearShowSuggestionsForTaskId(task.id));
    };
  }, [task.id, dispatch]);

  return (
    <Grid
      container
      spacing={0.5}
      justifyContent="space-around"
      sx={{
        padding: '4px 0 0 4px',
        alignItems: 'stretch',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('xs')]: {
          //   maxHeight: '80px',
          //   overflow: 'auto',
          // padding: '0 8px 6px 12px',
          fontSize: '14px',
        },
      }}
      className="task-smart-suggestions-bar"
    >
      {suggestionEvents.map(({ start: date, end, allDay }) => {
        const title = formatHumanDate(date, allDay);
        return (
          <Grid item xs={12} lg={suggestionEvents.length > 1 ? 6 : 12} key={date /*.unix()*/}>
            <Button
              variant="outlined"
              sx={{
                width: '100%',
                height: '100%',
                fontSize: '13px',
              }}
              onClick={() => onSchedule(date, end, allDay)}
            >
              {title}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
});
