import moment from 'moment';
import type { Task, List, TaskPredictionSet, UserScheduleConfig } from '../types';
import { planningStrategySimple } from './planningStrategySimple';
import { planningStrategyPredicted } from './planningStrategyPredicted';
import { generatePredictionBoostFromHint } from './utils';
import { PlanningContext, TaskWithUserScheduleConfig } from './types';

const strategies = {
  simple: planningStrategySimple,
  predicted: planningStrategyPredicted,
};

export const generateSuggestionsForTasks = async (props: {
  ctx: PlanningContext;
  tasks: TaskWithUserScheduleConfig[];
  fromDate: moment.Moment;
  toDate: moment.Moment;
  events: { beginDate: string; endDate: string; allDay: boolean; isFree?: boolean }[];
  strategy: keyof typeof strategies;
  limit?: number;
  hintDatetime?: string;
  getCachedTaskPredictions: (taskId: string) => Promise<TaskPredictionSet>;
}) => {
  const { ctx, tasks, fromDate, toDate, events, strategy, limit = 20, hintDatetime, getCachedTaskPredictions } = props;
  const {
    prefs: { default_task_duration: defaultTaskDuration },
  } = ctx;
  console.log('generateSuggestionsForTasks', {
    tasks,
    fromDate,
    toDate,
    events,
    ctx,
  });

  const predictionBoost = hintDatetime ? generatePredictionBoostFromHint(moment(hintDatetime)) : null;

  const tasksWithPredictions = await Promise.all(
    tasks.map((task) =>
      getCachedTaskPredictions(task.id)
        .then((pred) => {
          if (!pred) throw new Error('No predictions for task ' + task.id);
          const { duration, start_times, days } = pred;
          if (predictionBoost) {
            start_times.unshift(predictionBoost.start_times);
            days.unshift(predictionBoost.days);
          }

          console.log('Predictions:', duration[0], start_times[0], days[0], '-', task.title, pred);
          return pred;
        })
        .catch((err) => {
          console.log('Error predicting duration', err, 'ignore');
          return {
            duration: [{ value: defaultTaskDuration }],
            days: [],
            start_times: [],
          } as TaskPredictionSet;
        })
        .then((pred) => ({ task, pred }))
    )
  );

  const strategyFunction = strategies[strategy];

  const busyEvents = events.filter((e) => !e.isFree);

  const suggestions = await strategyFunction({
    ctx,
    tasksWithPredictions,
    fromDate,
    toDate,
    events: busyEvents,
    limit,
  });
  console.log('generateSuggestionsForTasks suggestions:', suggestions);
  return suggestions;
};

export const enhanceTaskWithUserScheduleConfig = (
  task: Task,
  userSchedule: UserScheduleConfig,
  listsObj: Record<string, List>
): TaskWithUserScheduleConfig => {
  const list = listsObj[task.listId];
  if (!list.associatedUserScheduleZoneId) return task;
  const userScheduleConfigItem = userSchedule?.moreItems?.find(({ id }) => id === list.associatedUserScheduleZoneId);
  return { ...task, userScheduleConfigItem };
};
