import React, { Component } from 'react';

const texts = [
  `"Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort." - Paul J. Meyer`,
  `"Deep work is the ability to focus without distraction on a cognitively demanding task." - Cal Newport`,
  `"Time blocking is a type of scheduling that helps you manage your time more effectively by assigning specific tasks to dedicated blocks of time." - Unknown`,
  `"The key is not to prioritize what's on your schedule, but to schedule your priorities." - Stephen Covey`,
  `"You can't make time; so those who waste the least, achieve the most." - Tim Fargo`,
  `"Concentrate all your thoughts upon the work at hand. The sun's rays do not burn until brought to a focus." - Alexander Graham Bell`,
  `"Efficiency is doing things right; effectiveness is doing the right things." - Peter Drucker`,
  `"The way to get started is to quit talking and begin doing." - Walt Disney`,
  `"The bad news is time flies. The good news is you're the pilot." - Michael Altshuler`,
  `"Time is a created thing. To say 'I don't have time' is to say 'I don't want to.'" - Lao Tzu`,
  `"The best way to predict the future is to create it." - Peter Drucker`,
  `"Ordinary people think merely of spending time. Great people think of using it." - Arthur Schopenhauer`,
  `"Do the hard jobs first. The easy jobs will take care of themselves." - Dale Carnegie`,
  `"The secret of your future is hidden in your daily routine." - Mike Murdock`,
  `"Amateurs sit and wait for inspiration, the rest of us just get up and go to work." - Stephen King`,
  `"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work." - Steve Jobs`,
  `"Lost time is never found again." - Benjamin Franklin`,
  `"The only way to do great work is to love what you do." - Steve Jobs`,
  `"You will never find time for anything. If you want time, you must make it." - Charles Buxton`,
  // // 'Checking if the Force is with us',
  // // 'Setting up the energy field',
  // 'Preventing robots from taking our job',
  // // 'Warming the engines',
  // 'Giving the developers a long hard stare',
  // // 'Hitting the boss key',
  // // 'Jumping and clapping',
  // 'sleep(1) to delay global warming',
  // 'Yelling at devops',
  // // 'Engine check',
  // 'Fetching the elves',
  // 'Fighting orcs',
  // // 'Scribbling "You shall not pass” sign',
  // // 'Firing the firewall',
  // 'Telling Luke I’m his father',
  // 'Telling a colleague the winter is coming',
  // 'Pausing new Silicon Valley episode',
  // 'Waking task sorting dwarfs',
  // 'Helping Frodo organize Mordor tasks',
  // 'Shrinking services into microservices',
  // 'Your Ad could stay here 0.8 seconds',
  // 'Procrastination strikes back',
  // 'Did you see that ludicrous display last night?',
  // 'Using humor as a defensive mechanism',
];
const getRandomInitText = () => {
  let index = Math.floor(Math.random() * texts.length);
  return texts[index];
};

const LOADING_STUCK_TIMEOUT = 8_000;
const HINT_CHANGE_TIMEOUT = 10_000;

export class SpinnerWithFunnyStatuses extends Component {
  state = {
    loadingMessage: getRandomInitText(),
    showMessage: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showMessage: true });
    }, LOADING_STUCK_TIMEOUT);

    this.interval = setInterval(() => {
      this.setState({ loadingMessage: getRandomInitText() });
    }, HINT_CHANGE_TIMEOUT);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <div className="loader" />
        {this.state.showMessage && (
          <div
            style={{
              position: 'absolute',
              bottom: '100px',
              left: '50%',
              minWidth: '300px',
              transform: 'translate(-50%, 0)',
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.8em',
              padding: '1em 2em',
              background: 'rgba(255,255,255,0.1)',
              borderRadius: '10px',
              animation: 'fadeIn ease 2s',
            }}
          >
            <p>Stuck on loading? Let's try to resolve it.</p>

            <ol>
              <li>
                Refresh the page
                <br /> - on PC: F5
                <br /> - on Mac: Cmd+R
              </li>
              <li>Try opening it once in Incognito mode</li>
              <li>
                Perhaps a security extention is blocking our app?
                <br /> - Try adding an exception or shields down
              </li>
            </ol>
            <p>
              None of that worked? <br />
              Contact us via the message bubble or at{' '}
              <a style={{ color: 'rgba(255,255,255,0.5)' }} href="mailto:support@trevorlabs.com">
                support@trevorlabs.com
              </a>
              .
            </p>
          </div>
        )}
        <div className="init-message">{this.state.loadingMessage}</div>
      </div>
    );
  }
}
