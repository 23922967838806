import { useState } from 'react';
import moment from 'moment';
import { MobileDateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { localStorageGetItem } from 'common/utils';

const isDateAllDay = (dateStr) => typeof dateStr === 'string' && dateStr.length <= 10;
let calendarLocale = localStorageGetItem('calendarLocale') || 'en';

const DateTimePicker = ({ date, disabled = false, onChange, onOpen }) => {
  const [open, setOpen] = useState(false);
  const [modifiedDate, setModifiedDate] = useState(null);

  const isAllDay = isDateAllDay(date);

  let ampm = false;
  let displayDate;
  if (calendarLocale === 'en') {
    ampm = true;
    displayDate = moment(date).format("h:mm a, MMM Do 'YY");
  } else {
    displayDate = moment(date).format("kk:mm, Do of MMM 'YY");
  }
  if (isAllDay) {
    displayDate = moment(date).format("MMM Do 'YY");
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateTimePicker
        value={modifiedDate || date}
        // inputFormat={format}
        open={open}
        disabled={disabled}
        ampm={ampm}
        renderInput={(p) => {
          return (
            <span
              onClick={() => {
                setOpen(true);
                onOpen();
              }}
            >
              {displayDate}
              {/* {p.inputProps.value} */}
            </span>
          );
        }}
        onChange={(date) => {
          console.log('onChange', date.toDate());
          setModifiedDate(date.toDate());
        }}
        onAccept={(momentDate) => {
          if (moment(momentDate).isSame(date)) return;

          date = momentDate.toDate();
          console.log('onAccept', date);
          onChange(date);
          setModifiedDate(null);
          setOpen(false);
        }}
        onClose={() => {
          console.log('onClose');
          setModifiedDate(null);
          setOpen(false);
        }}
      />
    </LocalizationProvider>
  );
};
export default DateTimePicker;
