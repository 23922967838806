import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import Info from '@mui/icons-material/Info';
import TipsAndUpdates from '@mui/icons-material/TipsAndUpdates';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import Analytics from '../analytics.jsx';
import { generateAiAssistance } from '../api';
import { useLocalStorageState } from 'common/utils';
import { useWatchTaskPredictions } from 'common/firebase';
import { DrawerButton } from 'components/EventContextMenu.jsx';

const Container = styled('div')({
  //   color: '#638EAF',
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '0',
  width: '100%',
  flexWrap: 'wrap',
  gap: '15px',
});

function StickyButton({ Icon, onClick }) {
  const [pressed, setPressed] = useState(false);
  const handleClick = () => {
    if (!pressed) {
      setPressed(!pressed);
      onClick();
    }
  };
  return (
    <IconButton
      onClick={handleClick}
      //disabled={pressed}
    >
      {pressed ? <Icon sx={{ color: '#638EAF' }} /> : <Icon sx={{ color: '#b7b7b7' }} />}
    </IconButton>
  );
}

const ActionPanel = ({ onLike, onDislike }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1,
        width: 'auto',
        color: '#638EAF',
        flexWrap: 'wrap',
      }}
    >
      <>
        <div>
          <StickyButton Icon={ThumbUp} onClick={onLike} />
          <StickyButton Icon={ThumbDown} onClick={onDislike} />

          <Tooltip
            placement="left"
            title="This is an AI generated example of a set of instructions for completing the task, based in it's title, list and notes. Do not blindly follow these suggestions, as they could be wrong, misleading or harmful. Make your own judgements."
          >
            <IconButton>
              <Info sx={{ color: '#638EAF' }} />
            </IconButton>
          </Tooltip>
        </div>
      </>
    </div>
  );
};

function formatResponseList(text) {
  const lines = text.split('\n') || [];
  const formattedLines = lines.map((line, index) => {
    const pattern = /^\d+\./;
    if (pattern.test(line)) {
      return (
        <li key={index} style={{ padding: '5px 0' }}>
          {line}
        </li>
      );
    } else {
      return line;
    }
  });
  return <ol style={{ listStyleType: 'none', padding: 0, margin: '0 0 0 10px' }}>{formattedLines}</ol>;
}

export const TaskActionPlan = ({ task, list, note }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generate, setGenerate] = useState(false);

  const { isPremium } = useSelector((state) => state.account.user);

  const storedPrediction = useWatchTaskPredictions(task.id);
  console.log('TaskToGPT prediction', storedPrediction);
  // const updateStoredPrediction = storedPrediction.update;

  // const refTaskTitle = useRef(task.title);
  // useEffect(() => {
  //   if (refTaskTitle.current !== task.title) {
  //     console.log('TaskToGPT: Task title changed. Clearing stored prediction');
  //     updateStoredPrediction({ summarisedSteps: null }).catch((err) => {
  //       console.log('Error updating stored prediction', err);
  //     });
  //   }
  //   refTaskTitle.current = task.title;
  // }, [task.title, updateStoredPrediction]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Immediately set loading to true before the async operation
        const response = await generateAiAssistance(task.id, list, note, 'summarisedSteps', isPremium);
        Analytics.event({ category: 'Task', action: 'AI Action Plan - Loaded' });
        setResponse(response.hint);
        setLoading(false);
      } catch (err) {
        console.log('Error fetching AI assistance:', err);
        setError(true);
        setLoading(false);
      }
    };

    if (generate) {
      fetchData();
      setGenerate(false);
    }
  }, [generate, task.id, list, isPremium]); // storedPrediction.data, storedPrediction.loading no longer needed

  let content;
  let data = response || storedPrediction.data?.summarisedSteps;
  if (loading) {
    content = <span style={{ margin: '5px 5px 5px 12px' }}>Loading...</span>;
  } else if (error) {
    content = <span style={{ margin: '5px 5px 5px 12px' }}>Something went wrong. Please reopen the event menu.</span>;
  } else if (data) {
    content = formatResponseList(data);
  }

  return (
    <Container>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        <DrawerButton
          variant="outlined"
          startIcon={<TipsAndUpdates />}
          onClick={() => setGenerate(true)}
          disabled={loading}
        >
          {content ? 'Update' : 'Generate'} 5-Step Action Plan
        </DrawerButton>
        <ActionPanel
          onLike={() => {
            Analytics.event({ category: 'Task', action: 'AI Action Plan - Liked' });
          }}
          onDislike={() => {
            Analytics.event({ category: 'Task', action: 'AI Action Plan - Disliked', label: task.title });
          }}
        />
      </div>
      {content}
    </Container>
  );
};
