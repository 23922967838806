import moment from 'moment';
import { Planner, ShallowEvent } from './planner';
import { generateDatesRange } from './utils';
import { PlanningStrategyFunction } from './types';
import { MinAllDayDuration } from './utils';
import { wrapToTimeGrid, momentToFloat } from './utils';

export const planningStrategySimple: PlanningStrategyFunction = async ({
  ctx,
  tasksWithPredictions,
  fromDate,
  toDate,
  events,
}) => {
  const {
    momenttz,
    prefs: { user_schedule, default_task_duration: defaultTaskDuration },
  } = ctx;
  const timeNow = momenttz();

  const eventsStartEnds: ShallowEvent[] = events
    .filter((ev) => !ev.allDay)
    .map((ev) => ({ start: ev.beginDate, end: ev.endDate }));

  const suggestions = [];

  for (const { task, pred } of tasksWithPredictions) {
    const eventDuration = task.eventId ? momenttz(task.eventEndDate).diff(task.eventBeginDate, 'minutes') : null;
    let duration = eventDuration || task.duration || pred.duration?.[0]?.value || 60;
    if (duration >= MinAllDayDuration) {
      // originalDuration = duration;
      duration = defaultTaskDuration;
      console.log('TODO temp consider all-day tasks having default duration', { task, duration, pred });
      // allDay = true;
    }

    const datesRange = generateDatesRange(ctx, fromDate, toDate);
    // const workTimeStart = user_schedule.zones[0].start / 60;
    // const workTimeEnd = user_schedule.zones[0].end / 60;

    for (const date of datesRange) {
      // let zones = [workTimeStart, workTimeEnd];
      if (timeNow.isSame(date, 'date')) {
        const nextSlot = moment(timeNow).add(wrapToTimeGrid(timeNow.minutes()), 'minutes');
        // if (moment(nextSlot).add(duration, 'minutes').hours() > workTimeEnd) continue;
        // zones = [momentToFloat(nextSlot), workTimeEnd];
        eventsStartEnds.push({ start: moment().startOf('day').toISOString(), end: nextSlot.toISOString() });
      }
      const planner = new Planner(date, { zones: task.userScheduleConfigItem?.zones ?? user_schedule.zones });
      const summary = planner.getSummaryByZone(eventsStartEnds);
      const { freeZones } = Object.values(summary)[0];
      const bigEnoughZones = freeZones
        .filter((zz) => timeNow.isBefore(zz.end))
        .filter((zz) => planner.zoneDurationMinutes(zz) >= duration);
      console.log('summary', date.format(), bigEnoughZones);
      // TODO find most suitable zone
      const zone = bigEnoughZones[0];
      if (zone) {
        suggestions.push({ task, beginDate: zone.start, duration });
        eventsStartEnds.push({ start: zone.start, end: moment(zone.start).add(duration, 'minutes') });
        break;
      }
    }
  }

  return suggestions;
};
