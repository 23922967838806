import { Box, SvgIcon } from '@mui/material';
import { detectOrigin } from 'common/utils';
import { getExtensionsRegistry } from 'shared';

export const integrationIcon = (list, size = 18, margin = '0 4px 2px 5px', colorOverride = null) => {
  let { origin, color, id } = list;

  if (!origin) {
    origin = detectOrigin(id);
  }

  let svg_path;
  if (origin === 'todoist') {
    svg_path =
      'M21 0H3C1.35 0 0 1.35 0 3v3.858s3.854 2.24 4.098 2.38c.31.18.694.177 1.004 0 .26-.147 8.02-4.608 8.136-4.675.279-.161.58-.107.748-.01.164.097.606.348.84.48.232.134.221.502.013.622l-9.712 5.59c-.346.2-.69.204-1.048.002C3.478 10.907.998 9.463 0 8.882v2.02l4.098 2.38c.31.18.694.177 1.004 0 .26-.147 8.02-4.609 8.136-4.676.279-.16.58-.106.748-.008.164.096.606.347.84.48.232.133.221.5.013.62-.208.121-9.288 5.346-9.712 5.59-.346.2-.69.205-1.048.002C3.478 14.951.998 13.506 0 12.926v2.02l4.098 2.38c.31.18.694.177 1.004 0 .26-.147 8.02-4.609 8.136-4.676.279-.16.58-.106.748-.009.164.097.606.348.84.48.232.133.221.502.013.622l-9.712 5.59c-.346.199-.69.204-1.048.001C3.478 18.994.998 17.55 0 16.97V21c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3z';
  } else if (origin !== 'trevor') {
    const extensionsRegistry = getExtensionsRegistry();
    const ext = extensionsRegistry[origin];
    if (ext) {
      return (
        <img
          style={{ fontSize: size + 'px !important', opacity: 0.9, margin: margin, height: '20px' }}
          src={ext.logo}
          alt={ext.name}
        />
      );
    }
  } else {
    svg_path = 'M20 10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4h4zm-6 4h-4v-4h4v4z'; // fallback to hastag
    // compensate for mui icon margin
    size = size * 1.222;
    margin = '0 2px 0 3px';
  }

  return (
    <SvgIcon sx={{ fontSize: size + 'px !important', opacity: 0.9, margin: margin, height: '20px' }}>
      <path fill={colorOverride ? colorOverride : color} d={svg_path} />
    </SvgIcon>
  );
};

export const ListNameWithIcon = ({ list, size = 18, margin = '0 4px 2px 5px', color = null }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        height: '100%',
        alignItems: 'center',
        maxWidth: '100%',
      }}
    >
      {integrationIcon(list, size, margin, color)}&nbsp;<span className="truncate">{list.name}</span>
    </Box>
  );
};
