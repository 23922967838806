import { Select } from '@mui/material';
import { styled } from '@mui/system';

export const BorderlessSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    opacity: 0.5,
  },
  '& .MuiSelect-select': {
    paddingTop: 10,
    paddingBottom: 10,
  },
});
