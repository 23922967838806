import React from 'react';
// import { useSelector } from 'react-redux';
import { Button, TextField, Autocomplete, styled, Tooltip } from '@mui/material';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { formatDuration } from '.';
import { theme } from '../../theme';
// import { ReduxState } from '../../reducers';
import {
  Task,
  // DurationPrediction
} from 'shared';
import { usePredictDuration } from '../../operations/ml';

const standardOptions = [];
for (let minuteInterval = 15; minuteInterval < 1440; minuteInterval += 15) {
  standardOptions.push(minuteInterval);
} // 15m intervals up to 23h 45m
standardOptions.unshift(1440);
for (let dayInterval = 1440; dayInterval < 44640; dayInterval += 1440) {
  standardOptions.push(dayInterval);
} // all-day durations

interface SelectDurationWithAutoCompleteProps {
  task: Task;
  listColor: string;
  onChange: (duration: number) => Promise<void>;
}

const SetDurationIcon = styled(TimelapseIcon)({
  color: '#aaa',
});

export const SelectDurationWithAutoComplete: React.FC<SelectDurationWithAutoCompleteProps> = React.memo(
  ({ task, listColor, onChange }) => {
    // const suggestedDurations = useSelector<ReduxState, DurationPrediction[]>(
    //   (state) => state.tasks.predictionsByTaskId[task.id] || []
    // );

    const { topPrediction, orderedSuggestedDurations } = usePredictDuration(task);

    //temp disable
    //    else {
    //     options.unshift(defaultTaskDuration); // todo: User's Default Task Duration
    //   }

    const uniqueOptionsSet = new Set(orderedSuggestedDurations.concat(standardOptions));
    // move top prediction to the top of the list
    uniqueOptionsSet.delete(topPrediction);
    let optionsArray = [topPrediction, ...Array.from(uniqueOptionsSet)];
    const options = optionsArray.filter((opt) => {
      if (typeof opt === 'number') {
        return opt;
      }
    }); // clears non-numbers, as well as dubs due to merging, otherwise causing unassigned key error

    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');
    const [mode, setMode] = React.useState('button');

    const handleSetDuration = (e: React.ChangeEvent, newValue: number) => {
      // setValue(newValue);
      setMode('button');
      onChange(newValue);
    };

    if (mode == 'button') {
      return (
        <Tooltip
          title="Set Duration"
          placement="top"
          disableInteractive
          // open={topPrediction ? false : undefined}
        >
          <Button
            onClick={(event) => {
              setMode('selector');
            }}
            sx={{
              whiteSpace: 'nowrap',
              textTransform: 'none',
              transitionDuration: 'initial',
              justifyContent: 'flex-end',
              minWidth: '36px',
              padding: '6px 14px',
              color: (theme: any) => (!task.duration ? theme.palette.faded.main : listColor),
            }}
            // color={!task.duration ? 'info' : 'primary'}
            className="task-duration-button"
          >
            {topPrediction ? formatDuration(topPrediction) : <SetDurationIcon />}
          </Button>
        </Tooltip>
      );
    }
    return (
      <Autocomplete
        // value={value}
        onChange={handleSetDuration}
        // inputValue={inputValue}
        // onInputChange={(e, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        size="small"
        openOnFocus
        autoHighlight
        selectOnFocus
        blurOnSelect
        onBlur={() => {
          setMode('button');
        }}
        disableClearable
        clearIcon={''}
        forcePopupIcon={false}
        className="duration-autocomplete"
        // freeSolo // allows any value to be accepted, but we would have to reverse formatDuration()
        sx={{
          minWidth: '120px',
          textAlign: 'center',
          '& .MuiInput-input': {
            textAlign: 'right',
          },
        }}
        options={options}
        getOptionLabel={(option) => formatDuration(option)}
        renderOption={(props, option) => {
          let title = formatDuration(option);
          let suggested = orderedSuggestedDurations.includes(option);
          return (
            <li
              {...props}
              style={{
                color: option === options[0] ? 'rgb(80,150,190)' : '',
                borderLeft: suggested ? '4px solid rgb(80,150,190)' : '4px solid transparent',
              }}
            >
              {title}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="standard"
            placeholder={topPrediction ? formatDuration(topPrediction) : undefined}
            InputProps={{
              ...params.InputProps,
              type: 'text',
              style: { fontSize: '15px', fontWeight: '500', color: 'rgb(80,150,190)' },
            }}
          />
        )}
      />
    );
  }
);
