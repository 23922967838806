import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  borderRadius: 'inherit',
  background: 'white',
  zIndex: 4, // 1 above inbox
});

const displayNoneStyle = { display: 'none' };

const StyledTitle = styled('h2')({
  position: 'relative',
  top: '40%',
  textAlign: 'center',
  color: '#a5a5a5',
});

export const PostponePlaceholder = () => (
  <StyledBox
    className="postpone-placeholder" // used for runtime manipulation
    style={displayNoneStyle}
  >
    <StyledTitle>Drop here to unschedule task</StyledTitle>
  </StyledBox>
);
