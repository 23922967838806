import ReactGA4 from 'react-ga4';
import { getUid, getUidWithPromise } from './operations/index';
import { localStorageGetItem, localStorageSetItem } from 'common/utils';
import TagManager from 'react-gtm-module';

let userId;
let GA4Enabled = false;
let GTMEnabled = false;

// store original referrer in local storage
let originalReferrer = localStorageGetItem('originalReferrer');
if (!originalReferrer) {
  originalReferrer = document.referrer;
  localStorageSetItem('originalReferrer', originalReferrer);
}

if (typeof process.env.REACT_APP_GOOGLE_TAG_MANAGER !== 'undefined') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  };
  TagManager.initialize(tagManagerArgs);
  console.log('Google Tag Manager Initialized');
  GTMEnabled = true;
}

if (typeof process.env.REACT_APP_GOOGLE_ANALYTICS_4_CODE !== 'undefined') {
  console.log('Google Analytics 4 tracking code:', process.env.REACT_APP_GOOGLE_ANALYTICS_4_CODE);
  ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_CODE, {
    send_page_view: false, // Disable automatic pageview hits to avoid dublicates
  });
  ReactGA4.set({ anonymizeIp: true });

  // if original referrer is missing and set it as GA referrer
  ReactGA4.set({ referrer: originalReferrer });

  GA4Enabled = true;
} else {
  console.log('Sending to Google Analytics 4 is disabled');
}

const withUserId = async (callback, ignoreErrors = false, uid = null) => {
  // TODO: Data Layer seems to persist, so we can set userId once, after having an actual user object, and not call it on every event.
  try {
    // Try to retrieve the anonymized userAnalyticsId from local storage
    let userAnalyticsId = localStorageGetItem('anonymizedUserId');
    // console.log('GA4 Analytics UID from local storage: ', userId);
    if (!userAnalyticsId) {
      // Get uid, anonymize and store in local storage
      const user_id = uid || (await getUidWithPromise()); // wait for user to be loaded in redux, else it returns null upon initial login
      console.log('GA4 UID retreived from redux: ', uid);
      if (user_id) {
        userAnalyticsId = await anonimizeClientId(user_id);
        // console.log('GA4 Annon Analytics UID: ', userAnalyticsId);
        localStorageGetItem('anonymizedUserId', userAnalyticsId);
      } else {
        console.log('Analytics: Unable to getUid');
        return;
      }
    }
    // Set the anonymized userAnalyticsId for ReactGA4
    ReactGA4.set({ userId: userAnalyticsId });
    // console.log('GA4 Analytics userId set: ', userAnalyticsId);
    callback();
  } catch (err) {
    if (ignoreErrors) {
      callback();
    } else {
      console.log('GA4 Unable to get or anonymize uid', err);
    }
  }
};

const formatForGA4 = (opts) => {
  // Example IN: { category: 'Auth', action: 'Failed to Sign Out', label: err.toString() };
  // Example OUT: { name: 'auth__failed_to_sign_out', params: { category: 'auth', label: err.toString() }
  const { category, action, label } = opts;

  // console.log('Test Analytics IN: ', category, action, label);

  const formatString = (str) => {
    return str ? str.replace(/\s+/g, '_').toLowerCase() : null;
  };

  const formattedCategory = formatString(category);
  const formattedAction = formatString(action) || '';
  const eventName = formattedCategory + '__' + formattedAction;

  // console.log('Test Analytics OUT: ', eventName);

  return {
    name: eventName || 'undefined',
    params: (() => {
      const paramsObj = {};
      if (formattedCategory) {
        paramsObj.category = formattedCategory;
      }
      if (formattedAction) {
        paramsObj.action = formattedAction;
      }
      if (label) {
        paramsObj.label = label;
      }
      return paramsObj;
    })(),
  };
};

export default class Analytics {
  static event(opts) {
    const GA4opts = formatForGA4(opts);
    console.log('Analytics event', JSON.stringify(GA4opts));
    if (GA4Enabled) withUserId(() => ReactGA4.event(GA4opts.name, GA4opts.params), true);
  }
  static tagManagerEvent(opts) {
    if (GTMEnabled)
      withUserId(() => {
        TagManager.dataLayer({ dataLayer: opts });
      }, true);
  }
  static set(opts) {
    GA4Enabled && ReactGA4.set(opts);
  }
  static pageview(page, uid) {
    // we intentionally pass uid as a prop, since it's called before a user is loaded via getUid()
    console.log('Analytics pageview', page);
    if (GA4Enabled) withUserId(() => ReactGA4.send({ hitType: 'pageview', page: page, title: page }), true, uid);
  }
}

// Google Analytics User ID - Best practices - https://support.google.com/analytics/answer/9213390?hl=en

const anonimizeClientId = async (userId) => {
  try {
    // should be supported by modern browsers
    const msgBuffer = new TextEncoder().encode(userId);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    // console.log('Analytics sha256', hashHex)
    return hashHex;
  } catch (err) {
    // older browsers
    const med = Buffer.from(userId).toString('base64');
    return Buffer.from(med).toString('base64');
  }
};
