const extensionsRegistry = {
  ms_todo: {
    id: 'ms_todo',
    name: 'Microsoft To Do (beta)',
    kind: 'tasks',
    logo: '/images/ms_todo.svg',
    capabilities: {
      move_task: false,
      webhook: true,
    },
  },
};

export const getExtensionsRegistry = () => extensionsRegistry;
