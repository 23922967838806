import { Button } from '@mui/material';
import { clearDueDate } from 'api/todoist';
import { fbOps } from 'common/firebase';
import { Notification } from 'components';
import { logError } from 'common/utils';
import { getUid, store } from 'operations';
import { FC, useState } from 'react';
import { Task } from 'shared';

interface ModalErrorPerformingTaskEventOperationProps {
  task: Task;
  operation?: string;
}

const ModalErrorPerformingTaskEventOperation: FC<ModalErrorPerformingTaskEventOperationProps> = ({
  task,
  operation,
}) => {
  const [actionPerformed, setActionPerformed] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const uid = getUid();

  const supportEmailTitle = `Support Request - Error with task ${operation} - ${uid}/${task.id}`;
  const body = `Hi Trevor Support,

Please check the task with ID ${task.id} for user ${uid}.
    `;

  const handleClearAssociation = async () => {
    try {
      setInProgress(true);
      await fbOps.breakAllEventAssociationsByTaskId(uid, task.id);

      if (task.todoist?.due?.date && !task.todoist?.due.is_recurring) {
        const todoistAccessToken = store().getState().account.user?.accounts?.todoist?.accessToken;
        const syncDateToTodoist = store().getState().account.user?.accounts?.todoist?.syncDateToTodoist;
        if (!todoistAccessToken || !syncDateToTodoist) {
          return;
        }
        return clearDueDate(todoistAccessToken, task.todoist.id).catch((err) => {
          logError(err, { hint: 'Failed to clear Todoist due date after breaking all event associations' });
        });
      }

      setActionPerformed(true);
    } catch (error) {
      setError(error);
      logError(error, { hint: 'Failed to break all event associations' });
    }
  };

  return (
    <div>
      <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
        There was an error performing the operation with this task :-(
      </div>
      <br />

      <p>
        The corresponding calendar event is not found. It may have been deleted in the past or the event recurrence has
        ended. It happens and sometimes we're unable to detect it.
      </p>
      <br />
      <p>
        You can <strong>clear</strong> the event association - it will be a regular unscheduled task and you can try
        again the task {operation} after that.
      </p>
      <br />
      <p>
        You can also contact us on{' '}
        <a
          href={`mailto:support@trevorlabs.com?subject=${encodeURIComponent(
            supportEmailTitle
          )}&body=${encodeURIComponent(body)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          support@trevorlabs.com
        </a>{' '}
        to report and get help with this.
      </p>
      <br />

      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" disabled={actionPerformed || inProgress} onClick={handleClearAssociation}>
          {actionPerformed ? 'Done' : 'Clear event association'}
        </Button>
      </div>
      <br />

      {actionPerformed && (
        <div>Event association was cleared. You can now close this dialog and try again the task {operation}.</div>
      )}

      {error && <div>{error.message}</div>}
    </div>
  );
};

export const showModalErrorPerformingTaskEventOperation = ({
  task,
  operation,
}: ModalErrorPerformingTaskEventOperationProps) => {
  Notification.showModal(<ModalErrorPerformingTaskEventOperation task={task} operation={operation} />);
};
