import { store as reduxStore } from '../reducers';

export const store = () => reduxStore;

export const getUid = () => {
  const { user } = store().getState().account;
  return user ? user.uid : null;
};

export const getUidWithPromise = (maxRetries = 10) => {
  let retries = 0;
  return new Promise((resolve, reject) => {
    const checkState = () => {
      const { user } = store().getState().account;
      if (user && user.uid) {
        resolve(user.uid);
      } else if (retries < maxRetries) {
        retries++;
        setTimeout(checkState, 100);
      } else {
        reject(new Error('User UID not found within the allowed retries.'));
      }
    };
    checkState();
  });
};
