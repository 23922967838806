import React from 'react';
import { TouchmoveAfterLongPress } from './TouchmoveAfterLongPress';
import '@fullcalendar/core';
import { Draggable as FCDraggable } from '@fullcalendar/interaction';

export function simulateMouseEvent(event, simulatedType) {
  // Ignore multi-touch events
  if (event.touches.length > 1) {
    return;
  }

  event.preventDefault();

  var touch = event.changedTouches[0],
    simulatedEvent = document.createEvent('MouseEvents');

  // Initialize the simulated mouse event using the touch event's coordinates
  simulatedEvent.initMouseEvent(
    simulatedType, // type
    true, // bubbles
    true, // cancelable
    window, // view
    1, // detail
    touch.screenX, // screenX
    touch.screenY, // screenY
    touch.clientX, // clientX
    touch.clientY, // clientY
    false, // ctrlKey
    false, // altKey
    false, // shiftKey
    false, // metaKey
    0, // button
    null // relatedTarget
  );

  // Dispatch the simulated event to the target element
  event.target.dispatchEvent(simulatedEvent);
}

export class Draggable extends React.PureComponent {
  componentDidMount() {
    // let element = $(this.refs.container); //$('#task' + this.props.index)
    let element = this.refs.container;
    this.__dd = new FCDraggable(element, {
      eventData: () => this.props.data,
      // delay: 100,
      // distance: 5,
      // scroll: false,
      // cursor: 'move',
      // cursorAt: { left: 150, top: 6 },
      // appendTo: 'body',
      // zIndex: 9999,
    });

    // this.__dd = element.draggable({
    //   containment: 'body',
    //   scroll: false,
    //   cursor: 'move',
    //   cursorAt: { left: 150, top: 6 },
    //   appendTo: 'body',
    //   helper: () => {
    //     let { data, dataProp, denyDrag, dragClass, onDrag } = this.props;
    //     var copy = element.clone();
    //     if (dragClass) copy.addClass(dragClass);
    //     if (!denyDrag && dataProp) {
    //       element.data(dataProp, data);
    //     }
    //     if (onDrag) onDrag();
    //     return copy;
    //   },
    //   drag: () => {
    //     let { denyDrag, onDragDenied } = this.props;
    //     if (denyDrag) {
    //       element.effect('shake', { distance: 5 });
    //       if (onDragDenied) onDragDenied();
    //       return false;
    //     }
    //   },
    // });
  }
  componentWillUnmount() {
    // breaks dragging task on mobile
    // this.__dd?.destroy();
  }
  render() {
    let { onTouchDrag, onTouchDragStop } = this.props;
    return (
      <TouchmoveAfterLongPress
        onDragStart={() => onTouchDrag && onTouchDrag()}
        onDragStop={() => onTouchDragStop && onTouchDragStop()}
      >
        <div ref="container">{this.props.children}</div>
      </TouchmoveAfterLongPress>
    );
  }
}
