import React from 'react';
import { connect } from 'react-redux';
import * as utils from 'common/utils';
import Analytics from '../../analytics.jsx';
import { Notification } from '../../components';
import {
  Button,
  Box,
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import ActionDelete from '@mui/icons-material/Delete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { shareList, removeShareList } from '../../api';
import { getRecaptchaToken } from 'common/utils';

const scriptjs = require('scriptjs');

const LabelBadge = ({ title }) => {
  let style = {
    padding: 5,
    margin: 10,
    fontSize: 'small',
    color: 'white',
    backgroundColor: utils.defaultColor,
    borderRadius: 3,
  };
  return <span style={style}>{title}</span>;
};

class ShareWithPeopleDialog extends React.Component {
  constructor(props) {
    super(props);
    // const { list } = props
    this.state = {
      close: false,
      input: '',
      error: null,
    };
  }

  componentDidMount() {
    scriptjs('https://www.google.com/recaptcha/api.js?render=6LdNGWoUAAAAAC8NOhWtNjkE6R8YH1OmVKIt1HbL');
  }

  render() {
    let { list, user, onClose, onShare, onShareRemove } = this.props;
    let { close, input, error } = this.state;
    let members = list.shared ? list.shared.members : [];

    const onAdd = (e) => {
      e.preventDefault();
      if (input === user.email) {
        this.setState({ error: 'Your email is already added' });
        return;
      } else if (members.find((cc) => cc.email === input)) {
        this.setState({ error: 'This email is already added' });
        return;
      }
      // this.setState({input: ''})
      // onShare(list, input)
      getRecaptchaToken((token) => {
        this.setState({ input: '' });
        onShare(list, input, token);
      });
    };
    const onRemove = (email) => {
      if (window.confirm('Are you sure you want to remove ' + email)) {
        onShareRemove(list, email);
      }
    };

    return (
      <Dialog className="shared-dialog" open={!close && !!list} onClose={onClose}>
        <DialogTitle sx={{ color: list.color }}>{'Share #' + list.name}</DialogTitle>
        <DialogContent>
          <p style={{ marginTop: 0 }}>
            Invite others to collaborate on this list. Each collaborator will be able to view, edit, schedule and
            complete tasks from this list.
          </p>
          <form onSubmit={onAdd} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
              <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                fullWidth
                required
                type="email"
                label="email"
                variant="standard"
                name="share-email"
                error={error ? true : false}
                value={input}
                helperText={error}
                onChange={(e) => this.setState({ input: e.target.value, error: null })}
                style={{
                  maxWidth: '70%',
                }}
              />
              <Button variant="contained" disabled={!input} type="submit">
                Send Invite
              </Button>
            </Box>
          </form>
          <div>
            <List>
              {members.map((pp) => {
                let badge;
                if (pp.owner) badge = <LabelBadge title="owner" />;
                if (pp.pending) badge = <LabelBadge title="pending" />;
                return (
                  <ListItem
                    sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}
                    key={pp.email}
                    disabled={pp.email === user.email || !!pp.owner}
                  >
                    <ListItemText sx={{ width: '90%', marginLeft: '16px' }}>
                      <span>
                        {pp.email}
                        {badge}
                      </span>
                    </ListItemText>
                    <ListItemButton>
                      {!pp.owner ? <ActionDelete onClick={() => onRemove(pp.email)} /> : undefined}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="text" onClick={() => onClose()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  (state, ownProps) => {
    let { listId } = ownProps;
    return {
      list: state.tasks.listsObj[listId],
      user: state.account.user,
    };
  },
  (dispatch, ownProps) => {
    return {
      onShare: async (list, email, recaptchaToken) => {
        try {
          await shareList(list.id, list.name, list.color, email, recaptchaToken);
          Notification.show('Invited collaborator');
          Analytics.event({ category: 'List', action: 'Invited Collaborator' });
          // return true
        } catch (err) {
          Notification.showError('Failed to invite collaborator');
          Analytics.event({ category: 'List', action: 'Failed to Invite Collaborator' });
        }
      },
      onShareRemove: async (list, email) => {
        try {
          await removeShareList(list.id, email);
          Notification.show('Collaborator removed');
          Analytics.event({ category: 'List', action: 'Removed Collaborator' });
          // return true
        } catch (err) {
          Notification.showError('Failed to remove collaborator');
          Analytics.event({ category: 'List', action: 'Failed to Remove Collaborator' });
        }
      },
      onClose: ownProps.onClose,
    };
  }
)(ShareWithPeopleDialog);
