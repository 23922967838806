import { delay, eventChannel } from 'redux-saga';
import { takeEvery, take, put, fork, cancel, select, cancelled } from 'redux-saga/effects';
import { LOAD_EXTENSION, UNLOAD_EXTENSION, extensionDataLoaded, STORE_EXTENSION_DATA } from '../actions/extensions';
import { getDatabase, ref, off, onValue, set } from 'firebase/database';

const db = getDatabase();

function* fetchUid() {
  let user = yield select((state) => state.account.user);
  let { uid } = user;
  if (!uid) throw new Error('User UID is not found. Really weird');
  return uid;
}

function* extensionThread(extension) {
  console.log('!!! ext thread started', extension);
  const uid = yield fetchUid();
  const channel = yield eventChannel((emit) => {
    console.log('Subscribe to', `extensions/${uid}/${extension}`);
    let extRef = ref(db, `extensions/${uid}/${extension}`);

    setTimeout(
      () =>
        onValue(extRef, (snap) => {
          console.log('ext data', snap.val());
          emit({ data: snap.val() });
        }),
      0
    );

    return () => off(extRef);
  });

  try {
    while (true) {
      console.log('Wait for channel data', extension);
      let { data } = yield take(channel);
      console.log('data from channel, dispatch as action', data);
      yield put(extensionDataLoaded(extension, data));
    }
  } finally {
    if (yield cancelled()) console.log('ext thread stopped', extension);
  }
}

const threads = {};

function* load(action) {
  const { extension } = action;
  let th = yield fork(extensionThread, extension);
  threads[extension] = th;
}
function* unload(action) {
  const { extension } = action;
  yield cancel(threads[extension]);
  delete threads[extension];
}
function* storeExtensionData(action) {
  let { extension, data } = action;
  const uid = yield fetchUid();

  yield set(ref(db, `extensions/${uid}/${extension}`), data);
}

function* extensionsSaga() {
  yield takeEvery(LOAD_EXTENSION, load);
  yield takeEvery(UNLOAD_EXTENSION, unload);
  yield takeEvery(STORE_EXTENSION_DATA, storeExtensionData);
}

export default extensionsSaga;
