import React, { useState } from 'react';
import { IconButton, MenuItem, Menu, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

// const DragHandle = ({handleClass}) => {
// 	return <i className={`material-icons ${handleClass}`}>reorder</i>
// }
export const ListOptions = ({ onExpandAllNotes, onCollapseAllNotes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (callback) => () => {
    setAnchorEl(null);
    callback();
  };
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.target)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClick(onExpandAllNotes)}>
          <ListItemIcon>{<UnfoldMoreIcon />}</ListItemIcon>Expand All Notes
        </MenuItem>
        <MenuItem onClick={handleClick(onCollapseAllNotes)}>
          <ListItemIcon>{<UnfoldLessIcon />}</ListItemIcon>Collapse All Notes
        </MenuItem>
      </Menu>
    </>
  );
};
