import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Analytics from '../analytics.jsx';
import { ListItemIcon, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export default class TermsOfService extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggle: props.open || false,
      openDialog: () => this.setState({ toggle: true }),
    };
  }

  handleOpen = () => {
    this.setState({ toggle: true });
  };

  handleClose = () => {
    this.setState({ toggle: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    let { open } = this.props;

    let button;
    if (this.props.link) {
      button = (
        <a
          href="#"
          onClick={() => {
            this.handleOpen();
            Analytics.event({ category: 'Navbar', action: 'Clicked Menu > Terms of Service' });
          }}
        >
          Terms of Service
        </a>
      );
    } else if (open) {
      button = '';
    } else {
      button = (
        <MenuItem
          onClick={() => {
            this.handleOpen();
            Analytics.event({ category: 'Navbar', action: 'Clicked Menu > Terms of Service' });
          }}
        >
          <ListItemIcon />
          Terms of Service
        </MenuItem>
      );
    }

    return (
      <span className="TOS">
        {button}
        <Dialog
          open={this.state.toggle}
          onClose={open ? null : this.handleClose}
          scroll="paper"
          fullWidth
          onClick={(e) => e.preventDefault}
        >
          <DialogTitle>Terms Of Service for Trevor A.I.</DialogTitle>
          <DialogContent>
            <h1>Trevor Labs Terms of Service</h1>
            <p>
              <b>Effective Date:</b> May 25, 2018
            </p>

            <h3>Welcome to Trevor A.I.</h3>
            <p>
              Welcome to Trevor A.I.! Trevor A.I. service (“Service”) is provided by Trevor Labs Ltd. (“Trevor Labs”).
              By using our Service and this website, you are agreeing to these Terms of Service (“Terms”), all
              applicable laws and regulations, and agree that you are responsible for compliance with any applicable
              local laws. If you are using the Service on behalf of an organization or entity (“Organization”), then you
              are agreeing to these Terms on behalf of the Organization. In that case, “you” and “your” may refer to the
              Organization. If you do not agree with any of these terms, you are prohibited from using or accessing this
              site. The materials contained in this web site are protected by applicable copyright and trade mark law.
            </p>
            <h3>Amendments to the Terms of Service</h3>
            <p>
              Trevor Labs may modify existing terms and/or update these terms with additional terms that apply to the
              Service. For example, mandatory changes to the law may be reflected in the existing terms. Mandatory
              changes to the law and changes addressing new functions for the Service are effective immediately. You are
              responsible for checking the terms regularly to stay informed. Trevor Labs may or may not also post notice
              of modification to these Terms on this page or via the email address you registered with us. You should
              discontinue your use of our Service if you do not agree with updated/modified Terms.
            </p>

            <h3>Your Content in Our Service</h3>
            <p>
              Our Service allows you to upload, download and store content, including but not limited to, information,
              text, graphics, videos, audios, or other material (“Content”). You retain ownership of any intellectual
              property rights that you hold in that Content. By uploading, storing, sending or receiving Content to or
              through our Service, you give Trevor Labs and its service partners a worldwide license to host, store,
              upload and download this Content and only for the purpose of providing Service strictly to you and your
              use of the Content. We reserve our right at all times, but are not obligated, to remove or refuse to
              distribute any Content through the Service including your Content.
            </p>

            <h3>Privacy Protection</h3>
            <p>
              Your privacy is very important to us. Accordingly we have developed our Privacy Policy in order for you to
              understand how we collect, use, communicate and disclose and make use of personal information. By using
              our Service, you automatically agree to our privacy policies and Trevor Labs can use such data in
              accordance with its privacy policies.
            </p>

            <h3>Software in Our Service</h3>
            <p>
              When the Service requires or includes downloadable software, this software may update automatically on
              your device once a new version or features become available to you. Some platforms may let you adjust your
              automatic update settings.
            </p>
            <p>
              Trevor Labs gives you a worldwide, royalty-free, personal, non-assignable and non-exclusive license to use
              the software provided by Trevor Labs as part of the Service. You are prohibited from copying, modifying,
              distributing, selling, or leasing any part of our Service or included software, nor are you allowed to
              reverse engineer or attempt to extract the source code of the software, unless local laws prohibit those
              restrictions or you have our written permission.
            </p>

            <h3>Modifying and Terminating Our Service</h3>
            <p>
              Trevor Labs aims to provide new functionalities or features and improve existing ones for the purpose of
              improving, changing, and/or updating the Service. Trevor Labs also retains the right to suspend or stop
              the Service with at least thirty (30) days prior notice to the complete shutdown of the Service.
            </p>
            <p>
              You can choose to stop using our Service at will. We may also stop providing Service to you, or add or
              create new limitations to our Service at any time as detailed in the Terms.
            </p>

            <h3>Using Our Service</h3>
            <p>
              You must follow any policies made available to your within the Service. You may only use our Service as
              permitted by law. Trevor Labs may investigate and/or suspend or terminate our Service to you at any time
              if we find your use of our Service violates the Terms and/or any policies.
            </p>
            <p>
              Using our Service does not grant you ownership of any intellectual property rights in our Service or the
              content you may have access to. You may not use any copyrighted content in our Service unless you obtain
              permission from the content owner and/or are otherwise permitted by law. The Terms do not grant you the
              right to use any branding or logos used in our Service. Our Service may display some logos, trademarks, or
              branding materials that is not the property of Trevor Labs. These types of content are the sole
              responsibility of the entity that makes it available.
            </p>
            <p>
              You must not abuse and/or misuse our Service, including but not limited to, doing the following things:
            </p>
            <p>
              - Using the Service for any unlawful purposes or activities;
              <br />
              - Interfering with or disrupting the access of any user, host, or network;
              <br />
              - Accessing or tampering with the Service’s server systems;
              <br />
              - Sending unsolicited promotions or advertisements;
              <br />
              - Abusing or submitting excessively frequent requests to the Service via the API.
              <br />
              - Uploading any content to the Service in violation of any applicable law, including but not limited to,
              intellectual property laws and publicity laws;
              <br />
              - Trevor Labs, in its sole discretion, will determine abuse and/or misuse of our Service.
              <br />
            </p>

            <h3>Limited Liability for Our Service</h3>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE IS AVAILABLE “AS IS”. YOU EXPRESSLY UNDERSTAND AND
              AGREE THAT:
              <br />
              <br />
              WHEN PERMITTED BY LAW, TREVOR LABS AND ITS SERVICE PARTNERS, LICENSORS, EMPLOYEES, AGENTS WILL NOT BE
              RESPONSIBLE FOR ANY LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL,
              EXEMPLARY, OR PUNITIVE DAMAGES UNDER ANY CIRCUMSTANCES.
              <br />
              <br />
              YOUR USE AND/OR PURCHASE OF SERVICE ARE ALL AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS”
              BASIS.
              <br />
              <br />
              TREVOR LABS DOES NOT WARRANT THAT:
              <br />
              <br />
              THE SERVICE WILL MEET ALL OF YOUR REQUIREMENTS AT ALL TIMES;
              <br />
              THE SERVICE WILL BE ERROR-FREE AND ALL ERRORS IN THE SERVICE WILL BE CORRECTED;
              <br />
              THE SERVICE WILL BE UNINTERRUPTED.
              <br />
              ALL CONTENT DOWNLOADED, UPLOADED AND/OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR
              OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER EQUIPMENT OR
              DEVICES, INCLUDING BUT NOT LIMITED TO, LAPTOP COMPUTERS, DESKTOP COMPUTER, TABLETS, SMARTPHONES AND
              SMARTWATCHES, OR ANY DATA LOSS RESULTING FROM DOWNLOAD OR USE OF ANY SUCH ABOVE MENTIONED MATERIAL.
            </p>
            <p>
              Trevor Labs, ltd. has not reviewed all of the sites linked to its Internet web site and is not responsible
              for the contents of any such linked site. The inclusion of any link does not imply endorsement by Trevor
              Labs, ltd. of the site. Use of any such linked web site is at the user&#39;s own risk.
            </p>
            <p>
              The materials appearing on Trevor Labs&#39; web site could include technical, typographical, or
              photographic errors. Trevor Labs does not warrant that any of the materials on its web site are accurate,
              complete, or current. Trevor Labs may make changes to the materials contained on its web site at any time
              without notice. Trevor Labs does not, however, make any commitment to update the materials.
            </p>

            <h3>Information gathering and usage</h3>
            <p>
              When registering for Trevor A.I. we ask for information such as your name, email address and other
              information provided by the Google Account you signed in with. Information collected during sign up is
              displayed in the Google Sign In panel, or page, as well as in our privacy policy. Your information is used
              for the purpose of providing a personalized experience.
            </p>

            <h3>Governing Law</h3>
            <p>
              Any claim relating to Trevor Labs&#39; web site shall be governed by the laws of the State of Sofia,
              Bulgaria, 1164 without regard to its conflict of law provisions.
            </p>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.handleClose}>
              Agree and Close
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

export class IubendaPolicy extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggle: props.open || false,
      openDialog: () => this.setState({ toggle: true }),
      iubendaLink: 'https://www.iubenda.com/privacy-policy/7790939',
    };
  }

  handleOpen = (policy) => {
    this.setState({ toggle: true });
    console.log('Policy handleOpen, ', policy);
    if (policy === 'cookie') {
      this.setState({ iubendaLink: 'https://www.iubenda.com/privacy-policy/7790939/cookie-policy' });
    }
  };

  handleClose = () => {
    this.setState({ toggle: false });
    this.props.onClose && this.props.onClose();
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    let { open, policy } = this.props;

    if (!policy) {
      policy = 'privacy';
    }

    let button;
    if (this.props.link) {
      button = (
        <a
          href="#"
          onClick={() => {
            this.handleOpen(policy);
            Analytics.event({ category: 'Navbar', action: `Clicked Menu > ${this.capitalize(policy)} Policy` });
          }}
        >
          {this.capitalize(policy)} Policy
        </a>
      );
    } else if (open) {
      button = '';
    } else {
      button = (
        <MenuItem
          onClick={() => {
            this.handleOpen(policy);
            Analytics.event({ category: 'Navbar', action: `Clicked Menu > ${this.capitalize(policy)} Policy` });
          }}
        >
          <ListItemIcon />
          {this.capitalize(policy)} Policy
        </MenuItem>
      );
    }

    return (
      <span className="TOS">
        {button}
        <Dialog
          open={this.state.toggle}
          onClose={open ? null : this.handleClose}
          scroll="paper"
          fullWidth
          onClick={(e) => e.preventDefault}
        >
          <DialogContent>
            <iframe
              height="100%"
              width="100%"
              style={{ border: 'none', height: '70vh' }}
              src={this.state.iubendaLink}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.handleClose}>
              Agree and Close
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}
