import React, { FC } from 'react';
import { BorderlessSelect } from 'components/BorderlessSelect';
import { useSelector } from 'react-redux';
import { getEnabledCalendars } from 'reducers/calendar';
import { Divider, MenuItem, ListItemIcon, ListItemText, Tooltip, IconButton, Menu } from '@mui/material';
import { detectOrigin } from 'common/utils';
import GoogleIcon from '@mui/icons-material/Google';
import { getExtensionsRegistry } from 'shared';
import { Clear, Event, CalendarToday } from '@mui/icons-material';

const defaultColor = 'gray';

export const integrationIcon = (item, size = '24px') => {
  let { origin, id } = item;

  if (!origin) {
    origin = detectOrigin(id);
  }

  let url = 'images/trevor_shape.png';
  let moreStyles = {};

  if (origin === 'google') {
    return <GoogleIcon color="primary" />;
  } else if (origin === 'microsoft') {
    url = 'images/outlook.svg';
  } else if (origin !== 'trevorai') {
    const extensionsRegistry = getExtensionsRegistry();
    const ext = extensionsRegistry[origin];
    if (ext) {
      url = ext.logo;
    }
  } else {
    // trevorai
    moreStyles = { background: defaultColor, borderRadius: '50%' };
  }

  return (
    <img alt={`${origin} logo`} src={url} className="svg-icon" style={{ width: size, height: size, ...moreStyles }} />
  );
};

interface EnabledCalendarsDropdownProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  flexGrow?: number;
  rightAction?: JSX.Element;
  tooltip?: string;
}

interface EnabledCalendarsButtonProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  flexGrow?: number;
  rightAction?: JSX.Element;
  tooltip?: string;
}

export const EnabledCalendarsDropdown: FC<EnabledCalendarsDropdownProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  flexGrow,
  rightAction,
  tooltip,
}) => {
  const enabledCalendars = useSelector(getEnabledCalendars);

  const items = [];

  enabledCalendars.forEach((cal, idx) => {
    if (cal.integrationId !== enabledCalendars[idx - 1]?.integrationId) {
      items.push(<Divider key={'divider' + idx} />);
    }

    const icon = integrationIcon(cal);
    const color = cal.color;

    items.push(
      <MenuItem key={cal.id} value={cal.id} style={{ color }}>
        <ListItemIcon sx={{ opacity: 0.5 }}>{icon}</ListItemIcon>
        <ListItemText>{cal.title}</ListItemText>
        {rightAction}
      </MenuItem>
    );
  });

  const handleSelect = (e) => {
    onSelectItem(e.target.value || '');
  };

  const selectedCalendar = enabledCalendars.find((l) => l?.id === selectedItemId);
  const color = selectedCalendar?.color || defaultColor;

  return (
    <BorderlessSelect
      variant="outlined"
      displayEmpty
      style={{
        color,
      }}
      sx={{
        height: '48px',
        width: '100%',
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': { textDecoration: 'underline' },
      }}
      value={selectedItemId || ''}
      // renderValue={(val) => (val && enabledCalendars.find((l) => l?.id === val)?.title) || placeholder}
      renderValue={(val) => (
        <Tooltip title={tooltip}>
          <div
            className="truncate"
            style={{ color, display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9em' }}
          >
            {selectedCalendar ? (
              <>
                {integrationIcon(selectedCalendar)}
                {selectedCalendar.title}
              </>
            ) : (
              <>
                {/* <Event /> */}
                {placeholder}
              </>
            )}
          </div>
        </Tooltip>
      )}
      onChange={handleSelect}
      className="add-new-task-panel-lists"
    >
      {!!selectedItemId && (
        <MenuItem style={{ color: defaultColor }}>
          <ListItemIcon>
            <Clear />
          </ListItemIcon>
          <ListItemText>Clear</ListItemText>
        </MenuItem>
      )}
      {!!selectedItemId && <Divider />}
      {items}
    </BorderlessSelect>
  );
};

export const EnabledCalendarsButton: FC<EnabledCalendarsButtonProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  tooltip,
}) => {
  const enabledCalendars = useSelector(getEnabledCalendars);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => {
    onSelectItem(id);
    handleClose();
  };

  const selectedCalendar = enabledCalendars.find((cal) => cal.id === selectedItemId);
  const color = selectedCalendar?.color || defaultColor;

  const items = enabledCalendars.map((cal, idx) => (
    <React.Fragment key={cal.id}>
      {cal.integrationId !== enabledCalendars[idx - 1]?.integrationId && <Divider />}
      <MenuItem
        onClick={() => handleSelect(cal.id)}
        sx={{
          color: cal.color,
          backgroundColor: cal.id === selectedItemId ? 'primary.highlight' : 'none',
        }}
      >
        <ListItemIcon sx={{ opacity: 0.5 }}>{integrationIcon(cal)}</ListItemIcon>
        <ListItemText>{cal.title}</ListItemText>
      </MenuItem>
    </React.Fragment>
  ));

  return (
    <div>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleClick} style={{ color }}>
          <CalendarToday />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!!selectedItemId && (
          <MenuItem onClick={() => handleSelect('')} style={{ color: defaultColor }}>
            <ListItemIcon>
              <Clear />
            </ListItemIcon>
            <ListItemText>Clear</ListItemText>
          </MenuItem>
        )}
        {!!selectedItemId && <Divider />}
        {items}
      </Menu>
    </div>
  );
};
