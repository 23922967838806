// export const SELECT_EVENT = 'SELECT_EVENT';
// export const selectEvent = (eventId) => {
//     return {
//         type: SELECT_EVENT,
//         eventId
//     }
// }

export const SHOW_LISTS_SETTINGS = 'SHOW_LISTS_SETTINGS';
export const showListsSettings = () => {
  return {
    type: SHOW_LISTS_SETTINGS,
  };
};

export const SHOW_INBOX = 'SHOW_INBOX';
export const showInbox = () => {
  return {
    type: SHOW_INBOX,
  };
};

export const SELECT_LIST = 'SELECT_LIST';
export const selectList = (listId) => {
  return {
    type: SELECT_LIST,
    listId,
  };
};

export const SELECT_LIST_NONE = 'SELECT_LIST_NONE';
export const selectListNone = () => {
  return {
    type: SELECT_LIST_NONE,
  };
};

export const SET_SHOW_SUGGESTIONS_FOR_TASK_ID = 'SET_SHOW_SUGGESTION_FOR_TASK_ID';
export const setShowSuggestionsForTaskId = (taskId, forceShowTask = false) => {
  return {
    type: SET_SHOW_SUGGESTIONS_FOR_TASK_ID,
    taskId,
    forceShowTask,
  };
};

export const CLEAR_SHOW_SUGGESTIONS_FOR_TASK_ID = 'CLEAR_SHOW_SUGGESTION_FOR_TASK_ID';
export const clearShowSuggestionsForTaskId = (taskId) => {
  return {
    type: CLEAR_SHOW_SUGGESTIONS_FOR_TASK_ID,
    taskId,
  };
};

export const SNOOZE_OVERDUE_SUGGESTIONS_UNTIL = 'SNOOZE_OVERDUE_SUGGESTIONS_UNTIL';
export const snoozeOverdueSuggestionsUntil = (snoozeOverdueSuggestionsUntil) => {
  return {
    type: SNOOZE_OVERDUE_SUGGESTIONS_UNTIL,
    snoozeOverdueSuggestionsUntil,
  };
};

export const CHANGE_ACTIVE_VIEW = 'CHANGE_ACTIVE_VIEW';
export const changeActiveView = (view) => {
  return {
    type: CHANGE_ACTIVE_VIEW,
    view,
  };
};

export const CHANGE_TASKS_TAB = 'CHANGE_TASKS_TAB';
export const changeTasksTab = (tab) => ({
  type: CHANGE_TASKS_TAB,
  tab,
});

export const SEARCH_MODE_ON = 'SEARCH_MODE_ON';
export const searchModeOn = () => ({
  type: SEARCH_MODE_ON,
});
export const SEARCH_MODE_OFF = 'SEARCH_MODE_OFF';
export const searchModeOff = () => ({
  type: SEARCH_MODE_OFF,
});

export const SEARCH_STRING_CHANGE = 'SEARCH_STRING_CHANGE';
export const searchStringChange = (searchString) => ({
  type: SEARCH_STRING_CHANGE,
  searchString,
});

export const FILTER_COMPLETED_ON = 'FILTER_COMPLETED_ON';
export const filterCompletedOn = () => ({ type: FILTER_COMPLETED_ON });

export const FILTER_COMPLETED_OFF = 'FILTER_COMPLETED_OFF';
export const filterCompletedOff = () => ({ type: FILTER_COMPLETED_OFF });

export const FILTER_SCHEDULED_ON = 'FILTER_SCHEDULED_ON';
export const filterScheduledOn = () => ({ type: FILTER_SCHEDULED_ON });
export const FILTER_SCHEDULED_OFF = 'FILTER_SCHEDULED_OFF';
export const filterScheduledOff = () => ({ type: FILTER_SCHEDULED_OFF });

export const TOGGLE_FILTER_USER_TASKS = 'TOGGLE_FILTER_USER_TASKS';
export const toggleFilterUserTasks = () => ({ type: TOGGLE_FILTER_USER_TASKS });

export const FILTER_OVERDUE_ON = 'FILTER_OVERDUE_ON';
export const filterOverdueOn = () => ({ type: FILTER_OVERDUE_ON });
export const FILTER_OVERDUE_OFF = 'FILTER_OVERDUE_OFF';
export const filterOverdueOff = () => ({ type: FILTER_OVERDUE_OFF });

export const SET_CURRENT_VIEW_TASKS_COUNTS = 'SET_CURRENT_VIEW_TASKS_COUNTS';
export const setCurrentViewTasksCounts = (overdueTasksCount, autoSuggestionsSourceTasksCount) => ({
  type: SET_CURRENT_VIEW_TASKS_COUNTS,
  overdueTasksCount,
  autoSuggestionsSourceTasksCount,
});
