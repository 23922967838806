import { localStorageGetItem } from 'common/utils';
import { ajax_post, ajax_patch } from './util';

export const exportData = async () => {
  window.location.href =
    '/api/account/export?new_access_token=' +
    encodeURIComponent(localStorageGetItem('accessToken', sessionStorage.getItem('accessToken')));
};
export const deleteAccount = async (reason = '') => {
  await ajax_post('/api/account/delete', { reason });
};

export const acceptTerms = async (data) => {
  await ajax_post('/api/account/accept_terms', data);
};
export const saveEmailPrefs = async (data) => {
  await ajax_post('/api/account/email_prefs', data);
};
export const savePrefs = async (data) => {
  await ajax_patch('/api/account/prefs', data);
};
export const saveModule = async (id, active) => {
  await ajax_post('/api/account/module', { id, active });
};

export const checkEmailVerified = async () => {
  await ajax_post('/api/account/check_email_verified', '');
};

export const attachGoogleAccount = async (tokens) => {
  await ajax_post('/api/account/attach_google_account', tokens);
};
export const attachMicrosoftAccount = async (tokens) => {
  await ajax_post('/api/account/attach_microsoft_account', tokens);
};
export const connectIntegrationFinal = async (data) => {
  await ajax_post('/api/account/connect-integration-final', data);
};
export const detachIntegration = async (integrationId) => {
  await ajax_post('/api/account/detach_integration', { integrationId });
};

export const detachGoogleAccount = async (integrationId) => {
  await ajax_post('/api/account/detach_google_account', { integrationId });
};
export const detachMicrosoftAccount = async (integrationId) => {
  await ajax_post('/api/account/detach_microsoft_account', { integrationId });
};
